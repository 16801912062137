import React from 'react'
import styles from '../../pop-up.module.scss';
import CTA from '../../../CTA'
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';
import userForm from '../../../../hooks/useForm';
import { getArrayOfErrors } from 'utils/errors';
import { toast } from 'react-hot-toast';
import { addCategorie } from '../../../../api/categories';
import { useState } from 'react';

export const CategoriesAdd = ({idSupplier}) => {
    const [loading, setLoading] = useState(false)
    const { formData, handleInputChange, isDisabled, setFormData } = userForm({
        
        id:'',
        name:'' 

      });


      const createSupplier = async ()=>{
        setLoading(true)

          const data = {
           
            name:formData.name

          }

          try {

            const response =  await addCategorie(idSupplier, data)

            if (response.includes('existe')) {
              toast.error(response);
            }else{
              toast.success(response);
            }

             
              setTimeout(() => {
                window.location.reload();
                setLoading(false)
            }, 800);


          } catch (error) {

            if (error.response) {
              const errors = getArrayOfErrors(error.response.data);
              for (let i = 0; i < errors.length; i++) {
                toast.error(errors[i]);
              }
              setLoading(false)
            } else {
              toast.error('Error interno. Inténtelo más tarde');
              setLoading(false)
            }
          }
       


      }


  return (
    <div className={styles.content}>
    <div className={styles.header}>
       <h1 className={styles.title}>Nueva categoría</h1>
    </div>
    <h2 className={styles.subtitle}>Por favor ingresa el nombre de la categoría.</h2>
    <input
      className={styles.input}
      type="text"
      placeholder="Nombre"
      name="name"
      value={formData.name}
      onChange={handleInputChange}
      autoComplete="name"
      autoCorrect="off"
    />

    <div className={styles.bottomContainer}>
      <div className={styles.stepsContainer}>
        <div className={`${styles.dot}`}></div>
        <div className={`${styles.dot}`}></div>
      </div>
      <CTA text="Confirmar" isDisabled={formData.name === '' || loading ? true : false} onClick={createSupplier} />
    </div>
  </div>
  )
}
