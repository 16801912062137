import React from 'react';
import PropTypes from 'prop-types';
import styles from './cta.module.scss';

const CTA = ({ text, isDisabled, onClick, size, color = '', view = '' }) => {
  return (
    <button
      className={`${styles.button} ${styles[color]} ${styles[view]} ${styles[size]} ${
        isDisabled ? styles.disabled : ''
      }`}
      onClick={onClick}
      disabled={isDisabled}
    >
      {text}
    </button>
  );
};

CTA.propTypes = {
  text: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  view: PropTypes.string,
  size: PropTypes.string,
};

export default CTA;
