import { Box, Button, Divider, Grid, IconButton, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import styles from './form.module.scss'
import { AiOutlineDownload } from '@react-icons/all-files/ai/AiOutlineDownload';
import React, { useEffect, useState } from 'react'
import dele from '../../assets/deleteBlack.png'
import { useDispatch, useSelector } from 'react-redux';
import { getArrayOfErrors } from 'utils/errors';
import { toast } from 'react-hot-toast';
import { filterModel } from '../../redux/inputFileSlice/thunks';
import { saveProducts } from '../../api/products';
import { useNavigate } from 'react-router-dom';
import { setErrorToken } from '../../redux/authSlice/authSlice';





export const FormAddProducts = ({ setShowModal, model, udm, subCategories, data, user }) => {

    const { token } = useSelector((state) => state.auth)

    const [productos, setProducts] = useState([]);
    const [loading, setloading] = useState(false)
    const [step, setStep] = useState(true);
    const [initial, setInitial] = useState([]);
    const dispatch = useDispatch()
    const navigate = useNavigate();

    useEffect(() => {

        valueInitial(model)
    }, [])



    useEffect(() => {

        validate()

    }, [initial])



    const valueInitial = (array) => {


        const j = udm.map((j) => {
            return j.name
        })


        let initialValues = [];


        array.map(campos => {


            const value = {}

            for (const validate of campos.campos) {



                if (validate.name === 'measurementUnit' && !j.includes(validate.value)) {

                    value[validate.name] = ''

                } else {
                    value[validate.name] = validate.value === null ? '' : validate.value
                }



            }
            initialValues.push(value)
        })



        setInitial(initialValues)


    }

    const deleteItem = (i, k) => {



        const filt = model.filter((data) => {
            return data.id != i
        })


        dispatch(filterModel(filt))

        const initailFil = initial.filter((fi, j) => {
            return j != k

        })

        setInitial(initailFil)

    }

    const handleOnchange = (e, i) => {


        setInitial(prev => ([
            ...prev,
            initial[i][e.target.name] = e.target.value

        ])

        )


    }


    const createProducts = async () => {

        setloading(true)

        const data = initial.filter((t) => {

            return t.name


        })




        try {

            await saveProducts(user, data, token)
            setloading(false)
            toast.success('Productos creados de manera correcta');
            setTimeout(() => {
                navigate('/catalogo')
            }, 700);


        } catch (error) {
            setloading(false)
            if (error.response) {
                const errors = getArrayOfErrors(error.response.data);

                if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {

                    dispatch(setErrorToken(true))

                } else {
                    for (let i = 0; i < errors.length; i++) {
                        toast.error(errors[i]);
                    }
                }
            } else {
                toast.error('Error interno. Inténtelo más tarde');
            }


        }

    }


    const validate = () => {

        const val = initial.map((n, i) => {

            if (n.name === '' || n.measurementUnit === '' || n.subcategoryId === '') {

                return false

            } else {

                return (true)
            }



        })


        if (val.includes(false)) {

            setStep(true)

        } else {
            setStep(false)
        }


    }


    return (

        <>



            {
                model && model.map((models, j) => {

                    return (
                        <Box key={j} >
                            <Box key={j} padding={2} >
                                <Grid container  >


                                    {models && models.campos.map((campo, i) => {


                                        switch (campo.type) {
                                            case 'code':

                                                return (
                                                    <Grid key={i} item md={2} xs={12} sx={{ marginBottom: { xs: 2, md: 0 } }}   >
                                                        <input

                                                            value={initial.length != 0 ? initial[j].code : ''}
                                                            onChange={

                                                                (e) => handleOnchange(e, j)
                                                            } name={campo.name} className={styles.inputAddP} placeholder={campo.labeCodigo} type="text" />

                                                    </Grid>
                                                );

                                            case 'product':
                                                return (
                                                    <Grid key={i} item md={3.8} xs={12} sx={{ marginBottom: { xs: 2, md: 0 } }}  >
                                                        <input
                                                            value={initial.length != 0 ? initial[j].name : ''}
                                                            onChange={

                                                                (e) => handleOnchange(e, j)
                                                            } name={campo.name} className={styles.inputAddP} placeholder={campo.labeProduct} type="text" />
                                                        {
                                                            initial.length != 0 && initial[j].name === '' ?

                                                                <Typography fontSize={11} color={'red'} > El nombre es requerido </Typography>
                                                                :

                                                                null

                                                        }

                                                    </Grid>

                                                )
                                            case 'udm':
                                                return (
                                                    <Grid key={i} item md={2} xs={12} sx={{ marginBottom: { xs: 2, md: 0 } }}  >

                                                        <select

                                                            value={initial.length != 0 ? initial[j].measurementUnit : ''}
                                                            onChange={

                                                                (e) => handleOnchange(e, j)
                                                            } name={campo.name} className={styles.selectAdd} >
                                                            <option style={{ color: 'gray' }} value="">{campo.labelUdm}</option>
                                                            {udm && udm.map((udm) => {
                                                                return <option key={udm.id} value={udm.name} >{udm.name} </option>
                                                            })}


                                                        </select>
                                                        {
                                                            initial.length != 0 && initial[j].measurementUnit === '' ?

                                                                <Typography fontSize={11} color={'red'} > La UDM es requerida </Typography>
                                                                :

                                                                null

                                                        }

                                                    </Grid>

                                                )
                                            case 'category':
                                                return (
                                                    <Grid key={i} item md={2} xs={5} sx={{ marginBottom: { xs: 2, md: 0 } }}  >
                                                        <select
                                                            value={initial.length != 0 ? initial[j].subcategoryId : ''}
                                                            onChange={

                                                                (e) => handleOnchange(e, j)
                                                            } name={campo.name} className={styles.selectAdd} >

                                                            <option value="">{campo.labelCategory} </option>
                                                            {
                                                                subCategories && subCategories.map((category, i) => {
                                                                    return (
                                                                        <optgroup key={i} style={{ fontSize: 12, color: '#49A35B' }} label={category.categoryName}  >
                                                                            {
                                                                                category.subcategories.map((subCate, j) => {

                                                                                    return <option key={j} style={{ fontSize: 14, color: 'black' }} value={subCate.subcategoryId} > {subCate.subcategoryName}   </option>

                                                                                })
                                                                            }
                                                                            {/* <option style={{ fontSize: 14, color: 'black' }} value={category.subcategoryId} > {category.subcategoryName}   </option> */}
                                                                        </optgroup>

                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        {
                                                            initial.length != 0 && initial[j].subcategoryId === '' ?

                                                                <Typography fontSize={11} color={'red'} > La categoría es requerida </Typography>
                                                                :

                                                                null

                                                        }
                                                    </Grid>

                                                )
                                            default:

                                                break;
                                        }



                                    })}
                                    <Grid item md={2} display='flex' alignItems='center' justifyContent={'center'} xs={5}>
                                        <IconButton onClick={() => deleteItem(models.id, j)} aria-label="delete" size="large">
                                            <img src={dele} alt="" />
                                        </IconButton>
                                    </Grid>

                                </Grid>
                            </Box>
                            <Box sx={{ display: { xs: 'block', md: 'none' } }} bgcolor={'#D8D9DA'} >
                                <Divider />
                            </Box>
                        </Box>






                    )

                })
            }
            <Box sx={{ display: { xs: 'block', md: 'flex' }, marginBottom: { xs: 2, md: 0 } }} padding={2} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}  >
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}  >
                    <a className={styles.link} href="https://cado-space.nyc3.cdn.digitaloceanspaces.com/docs/template_products.xlsx">Descarga formato de productos  </a>
                    <AiOutlineDownload className={styles.back} />
                </Box>

                <Box sx={{ display: { xs: 'block', md: 'flex' } }} display={'flex'} flexDirection={'row'} alignItems={'center'} >
                    <Box mr={2} sx={{ marginBottom: { xs: 2, md: 0 }, marginTop: { xs: 2, md: 0 } }} >
                        <Button
                            onClick={() => setShowModal(true)}
                            variant='contained'
                            sx={{
                                backgroundColor: '#94A3B8', padding: 1.5, borderRadius: 8, width: 180, '&:hover': {
                                    backgroundColor: '#94A3B8',
                                },
                            }} >
                            Importar archivo
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            type='submit'

                            disabled={step || loading  }
                            onClick={createProducts}
                            variant='contained'
                            sx={{
                                padding: 1.5, borderRadius: 8, width: 180
                            }} >
                            Confirmar productos
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>

    )
}
