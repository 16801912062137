import React, { useEffect } from 'react';
import styles from './StyleRecover/step2.module.scss';
import ReactModal from 'react-modal';
import InputTag from 'components/Input';
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';

const Step2 = ({
  verifyRecoveryCode,
  recoveryStep,
  handleRecoveryCode,
  handleRecoveryCodeChange,
  recoveryCode,
  recoverPasswordState,
  verifyRecoveryError,
  disableRecoveryCode,
  validateRecoveryCode,
  goBackRecovery,
}) => {
  useEffect(validateRecoveryCode, [recoveryCode]);

  return (
    <form
      className={styles.infoContainer}
      onSubmit={(e) => verifyRecoveryCode(e, recoverPasswordState, recoveryCode)}
    >
      <div className={styles.headerContainer}>
        <div className={styles.backButtonContainer}>
          <Back className={styles.backButton} onClick={(e) => goBackRecovery(e)} />
          <p className={styles.authHeader}>Ingresa el código de verificación</p>
        </div>
        <div />
        <div
          className={styles.individualInputContainer}
          data-error={verifyRecoveryError ? verifyRecoveryError.code : null}
        >
          <div className={styles.styleInputContainer}>
            <input
              type="text"
              defaultValue={recoveryCode[0]}
              name="0"
              placeholder="0"
              className={styles.input1}
              maxLength={1}
              onKeyUp={(e) => handleRecoveryCodeChange(e)}
              autoFocus
            />
            <input
              type="text"
              defaultValue={recoveryCode[1]}
              name="1"
              placeholder="0"
              className={styles.input2}
              onKeyUp={(e) => handleRecoveryCodeChange(e)}
              maxLength={1}
            />
            <input
              type="text"
              defaultValue={recoveryCode[2]}
              name="2"
              placeholder="0"
              className={styles.input3}
              onKeyUp={(e) => handleRecoveryCodeChange(e)}
              maxLength={1}
            />
            <input
              type="text"
              defaultValue={recoveryCode[3]}
              name="3"
              placeholder="0"
              className={styles.input4}
              onKeyUp={(e) => handleRecoveryCodeChange(e)}
              maxLength={1}
            />
          </div>
          {typeof verifyRecoveryError === 'string' ? (
            <span className={styles.errorSpan}>{verifyRecoveryError}</span>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <input
          type="submit"
          value="Confirmar"
          disabled={disableRecoveryCode}
          className={styles.submitAuth}
        ></input>
      </div>
    </form>
  );
};

export default Step2;
