import React, { useEffect } from 'react';
import useAuth from 'hooks/useAuth';
import Loading from 'components/Loading';
import { useDispatch } from 'react-redux';
import { clearAuth } from '../../redux/authSlice/authSlice';

const Logout = () => {
  const dispatch = useDispatch()
  const { logoutUser } = useAuth();

  useEffect(() => {
    clearA()
  }, []);

  const clearA = () => {

    setTimeout(() => {
      dispatch(clearAuth())

    }, 700);

  }

  return <Loading />;
};

export default Logout;
