
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import subRoutes from './subRoutes'
import { Messages } from './Messages'
import NotFound from '../../router/components/NotFound'
import { Message } from './components/Message'
import { DeleteMessage } from './DeleteMessage'

export const MessagesRouters = () => {
  return (
    <>
    <Routes>
      <Route path={subRoutes.messages} element={<Messages />} />
      <Route path={subRoutes.message} element={<Message />} />
      <Route path={subRoutes.delete} element={<DeleteMessage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </>
  )
}
