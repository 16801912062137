import React from 'react'
import styles from '../../catalog.module.scss';
import CTA from 'components/CTA';
import { IoChevronBack as Back } from '@react-icons/all-files/io5/IoChevronBack';
import { HomeLayouts } from '../../../../components/layouts/HomeLayout'
import { Box, Chip, Container, Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPriceList, getUdm, updateProduct } from '../../../../api/products';
import { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { getArrayOfErrors } from 'utils/errors';
import { useDispatch, useSelector } from 'react-redux';
import { gatSubCate } from '../../../../redux/categories/thunks';
import { setErrorToken } from '../../../../redux/authSlice/authSlice';
import PopUp from '../../../../components/PopUp';
import { AddPrice } from '../../../../components/PopUp/components/AddPrice/AddPrice';
import moment from 'moment';
import { formatNumber } from '../../../../utils/numbers';


// Descripcion, precio base, categoria, UDM,

const columns = [
    {
        name: 'Fecha',
        size: 'small',
    },
    {
        name: 'Tipo',
        size: 'large',
    },
    {
        name: '$',
        size: 'small',
    },
    // {
    //     name: 'Precio Unitario',
    //     size: 'medium',
    // },
    {
        name: 'Estado',
        size: 'small',
    },

];

export const Product = () => {
    const { subCategories } = useSelector((state) => state.categories)
    const [historiPrices, sethistoriPrices] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { user, token } = useSelector((state) => state.auth)
    const [values, setValues] = useState({
        subcategoryId: '',
        name: '',
        measurementUnit: '',
        code: '',
        active: '',
        iva: ''
    })
    const { state } = useLocation()
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [udm, setUdm] = useState(false);


    useEffect(() => {
        listPrices()
        dispatch(gatSubCate(token, user.supplierId, null))
        initialV()
        getUDM()
    }, [])

    const listPrices = async () => {

        const resp = await getPriceList(token, user.supplierId, state.column.productId)
        sethistoriPrices(resp.historyPrices
        )

    }


    const handleOnchege = (e) => {


        setValues(prev => ({
            ...prev,
            [e.target.name]: e.target.value

        }))


    }

    const initialV = () => {
        setValues({
            subcategoryId: state?.column.subcategoryId,
            name: state?.column.productName,
            measurementUnit: state?.column.measurementUnit,
            code: state?.column.code,
            active: state?.column.isActive,
            iva: state?.column.iva

        })

    }
    const handleOnchegeChec = (e) => {


        setValues({
            subcategoryId: state?.column.subcategoryId,
            name: state?.column.productName,
            measurementUnit: state?.column.measurementUnit,
            code: state?.column.code,
            active: e.target.checked

        })


    }

    const saved = async () => {

        let data = {}

        if (!values.code) {
            data = {

                subcategoryId: values.subcategoryId.toString(),
                name: values.name,
                measurementUnit: values.measurementUnit,
                isActive: values.active,
                iva: values.iva

            }

        } else {
            data = {

                subcategoryId: values.subcategoryId.toString(),
                name: values.name,
                measurementUnit: values.measurementUnit,
                isActive: values.active,
                code: values.code,
                iva: values.iva

            }
        }



        setLoading(true)

        try {

            const update = await updateProduct(state.column.productId, user.supplierId, data, token)
            toast.success('Producto actualizado');
            setTimeout(() => {
                navigate('/catalogo')
                setLoading(false)
            }, 1000);

        } catch (error) {
            if (error.response) {
                const errors = getArrayOfErrors(error.response.data);

                if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {

                    dispatch(setErrorToken(true))
                    setLoading(false)
                } else {
                    for (let i = 0; i < errors.length; i++) {
                        toast.error(errors[i]);
                    }
                    setLoading(false)
                }
            } else {
                toast.error('Error interno. Inténtelo más tarde');
                setLoading(false)
            }
        }
    }


    const getUDM = async () => {

        try {

            const udm = await getUdm(token)
            setUdm(udm)

        } catch (error) {
            if (error.response) {
                const errors = getArrayOfErrors(error.response.data);

                if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {

                    dispatch(setErrorToken(true))

                } else {
                    for (let i = 0; i < errors.length; i++) {
                        toast.error(errors[i]);
                    }
                }
            } else {
                toast.error('Error interno. Inténtelo más tarde');
            }

        }




    }



    return (

        <HomeLayouts>
            <div className={styles.content} >
                <PopUp showModal={showModal} setShowModal={setShowModal}>
                    <AddPrice
                        product_id={state.column.productId
                        }
                        token={token}
                        supplier={user?.supplierId}
                        user={user?.id}
                        setShowModal={setShowModal}
                        product={state.column.productName}
                    />
                </PopUp>
                <div className={styles.header} >
                    <Box

                        sx={{ alignItems: 'center', display: { sm: 'flex', md: 'flex', xs: 'block' } }}
                        flexDirection='row'
                        alignContent={'center'}
                        justifyContent='space-between'
                        display='flex' >
                        <Box display='flex' flexDirection='row' alignItems='center' >
                            <Back
                                cursor={'pointer'}
                                className={styles.back}
                                onClick={() => navigate(`/catalogo`)}
                            />
                            <h1 className={styles.title}>Ver producto</h1>
                        </Box>
                        <Box mt={1} flexDirection={'row'} sx={{ display: { sm: 'flex', md: 'flex', xs: 'block' } }} >
                            <Box ml={2} width={220} sx={{ display: { xs: 'none', md: 'flex', sm: 'flex' } }}  >
                                <CTA
                                    isDisabled={loading}
                                    text="Guardar cambios"
                                    onClick={saved}
                                />
                            </Box>
                        </Box>
                    </Box>
                </div>
            </div>
            <Container maxWidth='lg'  >
                <Grid
                    mt={5}
                    sx={{ padding: { xs: 0.5, md: 2 } }}

                    container
                    display='flex'
                    flexDirection='row'
                    alignItems='center'

                >

                    <Grid item xs={12} md={5} mr={4} >
                        <Typography fontSize={16} fontWeight='BOLD'  >Código</Typography>
                        <Box height={50} width={'60%'} padding={0.5} border={1} borderRadius={2} borderColor='#E9E7E2' >
                            <input

                                onChange={handleOnchege}
                                name='code'
                                style={{ width: '100%' }}
                                type="text"
                                value={values.code}

                            />
                        </Box>
                    </Grid>
                    <Grid md={3} item xs={12} display='flex' justifyContent='space-between' alignItems='center' >
                        <Box mt={2} mr={12} display='flex' alignItems='center' >
                            <input
                                onChange={handleOnchegeChec}
                                name='active'
                                type='checkbox'
                                defaultChecked={state?.column.isActive} />
                            <Typography fontSize={18} mr={2} ml={1.5} >En stock</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={5} mr={4} mt={4} >
                        <Typography fontSize={16} fontWeight='BOLD'  >Producto/Descripción </Typography>
                        <Box height={50} padding={0.5} border={1} borderRadius={2} borderColor='#E9E7E2' >
                            <input
                                onChange={handleOnchege}
                                name='name'
                                style={{ width: '100%' }}
                                type="text"
                                value={values.name}
                            />
                        </Box>

                    </Grid>
                    <Grid md={3} item xs={12} mt={4} >
                        <Typography fontSize={16} fontWeight='BOLD' >UDM </Typography>
                        <Box height={50} border={1} borderRadius={2} borderColor='#E9E7E2' width={150}   >
                            <select
                                onChange={handleOnchege}
                                name='measurementUnit'
                                value={values.measurementUnit}
                                className={styles.selectAddProd} >
                                {udm && udm.map((udm) => {
                                    return <option key={udm.id} value={udm.name} >{udm.name} </option>
                                })}
                            </select>

                        </Box>
                    </Grid>
                    <Grid item xs={12} md={5} mr={4} mt={4} >
                        <Box height={50} >
                            <Typography fontSize={16} fontWeight='BOLD'  >Categoría </Typography>
                            <select
                                onChange={handleOnchege}
                                name='subcategoryId'
                                value={values.subcategoryId}
                                className={styles.selectAdd} >


                                {



                                    subCategories && subCategories.map((category, i) => {
                                        return (
                                            <optgroup key={i} style={{ fontSize: 12, color: '#49A35B' }} label={category.categoryName}  >
                                                {
                                                    category.subcategories.map((subCate, j) => {

                                                        return <option key={j} style={{ fontSize: 14, color: 'black' }} value={subCate.subcategoryId} > {subCate.subcategoryName}   </option>

                                                    })
                                                }

                                                {/* <option style={{ fontSize: 14, color: 'black' }} value={category.subcategoryId} > {category.subcategoryName}   </option> */}
                                            </optgroup>

                                        )
                                    })
                                }
                            </select>
                        </Box>

                    </Grid>
                    <Grid item xs={12} md={1} mr={4} mt={6} >
                        <Typography fontSize={16} fontWeight='BOLD'  >IVA </Typography>
                        <Box height={50} padding={0.5} border={1} borderRadius={2} borderColor='#E9E7E2' >
                            <input
                                onChange={handleOnchege}
                                name='iva'
                                style={{ width: '100%' }}
                                type="text"
                                value={values.iva}
                            />
                        </Box>


                    </Grid>


                    <Grid item xs={12} md={7} mt={6} >
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Typography fontSize={16} fontWeight='BOLD'  >Historial de precios </Typography>
                            <CTA
                                size='small'
                                isDisabled={loading}
                                text="Agregar Precio"
                                onClick={() => {
                                    setShowModal(true)
                                }}
                            />
                        </Box>
                        <Grid
                            mt={3}
                            borderRadius={3}
                            border={0.5}
                            borderColor={'#e4e4e4'}
                            display='flex'
                            flexDirection={'column'}
                            container
                        >
                            <Grid
                                sx={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
                                bgcolor={'#e9e7e2'}
                                padding={2}
                                display='flex'
                                flexDirection={'row'}
                                container
                            >
                                {columns.map((column, i) => {
                                    return (
                                        <Grid
                                            md={3}
                                            xs={3}
                                            item
                                            // md={i === 0 ? 2 : i === 1 ? 3.2 : i === 2 ? 2.4 : i === 3 ? 2.2 : i === 4 ? 2.2 : null}
                                            // xs={i === 0 ? 3 : i === 1 ? 3 : i === 2 ? 3 : i === 3 ? 2 : i === 4 ? 1 : null}
                                            key={`${i}_${column}`}

                                        >
                                            <Typography
                                                sx={{ fontSize: { xs: 15, md: 16 } }}
                                                textAlign={'center'}
                                                fontWeight={'bold'}
                                            >  {column.name} </Typography>
                                        </Grid>
                                    );
                                })}

                            </Grid>
                            {
                                historiPrices && historiPrices?.map((history, i) => {

                                    return (
                                        <Grid
                                            key={i}
                                            alignItems='center'
                                            padding={2}
                                            sx={{ border: { xs: 1, md: 0 }, borderColor: { xs: '#e9e7e2', md: null } }}
                                            display='flex'
                                            flexDirection={'row'}
                                            container
                                        >

                                            <Grid item md={3} xs={3} display='flex' flexDirection='column' >
                                                <Typography textAlign={'center'} sx={{ fontSize: { xs: 15, md: 16 } }} > {moment(history.createdAt).format('DD/MM/YYYY')} </Typography>

                                            </Grid>
                                            <Grid item md={3} xs={3} >
                                                <Typography textAlign={'center'} sx={{ fontSize: { xs: 15, md: 16 } }} > {history.tipo} </Typography>
                                            </Grid>
                                            <Grid item md={3} xs={3}>
                                                <Typography textAlign={'center'} sx={{ fontSize: { xs: 15, md: 16 } }} > ${formatNumber(history.price)} </Typography>
                                            </Grid>
                                            <Grid 
                                            display={'flex'} 
                                            flexDirection={'row'}
                                            justifyContent={'center'}
                                            item md={3} xs={3}>

                                                <Chip label={history.status ? 'Activo' : 'Inactivo'}
                                                    sx={{
                                                        backgroundColor: history.status ? 'rgba(34, 197, 94, 0.16)'  :   'rgba(255, 86, 48, 0.16)',
                                                        color: history.status   ? '#2e6e82' : '#B71D18'
                                                    }} />
                                               
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }




                        </Grid>
                    </Grid>
                </Grid>
                <Box mt={1} sx={{ display: { xs: 'block', md: 'none', sm: 'none' } }} >
                    <CTA
                        isDisabled={loading}
                        text="Guardar cambios"
                        onClick={saved}
                    />
                </Box>

            </Container>

        </HomeLayouts>


    )
}