



import { createSlice } from "@reduxjs/toolkit";




const initialState = {

    modelF: [],
    error: null,
    data:[],
    isLoading: false

}





export const inputFileSlice = createSlice({

    name: 'model',
    initialState: initialState,
    reducers: {

        setModel: (state, action) => {

            state.modelF = action.payload
            state.isLoading = false

        },
        setData: (state, action) => {

            state.data = action.payload
            state.isLoading = false

        },
        clearModel: (state) => {

            state.modelF= [],
            state.error= null,
            state.isLoading= false

        },

    }


})

export const { setModel, clearModel, setData} = inputFileSlice.actions




