import React from 'react'
import { HomeLayouts } from '../../components/layouts/HomeLayout'
import { Box, Button, Grid, TablePagination, Typography } from '@mui/material'
import styles from './categories.module.scss';
import CTA from '../../components/CTA';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import { gatCatego, gatCategoPag, gatSubCate, gatSubCateBycat, getCategoAll } from '../../redux/categories/thunks';
import { setSubCategories } from '../../redux/categories/categoriesSlice';
import PopUp from '../../components/PopUp';
import { useState } from 'react';
import { CategoriesAdd } from '../../components/PopUp/components/Categories/CategoriesAdd';
import AddSubCateg from '../../components/PopUp/components/AddSubCateg/AddSubCateg';

const options = [10];

export const Categories = () => {
  const { categories, categoriesAll, subcategoriesByCateg, isLoading } = useSelector((state) => state.categories)
  const { user, token } = useSelector((state) => state.auth)
  const [showModal, setShowModal] = useState(false);
  const [page, setpage] = useState(0)
  const [active, setActive] = useState()
  const [showModalSub, setShowModalSub] = useState(false);
  const [params, setParams] = useState({
    limit: 10,
    page: 1
  });
  const dispatch = useDispatch()




  const handleChangePage = (event, newPage) => {

    setpage(newPage);
    setParams({ ...params, page: newPage + 1 })

  };

  const handleChangeRowsPerPage = (event) => {
    setpage(0)
    setParams({ ...params, page: 1, limit: parseInt(event.target.value) });

  };


  useEffect(() => {

    dispatch(gatCatego(user.supplierId, params.limit, params.page, token))
    dispatch(getCategoAll(user.supplierId, token))

  }, [])
  useEffect(() => {

    dispatch(gatCategoPag(user.supplierId, params.limit, params.page, token))

  }, [params])

  const handleCategorie = (id_cat) => {
    setActive(id_cat)
    dispatch(gatSubCateBycat(token))

  }

  if (isLoading) {
    return (

      <HomeLayouts>
        <Loading />
      </HomeLayouts>

    )
  }


  var btns = document.getElementsByClassName("box");
  for (var i = 0; i < btns.length; i++) {
    btns[i].addEventListener("click", function () {
      var current = document.getElementsByClassName("active");
      current[0].className = current[0].className.replace(" active", "");
      this.className += " active";
    });
  }


  return (
    <HomeLayouts>
      <PopUp showModal={showModal} setShowModal={setShowModal} >
        <CategoriesAdd
          idSupplier={user.supplierId}
        />
      </PopUp>
      <PopUp showModal={showModalSub} setShowModal={setShowModalSub} >
        <AddSubCateg
          categories={categories}
          categoriesAll={categoriesAll}
        />
      </PopUp>
      <Box className={styles.header}>
        <h1 className={styles.title}>Categorías</h1>
      </Box>
      <Box mt={8} className={styles.desktopCategoriesContainer} >
        <Grid container gap={2}    >
          <Grid item md={5.5}  >
            <Box

              padding={2}
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              border={1}
              borderColor={'#E9E7E2'}

            >
              <Typography fontSize={18} fontWeight={'bold'} > Categorías </Typography>
              <Box width={150}  >
                <CTA
                  text='Nueva categoría'
                  onClick={() => setShowModal(true)}

                />
              </Box>


            </Box>

            <Box >
              {
                categories && categories?.categories.map((category, i) => {

                  return (

                    <Box
                     
                      width={'100%'}
                      component={'button'}
                      className={category.categoryId === active ? styles.box : styles.inactive}
                      key={i}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => handleCategorie(category.categoryId)}
                      padding={1.5}
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      border={1}
                      borderColor={'#E9E7E2'}

                    >
                      {category.name}

                    </Box>
                  )

                })
              }
            </Box>
            <Box mt={0.5} border={1}
              borderColor={'#E9E7E2'} >
              {
                isLoading ? null :
                  <TablePagination
                    component="div"
                    labelRowsPerPage='Filas por página'
                    rowsPerPageOptions={options}
                    count={parseInt(categories?.totalCount) || 0}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={parseInt(params.limit)}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />

              }

            </Box>


          </Grid>
          <Grid item md={5.5}  >
            <Box
              padding={2}
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              border={1}
              borderColor={'#E9E7E2'}


            >
              <Typography fontSize={18} fontWeight={'bold'} > Subcategorías </Typography>
              <Box width={180}  >
                <CTA
                  text='Nueva subcategoría'
                  onClick={() => setShowModalSub(true)}
                />
              </Box>
            </Box>

            <Box sx={{ height: { md: 400, xs: 380 } }}>
              {
                subcategoriesByCateg.map((category, i) => {

                  return (

                    <Box
                      key={i}
                      padding={1.5}
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      border={1}
                      borderColor={'#E9E7E2'}

                    >
                      <Typography fontSize={14}  > {category.subcategoryName} </Typography>

                    </Box>
                  )
                })
              }
            </Box>


          </Grid>
        </Grid>


      </Box>

    </HomeLayouts>


  )
}
