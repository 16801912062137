import React from 'react';
import PropTypes from 'prop-types';


import styles from './autoComplete.module.scss';
import { getRestaurantFiter } from '../../api/restaurants';

const AutoComplete = ({
  suggestions,
  placeholder,
  state,
  setState,
  setQuery,
  formData,
  setFormData,
}) => {

 

  const getRuc = async (query) => {


    
   
      const data = await getRestaurantFiter(query, token);
      setFormData({
      ...formData,
      id:data[0].id,
      restaurantName:query,
      restaurantIdentify: data[0].identifier,
    });
     
  };


  const onChange = (e) => {
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      (suggestion) => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
    );

    setQuery(e.currentTarget.value);
    setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value,
    });
    setFormData({ ...formData, restaurantIdentify: '' });
  };

  const onClick = (e) => {
    setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText,
    });
    getRuc(e.currentTarget.innerText);
  };

  const onKeyDown = (e) => {

    const { activeSuggestion, filteredSuggestions } = state;
    // User pressed the enter key
    if (e.keyCode === 13) {
      setState({
        ...state,
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion],
      });
      getRuc(filteredSuggestions[activeSuggestion]);
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      setState({ ...state, activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion + 1 === filteredSuggestions.length) {
        return;
      }

      setState({ ...state, activeSuggestion: activeSuggestion + 1 });
    }
  };

  let suggestionsListComponent;

  const { showSuggestions, filteredSuggestions, userInput, activeSuggestion } = state;

  if (showSuggestions && userInput) {
    if (filteredSuggestions.length) {
      suggestionsListComponent = (
        <ul className={styles.suggestions}>
          {filteredSuggestions.map((suggestion, index) => {
            let className;

            // Flag the active suggestion with a class
            if (index === activeSuggestion) {
              className = styles.suggestionActive;
            }

            return (
              <li className={className} key={`${index}_${suggestion}`} onClick={onClick}>
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    } else {
      suggestionsListComponent = (
        <div className={styles.noSuggestions}>
          <em>No hemos encontrado ningún proveedor</em>
        </div>
      );
    }
  }

  return (
    <div className={styles.container}>
      <input
        type="text"
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={userInput}
        className={styles.input}
        placeholder={placeholder}
      />
      {suggestionsListComponent}
    </div>
  );
};

AutoComplete.propTypes = {
  suggestions: PropTypes.array,
  placeholder: PropTypes.string,
  state: PropTypes.object,
  setState: PropTypes.func,
  setQuery: PropTypes.func,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
};

export default AutoComplete;
