



import { createSlice } from "@reduxjs/toolkit";




const initialState = {

    users:null,
    usersOrder:null,
    admin:0,
    asesor:0,   
    isLoadin:false,
    errorData:null,
  
} 





 export const  usersSlice = createSlice({

    name:'users',
    initialState:initialState,
    reducers:{
        startUsers:(state)=>{
            state.isLoadin = true
        },
        setUsers: (state, action )=>{
                            
            state.users=action.payload.users,
            state.usersOrder=action.payload.usersOr,
            state.admin=action.payload.admin,
            state.asesor=action.payload.asesor,
            state.isLoadin=false,
            state.errorData=null
            
        },

        setError: (state, action )=>{
           
       
            state.users=null,
            state.admin=0,
            state.asesor=0,
            state.isLoadin=false,
            state.errorData=action.payload
            
        },
        clearUsers: (state )=>{

            state.users=null,
            state.admin=0,
            state.asesor=0,
            state.isLoadin=false,
            state.errorData=null    
            
        },
     
    }


})

export const {startUsers,  setUsers,  clearUsers, setError } = usersSlice.actions




