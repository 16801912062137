import React from 'react';

const InputTag = ({ type, value, name, placeholder, onChange, className }) => {
  return (
      <input
        type={type}
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        className={className}
        required
      />
  );
};

export default InputTag;
