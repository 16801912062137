import React from 'react'
import { HomeLayouts } from '../../../components/layouts/HomeLayout'
import { Box, Grid, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';
import styles from '../catalog.module.scss'
import CTA from '../../../components/CTA';
import { IoChevronBack as Back } from '@react-icons/all-files/io5/IoChevronBack';
import PopUp from '../../../components/PopUp';
import { useState } from 'react';
import { ChangeContact } from '../../../components/PopUp/components/ChangeContact/ChangeContact';
import { useDispatch, useSelector } from 'react-redux';



export const DetailCustomer = () => {
    const { user } = useSelector((state) => state.auth)
    const { state } = useLocation();
    const [showModal, setShowModal] = useState(false);
  
    const navigate = useNavigate();
  

    return (


        <HomeLayouts>
            <PopUp showModal={showModal} setShowModal={setShowModal}>
                <ChangeContact
                    
                    contactId={state?.customer.contactId}
                    clientId={state?.customer.restaurantId}
                    name={state?.customer.restaurantname}
                    setShowModal={setShowModal}
                />
            </PopUp>
            <Box>
                   
                    <div className={styles.header} >
                    <Box display='flex' alignItems='center' >
                        <Box mt={1.2} mr={1} >
                        <Back
                                cursor={'pointer'}
                                fontSize={25}
                                className={styles.back}
                                onClick={() => navigate(`/clientes`)}
                            />
                        </Box>
                           
                        <h1 className={styles.title}> {state?.customer.restaurantname}</h1>
                        </Box>
                    </div>
               

                <div className={styles.detailContainer} >
                    <Grid gap={2} container  >

                        <Grid item xs={12} md={3} >
                            <Typography >Código cliente</Typography>
                            <Box height={50} padding={1.5} border={1} borderRadius={2} borderColor={'#E9E7E2'}  >
                                <Typography> {state?.customer.code}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography>RUC</Typography>
                            <Box height={50} padding={1.5} border={1} borderRadius={2} borderColor={'#E9E7E2'}  >
                                <Typography color={'#C4C4C4'} > {state?.customer.identifier}</Typography>
                            </Box>
                        </Grid>
                      

                    </Grid>
                    <Grid container >
                      
                        <Grid item md={12} mt={2} xs={12}  >
                            <Typography  >Dirección  </Typography>
                            <Box height={50} padding={1.5} mt={1} sx={{ width: { sm: '50%', md: '30%', xs: '100%' } }}  border={1} borderRadius={2} borderColor={'#E9E7E2'}  >
                                <Typography color={'#C4C4C4'} > {state?.customer.address

                                }</Typography>
                            </Box>
                        </Grid>

                      
                     
                    </Grid>
                    <Grid container mt={8}  >
                        <Grid item md={12} >
                            <Typography fontSize={20} fontWeight={'bold'} > Contacto Vendedor </Typography>
                        </Grid>
                        <Grid item md={12} mt={2} xs={12}  >
                            <Typography fontWeight={'bold'}  >Nombre  </Typography>
                            <Box height={50} padding={1.5} mt={1} sx={{ width: { sm: '50%', md: '30%', xs: '100%' } }}  border={1} borderRadius={2} borderColor={'#E9E7E2'}  >
                                <Typography color={'#C4C4C4'} > {state?.customer.contactname

                                }</Typography>
                            </Box>
                        </Grid>

                        {/* <Grid item md={12} mt={2} xs={12}>
                            <Typography fontWeight={'bold'}  >Número de teléfono  </Typography>
                            <Box height={50} mt={1} sx={{ width: { sm: '50%', md: '30%', xs: '100%' } }} padding={2} border={1} borderRadius={2} borderColor={'#E9E7E2'}  >
                                <Typography color={'#C4C4C4'} > {customer.phone
                                }</Typography>
                            </Box>
                        </Grid> */}
                        <Grid item md={12} mt={2} >

                            <CTA
                                isDisabled={ user?.secondaryRole === 'ASESOR' ? true : false  }
                                size='small'
                                text="Cambiar vendedor"
                                onClick={() => {
                                setShowModal(true);

                                }}
                            />

                        </Grid>


                    </Grid>

                </div>

            </Box>
        </HomeLayouts>


    )
}
