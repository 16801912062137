
import React from 'react';
import { Routes, Route } from 'react-router-dom';


import NotFound from 'router/components/NotFound';

import subRoutes from './subRoutes';
import { Catalog } from './Catalog';
import { Product } from './components/product/Product';
import { AddPproducts } from './components/addProducts/AddPproducts';

const CatalogRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={subRoutes.catalog} element={<Catalog />} />
        <Route path={subRoutes.product} element={<Product />} />
        <Route path={subRoutes.add} element={<AddPproducts />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default CatalogRoutes;
