import axios from "axios";


  export const getSites = async (type, parentId, token) => {
    const response = await axios.get(
      `${process.env.API_URL}/countries?type=${type}&parentId=${parentId} `,    
      {
        withCredentials:true,
       headers: {
           'Authorization': `Bearer ${token}`,
           'Accept': '*/*',
           'content-type': 'application/json'
           
       }
     }
    );
 
    return response.data;
  };