import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from 'router/components/NotFound';
import subRoutes from './subRoutes';
import { Customer } from './Customer';
import { DetailCustomer } from './components/DetailCustomer';

const CustomerRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={subRoutes.customers} element={<Customer />} />
        <Route path={subRoutes.cliente} element={<DetailCustomer />} />
              <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default CustomerRoutes;
