

import React from 'react'
import styles from '../../pop-up.module.scss';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import CTA from '../../../CTA';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../../../redux/usersSlice/thunk';
import { useState } from 'react';
import { changeContactClient } from '../../../../api/suppliers';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { getArrayOfErrors } from 'utils/errors';
import { setErrorToken } from '../../../../redux/authSlice/authSlice';

export const ChangeContact = ({ name, setShowModal, clientId, contactId }) => {
    const { user, token } = useSelector((state) => state.auth)
    const { users, isLoadin } = useSelector((state) => state.users)
    const [loading, setLoading] = useState();
    const dispatch = useDispatch()
    const [personName, setPersonName] = useState('');
    const navigate = useNavigate();
    // "restaurantId": 154,
    // "userId": 370



    const handleChange = (event) => {

        setPersonName(event.target.value);
    };


    useEffect(() => {

        dispatch(getUsers(user?.supplierId, token, user.id))

    }, [])


    const changeContact = async () => {


        setLoading(true)


        try {
            const data = {
                restaurantId: clientId,
                contactId: contactId,
                userId: personName
            }
            await changeContactClient(user?.supplierId, data, token)

            toast.success('Se cambio el vendedor de manera correcta');
            setTimeout(() => {
                setShowModal(false);
                setLoading(false)
                navigate('/clientes')
            }, 500);


        } catch (error) {
            if (error.response) {
                const errors = getArrayOfErrors(error.response.data);

                if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {

                    dispatch(setErrorToken(true))
                    setLoading(false)
                    setShowModal(false)
                } else {
                    for (let i = 0; i < errors.length; i++) {
                        toast.error(errors[i]);
                    }
                    setLoading(false)
                }
            } else {
                toast.error('Error interno. Inténtelo más tarde');
                setLoading(false)
            }
        }
    }

    return (


        <div className={styles.content}>
            <h1 className={styles.title}>Cambiar vendedor</h1>

            <Box >
                <Typography>
                    {`Por favor selecciona un nuevo usuario como vendedor de ${name} `}
                </Typography>
            </Box>

            <Box mt={1} >
                <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-label">Selecciona un vendedor</InputLabel>
                    <Select
                        label='Selecciona un vendedor'
                        displayEmpty
                        value={personName}
                        onChange={handleChange}
                        fullWidth
                        variant='outlined'
                    >



                        {users && users.map((us, i) => {

                            return (
                                <MenuItem key={i} value={us.id}>
                                    {us.name}
                                </MenuItem>

                            )
                        }

                        )}
                    </Select>
                </FormControl>
            </Box>

            <Box>
                <CTA
                    isDisabled={loading}
                    text='Confirmar'
                    onClick={changeContact}
                />
            </Box>

        </div>
    )
}
