import { Typography } from '@mui/material';
import React from 'react'
import { FirstStep } from '../step1/FirstStep';
import { SecondStep } from '../step2/SecondStep';
import { ThirdStep } from '../step3/ThirdStep';

export const _renderMessage = (step, templates, setFormData, formData, val, setVal, inputs, setInputs) => {




    switch (step) {
        case 1:
            return <FirstStep
            inputs={inputs}
            setInputs={setInputs}
            val={val} 
            setVal={setVal}
            formData={formData}
            setFormData={setFormData}
            templates={templates}
            />;
        case 2:
            return <SecondStep/>;
        case 3:
            return <ThirdStep />;
               
    
            default:
                return <div>Not Found</div>;

    }



 
}
