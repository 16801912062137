import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './styles/app.scss'; // Load app styles

import { storeSuppliers } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';
import { ThemeConfig } from './config/theme.config';

const persistor = persistStore(storeSuppliers)


const root = document.getElementById('root');
ReactDOM.render(
        <PersistGate persistor={persistor} >
                <Provider store={storeSuppliers} >
                <ThemeConfig>
                         <App />
                </ThemeConfig>
                       
                </Provider>
        </PersistGate>

        , root


);
