export const getArrayOfErrors = (error) => {
  const errors = [];

  if (typeof error === 'string') {
    errors.push(error);
  } else if (typeof error === 'object') {
    Object.keys(error).forEach((key) => {
      errors.push(error[key]);
    });
  }

  return errors;
};
