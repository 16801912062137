import React from 'react';
import styles from '../../pop-up.module.scss'
import CTARed from 'components/CTARed';
import { useState } from 'react';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { clearAuth } from '../../../../redux/authSlice/authSlice';
import { clearCategories } from '../../../../redux/categories/categoriesSlice';



const Closesession = ({
    setShowModal,


}) => {

    const [isLoading, setIsloading] = useState(false)
    const dispatch = useDispatch()

    const handleSubmit = () => {

        setIsloading(true)

        setTimeout(() => {
            dispatch(clearAuth())
            dispatch(clearCategories())
            setIsloading(false)
        }, 700);

    }


    return (
        <div className={styles.content}>

            <h1 className={styles.title}>Sesión </h1>
            <h2 className={styles.subtitle}>Tu sesión ha caducado por favor ingresa de nuevo para disfrutar de los servicios de Cado</h2>

            <div className={styles.bottomContainer}>
                <CTARed
                    isDisabled={isLoading}
                    text="Iniciar sesión"
                    onClick={handleSubmit}
                />
            </div>
        </div>
    );
};



export default Closesession;
