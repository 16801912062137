







import { createSlice } from "@reduxjs/toolkit";




const initialState = {

    products:null,
    error:null,
    isLoading:false
   
  
} 



 export const  productsSlice = createSlice({

    name:'products',
    initialState:initialState,
    reducers:{
        startProducts:(state)=>{
            state.isLoading = true
        },
        setProductsAll: (state, action )=>{
                            
            state.products  = action.payload,
            state.productsCategorie = [],
            state.error = null,
            state.isLoading= false 
            
        },

     
        setError: (state, action )=>{
           
            state.productsAll  = null,
            state.productsCategorie = [],
            state.error = action.payload,
            state.isLoading= false 
        
            
        },
        clearProducts: (state )=>{
  
            state.productsAll  = null,
            state.productsCategorie = [],
            state.error = null,
            state.isLoading= false 

            
        },
     
    }


})



export const { 
    startProducts,
    setProductsAll,
    setError,
    clearProducts 
} = productsSlice.actions




