import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import SearchBar from 'components/SearchBar';

import { getDisplayDate } from 'utils/dates';

import { AiOutlineLeft as Left } from '@react-icons/all-files/ai/AiOutlineLeft';
import { AiOutlineRight as Right } from '@react-icons/all-files/ai/AiOutlineRight';

import styles from './myOrders.module.scss';
import { Box } from '@mui/material';

const DesktopOrders = ({ orders, params, setParams, navigateToOrder }) => {
  const options = [15, 10, 5];
  const columns = [
    {
      name: 'Restaurante',
      size: 'large',
    },
    {
      name: 'Estado',
      size: 'medium',
    },
    {
      name: 'Fecha de entrega',
      size: 'medium',
    },
    {
      name: 'Número de orden',
      size: 'small',
    },
  ];

  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(params.limit * params.page);

  useEffect(() => {
    if (params.limit * params.page < orders.totalCount) {
      setEnd(params.limit * params.page);
      setStart(params.limit * (params.page - 1) + 1);
    } else {
      setEnd(orders.totalCount);
      setStart(params.limit * (params.page - 1) + 1);
    }
  }, [params]);

  return (
    <>
      <div className={styles.desktopOrdersContainer}>
        <div className={styles.row}>
          <div className={styles.column}>
            <SearchBar
              query={params.query}
              setQuery={(val) => {
                setParams({ ...params, query: val });
              }}
              placeholder="Buscar en Mis Órdenes"
              height="small"
            />
          </div>
        </div>
        <div className={`${styles.row} ${styles.headerRow}`}>
          {columns.map((column, i) => {
            return (
              <div
                key={`${i}_${column}`}
                className={`${styles.column} ${styles[column.size]}`}
              >
                {column.name}
              </div>
            );
          })}
        </div>
        {orders.orders.map((order, i) => {
          return (
            <div
              key={`${i}_${order.id}`}
              className={ order.view === false && order.status === 'solicitada' ? `${styles.row} ${styles.orderRowV}`  : `${styles.row} ${styles.orderRow}` }
              onClick={(e) => {
                navigateToOrder(e, order.code, order?.restaurant?.id);
              }}
            >
              <Box alignItems={'center'} className={  order.view === false && order.status === 'solicitada' ?  `${styles.weight} ${styles.column} ${styles.large}`:` ${styles.column} ${styles.large}`}>
                {order.restaurant.name}
                   { order.view === false && order.status === 'solicitada' ?
                    <Box ml={0.5} borderRadius={50} bgcolor={'#E06469'} width={11} height={11} >  </Box> 
                    :
                    null
                 }
                  
                        
              </Box>
              <div className={`${styles.column} ${styles.medium}`}>
                <label className={ order.view === false && order.status === 'solicitada' ? ` ${styles.weight}  ${styles.label} ${styles[order.status]}` : ` ${styles.label} ${styles[order.status]}`}>
                  {order.status.charAt(0).toUpperCase() + order.status.slice(1)}{' '}
                </label>
              </div>
              <div className={ order.view === false && order.status === 'solicitada' ?`${styles.weight} ${styles.column} ${styles.medium}` :` ${styles.column} ${styles.medium}`}>
                {getDisplayDate(order.scheduleDate)}
              </div>
              <div className={ order.view === false && order.status === 'solicitada' ? `${styles.weight} ${styles.column} ${styles.small}`:` ${styles.column} ${styles.small}`}>{order.code}</div>
            </div>
          );
        })}
      </div>
      {/* {orders.orders.length > 0 && (
        <div className={styles.footer}>
          <div className={styles.rowSelector}>
            <p>Filas por página</p>
            <select
              onChange={(e) => {
                e.preventDefault();
                setParams({ ...params, limit: e.target.value });
              }}
              defaultValue={10}
            >
              {options.map((option, i) => {
                return (
                  <option key={`${i}_${option}`} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
          </div>
          <div className={styles.paginationInfo}>
            {`${start}-${end} de ${orders.totalCount}`}
          </div>
          <div className={styles.pagination}>
            <Left
              onClick={() => {
                if (params.page > 1) {
                  setParams({ ...params, page: params.page - 1 });
                }
              }}
              className={styles.paginationIcon}
            />
            <Right
              onClick={() => {
                if (params.page < orders.totalPages) {
                  setParams({ ...params, page: params.page + 1 });
                }
              }}
              className={styles.paginationIcon}
            />
          </div>
        </div>
      )} */}
    </>
  );
};

DesktopOrders.propTypes = {
  orders: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  setParams: PropTypes.func.isRequired,
  navigateToOrder: PropTypes.func.isRequired,
};

export default DesktopOrders;
