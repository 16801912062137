import { useSelector } from "react-redux";



  export const reemplazarVariables =(string, variables, user)=>{

 


    return string.replace(/\$(\d+)/g, (match, index) => {
        const variableIndex = parseInt(index) - 1;
  
        if (variables[variableIndex] && variables[variableIndex].label) {
  
          if (variables[variableIndex].label === 'Proveedor') {
              return  `<b>${ user.supplier.name } </b>`
          }
          if (variables[variableIndex].label === 'Url' &&  variables[variableIndex].value != null) {
              return  `<b>${variables[variableIndex].value} </b>` 
          }
        
                 return  `<b>${variables[variableIndex].label} </b>` 
  
        } else {
          return match; // Devuelve la variable original si no se encuentra una etiqueta correspondiente
        }
      });


  }