



import { createSlice } from "@reduxjs/toolkit";


//status: 'cheking' | 'not-authenticated' | 'get-user' | 'not-complete' | 'authenticated'

const initialState = {

    user:null,   
    isLoadin:false,
    status:'not-authenticated',
    pass:null,
    errorData:null,
    subscription:null,
    token:null,
    errorToken:false,

  
} 





 export const  authSlice = createSlice({

    name:'auth',
    initialState:initialState,
    reducers:{
        startAuth:(state)=>{
            state.isLoadin = true,
            state.status = 'cheking'
        },
        setToken:(state, action)=>{

            state.token=action.payload,
            state.isLoadin=false,
            state.error=null,
            state.errorToken=false
           



        },
        setPass:(state, action )=>{

            state.pass = action.payload

        },
        setUser: (state, action )=>{
          
            state.user=action.payload.user,
            state.isLoadin=false,
            state.errorData=null,
            state.status=action.payload.status,  
            state.subscription=action.payload.subscription
            
        },
        setError: (state, action )=>{
           
       
            state.user=null,           
            state.isLoadin=false,
            state.pass=null,
            state.status= 'not-authenticated',
            state.subscription=null,
            state.errorData=action.payload
            
        },
        clearAuth: (state )=>{

            state.user=null,   
            state.isLoadin=false,
            state.status='not-authenticated',
            state.pass=null,
            state.errorData=null,
            state.subscription=null,
            state.token=null,
            state.errorToken=false         
            
        },
        setErrorToken:(state, action )=> {

            state.errorToken = action.payload
    
        },
        
     
    }


})

export const {startAuth,  setUser,  clearAuth, setError,setPass, setToken, setErrorToken } = authSlice.actions




