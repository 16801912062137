

export const model = [
    {
        id: 1,
        campos: [
            {
                id: 1,
                type:'code',
                name: 'code',
                value:'',

                labeCodigo: 'Código',


            },
            {
                id: 2,
                type:'product',
                name: 'name',
                value:'',
                labeProduct: 'Nombre producto',

            },
            {

                id: 3,
                type:'udm',
                value:'',
                name: 'measurementUnit',
                labelUdm: 'UDM',

            },
            {
                id: 4,
                type:'category',
                value:'',
                name: 'subcategoryId',
                labelCategory: 'Categoría'
            }

        ]

    },
    {
        id: 2,
        campos: [
            {
                id: 1,
                type:'code',
                value:'',
                name: 'code',
                labeCodigo: 'Código',


            },
            {
                id: 2,
                type:'product',
                value:'',
                name: 'name',
                labeProduct: 'Nombre producto',

            },
            {

                id: 3,
                type:'udm',
                value:'',
                name: 'measurementUnit',
                labelUdm: 'UDM',

            },
            {
                id: 4,
                type:'category',
                value:'',
                name: 'subcategoryId',
                labelCategory: 'Categoría'
            }

        ]

    },
    {
        id: 3,
        campos: [
            {
                id: 1,
                type:'code',
                value:'',
                name: 'code',
                labeCodigo: 'Código',


            },
            {
                id: 2,
                type:'product',
                value:'',
                name: 'name',
                labeProduct: 'Nombre producto',

            },
            {

                id: 3,
                type:'udm',
                value:'',
                name: 'measurementUnit',
                labelUdm: 'UDM',

            },
            {
                id: 4,
                type:'category',
                value:'',
                name: 'subcategoryId',
                labelCategory: 'Categoría'
            }

        ]

    },
    {
        id: 4,
        campos: [
            {
                id: 1,
                type:'code',
                value:'',
                name: 'code',
                labeCodigo: 'Código',


            },
            {
                id: 2,
                type:'product',
                value:'',
                name: 'name',
                labeProduct: 'Nombre producto',

            },
            {

                id: 3,
                type:'udm',
                value:'',
                name: 'measurementUnit',
                labelUdm: 'UDM',

            },
            {
                id: 4,
                type:'category',
                value:'',
                name: 'subcategoryId',
                labelCategory: 'Categoría'
            }

        ]

    },


]
