

import { createSlice } from "@reduxjs/toolkit";




const initialState = {

    prices:null,

   
} 





 export const  preciosSlice = createSlice({

    name:'precios',
    initialState:initialState,
    reducers:{
  


        setPrecios: (state, action )=>{
                
          
            state.prices= action.payload
                            

        },
 
     clearPrices: (state )=>{
       

        state.prices=null

}
        
    }


})

export const { setPrecios, clearPrices,  } = preciosSlice.actions


