




import { getAlltemplatesSupplier } from "../../api/templates"
import { setErrorToken } from "../authSlice/authSlice"
import { setError, setTemplatesSupp, startTemplates } from "./templateSlice"




export const getTemplatesForSupplier = (id, id_user, token) => {


    return async (dispatch) => {

          dispatch(startTemplates())
          

        try {

            const templates = await getAlltemplatesSupplier(id, id_user, token)
      
            dispatch( setTemplatesSupp(templates) )


        } catch (error) {
         
            if (error.response) {
                if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {
                     dispatch(setErrorToken(true))
                 } else {
                     dispatch(setError(error.response?.data))
                 }
             } else {
                 dispatch(setError('Error interno. Inténtelo más tarde'))
            }
         }
    }
}

