import routes from 'router/routeList';

const getCurrentRoute = (pathname) => {
  const split = pathname.split('/');
  return '/' + split[1];
};

const routeExists = (route) => {
  return Object.values(routes).some((r) => r === route);
};

const isPageWithoutNavbar = (route) => {
  const { login, registration, logout, recoverPassword } = routes;
  return [login, registration, logout, recoverPassword].includes(route);
};

export const shouldHideNavbar = (pathname) => {
  const route = getCurrentRoute(pathname);

  if (isPageWithoutNavbar(route)) return true;
  if (!routeExists(route)) return true;

  return false;
};
