import React, { useEffect, useState } from 'react'
import { HomeLayouts } from '../../components/layouts/HomeLayout'
import { useLocation, useNavigate } from 'react-router-dom'
import makeAnimated from "react-select/animated";
import styles from './message.module.scss'
import { components } from 'react-select';
import { Box, Container, Grid, Typography } from '@mui/material'
import { IoChevronBack as Back } from '@react-icons/all-files/io5/IoChevronBack';
import CTARed from '../../components/CTARed'
import { getArrayOfErrors } from '../../utils/errors';
import PopUp from '../../components/PopUp'
import { ConfirmationDelete } from '../../components/PopUp/components/DeleteMessage/ConfirmationDelete'
import { reemplazarVariables } from '../../utils/replaceValues'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { dias, hours } from '../../utils/dates'
import { getCustom } from '../../redux/customersSlice/thunks'
import MySelect from '../../components/Select/MySElect'
import { Option } from '../../components/Option/Option';
import CTA from '../../components/CTA';
import { updateTemplate } from '../../api/templates';
import toast from 'react-hot-toast';

export const DeleteMessage = () => {
    const { customers, isLoading, errorData } = useSelector((state) => state.customers)
    const { user, token } = useSelector((state) => state.auth)
    const [loading, setLoading] = useState(false)
    const [editMsg, seteditMsg] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [suppliersL, setsuppliersL] = useState({ optionSelected: null })
    const animatedComponents = makeAnimated();
    const [templateFields, setTemplateFields] = useState([])
    const [plantilla, setplantilla] = useState('')
    const [showModal, setShowModal] = useState(false);
    const { state } = useLocation()
    const [params, setParams] = useState({
        query: '',
    });

    const [val, setVal] = useState([])
    const hoy = moment().format('YYYY-MM-DD')
    const [formData, setFormData] = useState({
        templateId: '',
        day: '',
        clients: { optionSelected: null },
        values: [],
        recurrent: '',
        fromsend: '',
        tosend: '',
        hoursend: "string"
    },)


    const handleChange = selected => {


        setFormData({
            ...formData,
            clients: selected
        })

        setsuppliersL({
            optionSelected: selected
        });
    };


    useEffect(() => {
        dispatch(getCustom(user?.supplierId, user?.id, params.query, token))
    }, [])


    const MultiValue = props => (
        <components.MultiValue key={props.data.value}  {...props}>
            <span>{props.data.label}</span>
        </components.MultiValue>
    );

    useEffect(() => {

        setplantilla(reemplazarVariables(state.template.template, state.template.templateFields, user))
        filtrar()
        //setFormDataInitialV()
    }, [])



    const setFormDataInitialV = (val) => {
      

        const clientsF = state.template.templateClients.map((client) => {
            return {
                value: client.restaurantId,
                label: client.name
            }
        })




        //filtrar()
        if (state.template.recurrent) {
            setFormData({
                day: state?.template?.monday ? 'L'  : state?.template?.tuesday  ? 'M' : state?.template?.wednesday ? 'MI' : 
                     state?.template?.thursday ? 'J' : state?.template?.friday   ? 'V'  :  
                     state?.template?.sunday ? 'D'   : state?.template?.saturday ? 'S'  : '',

                // day: state?.template?.friday ? 'V ' : state?.template?.monday ? 'L' : state?.template?.saturday
                //     ? 'S ' : state?.template?.sunday ? 'D' : state?.template?.thursday ? 'J' : state?.template?.tuesday
                //         ? 'M' : 'Mi',
                clients: clientsF,
                recurrent: state.template.recurrent,
                values: val,
                fromsend:  state.template.fromDate.split('T')[0],
                tosend: state.template.toDate.split('T')[0],
                hoursend: state.template.hours,
                templateId: state.template.templateId,
            })
        } else {
            setFormData({
                day: '',
                clients: state.template.clients,
                recurrent: state.template.recurrent,
                values: val,
                fromsend:state.template.fromDate.split('T')[0],
                tosend: state.template.toDate,
                hoursend: state.template.hours,
                templateId: state.template.templateId,
            })
        }
    }


    const handleOnchange = (e) => {

        setFormData({
            ...formData,
            [e.target.name]: e.target.value

        })


    }


    const filtrar = () => {

        const updatedTemplateFields = state.template.templateFields.map((field) => {
            // Busca un valor en templateValues que coincida con el id de templateFields
            const matchingValue = state.template.templateValues.find((value) => value.templateFieldId === field.id);

            // Si se encuentra un valor coincidente, actualiza el campo
            if (matchingValue) {
                return { ...field, value: matchingValue.value };
            }

            // Si no se encuentra un valor coincidente, no hace cambios en el campo
            return field;
        });

        const val = updatedTemplateFields.filter((val) => {

            return val.edit === true

        })

        setTemplateFields(val);


        setFormDataInitialV(val)

    }


    const listClient = (customer) => {


        if (customer.length > 0) {
            const supplierOptions = customers.map((val) => {
                return {
                    value: val.restaurantId,
                    label: val.restaurantname,
                };
            });

            return supplierOptions
        } else {
            return []
        }



    }


    const setmsg = () => {
        seteditMsg(true)
    }

    const updateMsg = async () => {


        const cli = formData.clients.map((cl) => {
            return cl.value
        })

        const data = {
            day: formData.day,
            clients: cli,
            from: formData.fromsend,
            tosend: formData.tosend,
            hoursend: formData.hoursend

        }
        setLoading(true)

        try {

            await updateTemplate(state.template.supplierTemplateId, data, token)

            toast.success('Se actualizó el mensaje de manera correcta');
            setTimeout(() => {
                setLoading(false)
                navigate('/mensajes')
            }, 500);


        } catch (error) {
            if (error.response) {
                const errors = getArrayOfErrors(error.response.data);
                for (let i = 0; i < errors.length; i++) {
                    toast.error(errors[i]);
                }

                setLoading(false)
            } else {
                setLoading(false)
                toast.error('Error interno. Inténtelo más tarde');
            }
            setLoading(false)
        }

    }


    return (

        <HomeLayouts>
            <PopUp showModal={showModal} setShowModal={setShowModal}>
                <ConfirmationDelete
                    idTemplate={state.template.supplierTemplateId}
                    name={state?.template?.name}
                    setShowModal={setShowModal}
                // clientId
                // contactId

                />
            </PopUp>
            <Container>
                <Box padding={3}>
                    <Grid
                        display='flex'
                        flexDirection='row'
                        alignItems='center'
                        justifyContent='space-between'
                        container >
                        <Grid
                            alignItems='center'
                            display='flex'
                            item >
                            <Back
                                cursor={'pointer'}
                                fontSize={25}
                                className={styles.back}
                                onClick={() => navigate(`/mensajes`)}
                            />
                            <Typography
                                fontSize={28}
                                fontWeight='bold'
                            > Detalle del mensaje </Typography>
                        </Grid>
                        <Grid item

                            display='flex'
                            flexDirection='row'
                            alignItems='center'
                            justifyContent='space-between'
                        >
                            <Box mr={5} >
                                {
                                    state?.template?.recurrent ?
                                        <CTA
                                            isDisabled={loading}
                                            text={!editMsg ? 'Editar Mensaje' : 'Guardar cambios'}
                                            onClick={editMsg ? updateMsg : setmsg}
                                        /> : null
                                }

                            </Box>

                            <Box>
                                <CTARed

                                    text='Eliminar Mensaje'
                                    onClick={() => {
                                        setShowModal(true);

                                    }}
                                />
                            </Box>
                        </Grid>

                    </Grid>
                </Box>
                <Grid
                    mt={4}
                    paddingLeft={4}
                    container

                >

                    <Grid
                        item
                        xs={12}
                        md={4}
                    >
                        <Typography
                            fontSize={18}
                            fontWeight='bold'
                        > Nombre del mensaje </Typography>

                        <Box mt={1} height={50} width={'90%'} padding={0.5} border={1} borderRadius={2} borderColor='#E9E7E2' >
                            <input
                                disabled
                                name='code'
                                style={{ width: '100%' }}
                                type="text"
                                value={state?.template?.name}

                            />
                        </Box>


                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={4}
                    >
                        <Typography
                            fontSize={18}
                            fontWeight='bold'
                        > Recurrente </Typography>
                        <Box mt={1} height={50} width={'60%'} padding={0.5} border={1} borderRadius={2} borderColor='#E9E7E2' >
                            <input
                                disabled
                                name='code'
                                style={{ width: '100%' }}
                                type="text"
                                value={state?.template?.recurrent ? 'Si' : 'No'}

                            />
                        </Box>

                    </Grid>


                </Grid>


                <Grid
                    mt={3}
                    paddingLeft={4}
                    container

                >

                    <Grid
                        item
                        xs={12}
                        md={12}
                    >
                        <Typography
                            fontSize={18}
                            fontWeight='bold'
                        > Plantilla </Typography>

                        <Box mt={1} width={'90%'} padding={2} border={1} borderRadius={2} borderColor='#E9E7E2' >

                            <p dangerouslySetInnerHTML={{ __html: plantilla }} style={{ color: '#B4B4B8' }} ></p>
                            {/* <input
 readOnly
 name='code'
 style={{ width: '100%' }}
 type="text"
 value={plantilla}

 /> */}
                        </Box>


                    </Grid>

                    {
                        templateFields.length > 0 ?

                            templateFields.map((template, i) => {

                                return (

                                    <Grid
                                        key={i}
                                        mt={2}
                                        item
                                        xs={12}
                                        md={5.7}
                                    >
                                        <Typography
                                            fontSize={18}
                                            fontWeight='bold'
                                        > {template.label
                                            } </Typography>

                                        <Box height={60} mt={1} width={'90%'} padding={1} border={1} borderRadius={2} borderColor='#E9E7E2' >
                                            <input
                                                disabled
                                                name=''
                                                value={formData.values[i]?.value || ''}
                                                type="text" />
                                            {/* <Typography> { template.value } </Typography> */}

                                        </Box>
                                    </Grid>
                                )

                            })

                            : null
                    }







                </Grid>



                {
                    state?.template?.recurrent ?



                        <>
                            <Grid
                                mt={4}
                                paddingLeft={4}
                                container

                            >

                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                >
                                    <Typography
                                        fontSize={18}
                                        fontWeight='bold'
                                    > Día</Typography>

                                    <Box mt={1} height={50} width={'90%'} padding={1} border={1} borderRadius={2} borderColor='#E9E7E2' >
                                        <select
                                            disabled={!editMsg}
                                            value={formData.day}
                                            onChange={handleOnchange}
                                            name={'day'}
                                        //className={styles.selectAddProd} 
                                        >
                                            {dias.map((udm, i) => {
                                                return <option key={i} value={udm.value} >{udm.dia} </option>
                                            })}
                                        </select>

                                    </Box>

                                    {/* <Box mt={1} height={50} width={'90%'} padding={0.5} border={1} borderRadius={2} borderColor='#E9E7E2' >
 <input
 readOnly
 name='code'
 style={{ width: '100%' }}
 type="text"
 value={state?.template?.friday ? 'Viernes ' : state?.template?.monday ? 'Lunes' : state?.template?.saturday
 ? 'Sábado ' : state?.template?.sunday ? 'Domingo' : state?.template?.thursday ? 'Jueves' : state?.template?.tuesday
 ? 'Martes' : 'Miércoles '


 }

 />
 </Box> */}


                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                >
                                    <Typography
                                        fontSize={18}
                                        fontWeight='bold'
                                    > Hora </Typography>
                                    <Box mt={1} height={50} width={'60%'} padding={1} border={1} borderRadius={2} borderColor='#E9E7E2' >

                                        <select
                                            disabled={!editMsg}
                                            onChange={handleOnchange}
                                            value={formData.hoursend}
                                            name={'hoursend'}
                                        //className={styles.selectAddProd}
                                        >
                                            {hours.map((udm, i) => {
                                                return <option key={i} value={udm} >{udm} </option>
                                            })}
                                        </select>
                                        {/* <input
 readOnly
 name='code'
 style={{ width: '100%' }}
 type="text"
 value={state?.template?.hours
 }

 /> */}
                                    </Box>

                                </Grid>


                            </Grid>
                            <Grid
                                mt={4}
                                paddingLeft={4}
                                container

                            >

                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                >
                                    <Typography
                                        fontSize={18}
                                        fontWeight='bold'
                                    > Fecha de inicio</Typography>

                                    <Box mt={1} height={50} width={'90%'} padding={0.5} border={1} borderRadius={2} borderColor='#E9E7E2' >
                                        <input
                                            disabled name={'fromsend'}
                                            min={hoy}
                                            style={{ width: '100%' }}
                                            type="text"
                                            value={  formData.fromsend}

                                        />
                                    </Box>


                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                >
                                    <Typography
                                        fontSize={18}
                                        fontWeight='bold'
                                    > Fecha de conclusión </Typography>
                                    <Box mt={1} height={50} width={'60%'} padding={0.5} border={1} borderRadius={2} borderColor='#E9E7E2' >
                                        <input
                                            disabled={!editMsg}
                                            type="date"
                                            id="start"
                                            value={formData.tosend}
                                            //className={styles.inputAddP}
                                            name={'tosend'}
                                            onChange={handleOnchange}
                                            min={formData.fromsend}
                                        />
                                        {/* <input

 name='code'
 style={{ width: '100%' }}
 type="text"
 value={moment(state?.template?.toDate).format('DD-MM-YYYY')}

 /> */}
                                    </Box>

                                </Grid>


                            </Grid>
                        </>

                        :


                        <Grid
                            mt={4}
                            paddingLeft={4}
                            container

                        >

                            <Grid
                                item
                                xs={12}
                                md={4}
                            >
                                <Typography
                                    fontSize={18}
                                    fontWeight='bold'
                                > Fecha Programada</Typography>

                                <Box mt={1} height={50} width={'90%'} padding={0.5} border={1} borderRadius={2} borderColor='#E9E7E2' >
                                    <input
                                        readOnly
                                        name={'fromsend'}
                                        min={hoy}
                                        style={{ width: '100%' }}
                                        value={formData.fromsend}
                                        onChange={handleOnchange}
                                        type="date"
                                        id="start"





                                    //value={moment(state?.template?.fromDate).format('DD-MM-YYYY')}

                                    />
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={4}
                            >
                                <Typography
                                    fontSize={18}
                                    fontWeight='bold'
                                > Hora </Typography>
                                <Box mt={1} height={50} width={'60%'} padding={0.5} border={1} borderRadius={2} borderColor='#E9E7E2' >
                                    <input
                                        readOnly
                                        name='code'
                                        style={{ width: '100%' }}
                                        type="text"
                                        value={state?.template?.hours
                                        }
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                }

                {
                    state?.template?.recurrent ?
                        <Box width={'90%'} paddingLeft={4} mt={4} mb={15} >
                            <Typography mb={1} fontSize={18} style={{ fontWeight: 'bold' }} > Clientes: </Typography>
                            {

                                customers && customers.length ?

                                    <MySelect
                                        isDisabled={!editMsg}
                                        options={listClient(customers)}
                                        isMulti
                                        className="basic-multi-select"
                                        hideSelectedOptions={false}
                                        components={{ Option, MultiValue, animatedComponents }}
                                        onChange={handleChange}
                                        allowSelectAll={true}
                                        placeholder='Selecciona los proveedores'
                                        isSearchable
                                        value={formData.clients.length > 0 ? formData.clients : []}

                                    />
                                    : null
                            }
                        </Box> :
                        <>
                            <Box mt={6} paddingLeft={5}>
                                <Typography
                                    fontSize={18}
                                    fontWeight='bold'
                                > Clientes </Typography>
                            </Box>

                            <div

                                className={styles.scrollbarX}

                                mt={2} >


                                {
                                    state?.template?.templateClients && state?.template?.templateClients.map((client, i) => {
                                        return (
                                            <Box
                                                mb={15}
                                                key={i}
                                                borderRadius={2}
                                                mr={1}
                                                padding={1}
                                                bgcolor={'#F9F6F4'}
                                            >
                                                <Typography> {client.name} </Typography>
                                            </Box>

                                        )
                                    })

                                }

                            </div>
                        </>
                }


            </Container>

        </HomeLayouts>


    )
}
