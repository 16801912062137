import { setData, setModel } from "./inputFileSlice"

import readXlsxFile from 'read-excel-file'



export const getModelFile = (file) => {


    return async (dispatch) =>  {

    
        const hhh = readXlsxFile(file).then((rows) => {
           
        
            const filter = rows.filter((r, i)=>{
                return i != 0
            })
        
          
        
            const map = filter.map((rows, i) => {
                const data = []
                        
                   return {
        
                    id: i + 1,
                    
                    
                    campos: [
                        {
                            id: 1,
                            type: 'code',
                            value:rows[0] === null ? '' :rows[0] ,
                            name: 'code',
                            labeCodigo: 'Código',
        
        
                        },
                        {
                            id: 2,
                            type: 'product',
                            value:rows[1] === null ? '' :rows[1],
                            name: 'name',
                            labeProduct: 'Nombre producto',
        
                        },
                        {
        
                            id: 3,
                            type: 'udm',
                            value:rows[2] === null ? '' :rows[2],
                            name: 'measurementUnit',
                            labelUdm: 'UDM',
        
                        },
                        {
                            id: 4,
                            type: 'category',
                            value:'',
                            name: 'subcategoryId',
                            labelCategory: 'Categoría'
                        },
                             
                    ]
        
                }
      
            })
             
     

            dispatch( setModel(map) )
        })


    }

   

}



export const filterModel = (model) => {


    return async (dispatch) =>  {

    
      

            dispatch( setModel(model) )
        


    }

   

}





