




import React from 'react'
import styles from '../../pop-up.module.scss';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import CTA from '../../../CTA';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../../../redux/usersSlice/thunk';
import { useState } from 'react';
import { changeContactClient } from '../../../../api/suppliers';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { getArrayOfErrors } from 'utils/errors';
import CTARed from '../../../CTARed';
import { deleteTemplateSupplier } from '../../../../api/templates';

export const ConfirmationDelete = ({ name, setShowModal, idTemplate }) => {
    const { user, token } = useSelector((state) => state.auth)
    const [loading, setLoading] = useState();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    // "restaurantId": 154,
    // "userId": 370

  




    useEffect(() => {

        dispatch(getUsers(user?.supplierId, token, user.id))

    }, [])


    const changeContact = async () => {


        setLoading(true)


        try {
     
            await deleteTemplateSupplier(user?.supplierId, idTemplate, token)

            toast.success('Se elimino el mensaje de manera correcta');
            setTimeout(() => {
                setShowModal(false);
                setLoading(false)
                navigate('/mensajes')
            }, 500);


        } catch (error) {
            if (error.response) {
                const errors = getArrayOfErrors(error.response.data);
                for (let i = 0; i < errors.length; i++) {
                  toast.error(errors[i]);
                }
              } else {
                setLoading(false)
                toast.error('Error interno. Inténtelo más tarde');
              }
        }
    }

    return (


        <div className={styles.content}>
            <h1 className={styles.title}>Eliminar mensaje</h1>

            <Box >
                <Typography>
                    {`Estás seguro de eliminar el mensaje ${name} `}
                </Typography>
            </Box>
         

            <Box mt={2} >
                <CTARed
                    isDisabled={loading}
                    text='Eliminar'
                    onClick={changeContact}
                />
            </Box>

        </div>
    )
}



