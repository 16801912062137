const months = {
  '01': 'Enero',
  '02': 'Febrero',
  '03': 'Marzo',
  '04': 'Abril',
  '05': 'Mayo',
  '06': 'Junio',
  '07': 'Julio',
  '08': 'Agosto',
  '09': 'Septiembre',
  10: 'Octubre',
  11: 'Noviembre',
  12: 'Diciembre',
};

export const changeReferenceDate = (date, direction) => {
  if (direction === 'previous') {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - 7);
    return newDate;
  } else if (direction === 'next') {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 7);
    return newDate;
  }
};

export const getCurrentWeekFromDate = (date) => {
  const currentDate = new Date(date);

  const week = [];

  for (let i = 1; i <= 7; i++) {
    const day = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDate.getDay() + i,
    );

    week.push(day);
  }

  const timezoneString = getTimezoneString();

  return {
    monday: week[0].toISOString().split('T')[0].concat(`T00:00:00${timezoneString}`),
    tuesday: week[1].toISOString().split('T')[0].concat(`T00:00:00${timezoneString}`),
    wednesday: week[2].toISOString().split('T')[0].concat(`T00:00:00${timezoneString}`),
    thursday: week[3].toISOString().split('T')[0].concat(`T00:00:00${timezoneString}`),
    friday: week[4].toISOString().split('T')[0].concat(`T00:00:00${timezoneString}`),
    saturday: week[5].toISOString().split('T')[0].concat(`T00:00:00${timezoneString}`),
    sunday: week[6].toISOString().split('T')[0].concat(`T11:59:59${timezoneString}`),
  };
};

export const formatDate = (date, type = 'default') => {
  const dateArray = date.split('-');
  let day = dateArray[2].split('T')[0];
  const month = months[dateArray[1]];

  if (day[0] === '0') {
    day = day.substring(1);
  }

  if (type === 'mobile') {
    return day;
  }

  return [month, day].join(' ');
};

const getTimezoneString = () => {
  /*
		offset: int
		timezone: string

		i.e. 300 => -05:00

    09:22:12.123-04:00

	*/

  const offset = new Date().getTimezoneOffset();
  const timezone = Math.round(parseInt(offset) / 60) * -1;
  const timeZoneAbs = Math.abs(timezone);
  const timeZoneString = `${timezone >= 0 ? '+' : '-'}${
    timeZoneAbs.toString().length === 1 ? `0${timeZoneAbs}` : timeZoneAbs
  }:00`;

  return timeZoneString;
};

export const getDayOfTheWeek = (date) => {
  const day = new Date(date).getDay();

  switch (day) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    default:
      return 'Sunday';
  }
};

export const isDateAfter = (date1, date2) => {
  if (!date1 || !date2) return false;
  const d1 = new Date(date1.replace(/-/g, '/'));
  const d2 = new Date(date2);

  return d1 > d2;
};

export const formatDateForOrder = (date) => {
  const isoDate = new Date(date).toISOString().split('T')[0];
  const timezoneString = getTimezoneString();
  const timeOfOrder = new Date();
  const timeOfOrderModified = new Date(timeOfOrder.getTime() + 1 * 60000)
    .toString()
    .split(' ')[4];

  return `${isoDate}T${timeOfOrderModified}${timezoneString}`;
};

export const getDisplayDate = (date) => {

  
  
  const dateAdjustedToTimezone = new Date(date);
  const year = dateAdjustedToTimezone.getFullYear();
  let month = dateAdjustedToTimezone.getMonth() + 1;
  let day = dateAdjustedToTimezone.getDate();

  if (month < 10) {
    month = `0${month}`;
  }

  if (day < 10) {
    day = `0${day}`;
  }

  return `${day}-${month}-${year}`;
};

export const getInputDate = (date) => {
  const dateAdjustedToTimezone = new Date(date);
  const year = dateAdjustedToTimezone.getFullYear();
  let month = dateAdjustedToTimezone.getMonth() + 1;
  let day = dateAdjustedToTimezone.getDate();

  if (month < 10) {
    month = `0${month}`;
  }

  if (day < 10) {
    day = `0${day}`;
  }

  return `${year}-${month}-${day}`;
};

export const getInputDateSumm = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const year = tomorrow.getFullYear();
  let month = tomorrow.getMonth() + 1;
  let day = tomorrow.getDate();

  if (month < 10) {
    month = `0${month}`;
  }

  if (day < 10) {
    day = `0${day}`;
  }

  return `${year}-${month}-${day}`;
};

export const hours = [
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',

]

export const dias = [
{
  dia:'Lunes',
  value:'L'
},
{
  dia:'Martes',
  value:'M'
},
{
  dia:'Miercoles',
  value:'MI'
},
{
  dia:'Jueves',
  value:'J'
},
{
  dia:'Viernes',
  value:'V'
},
{
  dia:'Sabado',
  value:'S'
},
{
  dia:'Domingo',
  value:'D'
},


]



