import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { authSlice } from './authSlice/authSlice'
import thunk from 'redux-thunk'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { usersSlice } from './usersSlice/usersSlice'
import { customerSlice } from './customersSlice/customersSlice'
import { openSlice } from './openSlice/opneSlice'
import { categoriesSlice } from './categories/categoriesSlice'
import { productsSlice } from './productsSlice/productsSlice'
import { inputFileSlice } from './inputFileSlice/inputFileSlice'
import { templateSlice } from './templateSlice/templateSlice'
import { orderNSlice } from './newOrderSlice/nOrderSlice'
import { preciosSlice } from './preciosSlice/preciosSlice'

// ...




const persistConfig = {

  key:'root',
  storage,
  whitelist:['auth', 'ordern']

}


const rootReducer = combineReducers({
  auth: authSlice.reducer,
  users: usersSlice.reducer,
  customers:customerSlice.reducer,
  open:openSlice.reducer,
  categories:categoriesSlice.reducer,
  products:productsSlice.reducer,
  modelFile:inputFileSlice.reducer,
  template: templateSlice.reducer,
  ordern:orderNSlice.reducer,
  precios:preciosSlice.reducer

})


const persistedReducer = persistReducer( persistConfig, rootReducer)

export const storeSuppliers = configureStore({
  reducer: persistedReducer,
  middleware:[thunk]
  
})



