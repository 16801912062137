import React, { useEffect, useState } from 'react';
import PropTypes, { number } from 'prop-types';
import QuantityAmount from '../QuantityAmount';
import QuantityModifierAux from '../QuantityAux';
import useStore from 'store';
import styles from './productReview.module.scss';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { total } from '../../utils/sum';
import { formatNumber } from '../../utils/numbers';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscription } from '../../api/user';
import { setPrecios } from '../../redux/preciosSlice/preciosSlice';
//import { setPrecios } from '../../redux/slices/preciosSlice';

const ProductReview = ({ restaurant, allowChanges, state, setState, prod = [], shouldRemoveEmpty = false }) => {

  const [tot, settotal] = useState()
  //const { subscription } = useSelector((state) => state.auth)
  const [iva, setiva] = useState()
  const store = useStore((state) => state);
  const [totalOrd, setTotalO] = useState()
  // const subscription = useStore((state) => state.subscription);
  const dispath = useDispatch()



  useEffect(() => {



    if (prod.length > 0) {

      settotal(total(prod))
      setiva(parseFloat(tot * 12 / 100).toFixed(2))
      setTotalO((parseFloat(tot) + parseFloat(iva)).toFixed(2))
      setState({ ...state, iva: iva, total: totalOrd, subtotal: tot });
    } else {

      dispath(setPrecios({
        subtotal: tot,
        iva: iva,
        total: totalOrd
      }))
      settotal(parseFloat(total(state.products)))
      setiva(parseFloat(tot * 12 / 100).toFixed(2))
      setTotalO((parseFloat(tot) + parseFloat(iva)).toFixed(2))
      setState({ ...state, iva: iva, total: totalOrd, subtotal: tot });
    }

  }, [total(prod), iva, totalOrd, tot])


  useEffect(() => {
    settotal(parseFloat(total(state.products)))
    setiva(parseFloat(tot * 12 / 100).toFixed(2))
    setTotalO((parseFloat(tot) + parseFloat(iva)).toFixed(2))

  }, [state])




  const getProductState = (product) => {
    if (product.confirmedQuantity === 0)
      return {
        status: 'unavailable',
        message: 'No disponible',
      };
    if (
      product.confirmedQuantity &&
      product.confirmedQuantity !== product.requestedQuantity
    )
      return {
        status: 'modified',
        message: 'Modificado',
      };

    return {
      status: 'available',
      message: '',
    };
  };


  return (
    <div className={styles.container}>
      {




        state.products.map((product, i) => {

          if (product.requestedQuantity === '0.0') {

            return null
          }

          let pre = 0


          if (product.confirmedQuantity === undefined) {

            pre = product.price * product.requestedQuantity
          } else if (product.confirmedQuantity === null && product.deliveredQuantity === undefined) {

            pre = product.price * product.requestedQuantity

          } else if (product.confirmedQuantity === null && product.deliveredQuantity === null) {

            pre = product.price * product.requestedQuantity

          } else if (product.confirmedQuantity != null && product.confirmedQuantity === product.requestedQuantity) {

            pre = product.price * product.requestedQuantity

          } else if (product.confirmedQuantity === null && product.deliveredQuantity != product.requestedQuantity) {

            pre = product.price * product.deliveredQuantity

          } else if (product.confirmedQuantity === null && product.deliveredQuantity === product.requestedQuantity) {

            pre = product.price * product.requestedQuantity


          } else if (product.deliveredQuantity != null && product.deliveredQuantity !== product.requestedQuantity && product.deliveredQuantity !== product.confirmedQuantity) {

            pre = product.price * product.deliveredQuantity;


          } else {

            pre = product.price * product.confirmedQuantity
          }


          return (
            <Box>
              <Grid
               
                alignItems={'center'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                container

                key={i} >
                <Grid
                  xs={4}
                  item
                  md={tot === 0 ? 6 : 5}

                >
                  {!allowChanges && (

                    <span
                      className={`${styles[getProductState(product).status]} ${styles.message
                        }`}
                    >
                      {getProductState(product).message}
                    </span>


                  )}
                  {
                    product.name.length > 20 ?
                      <Tooltip arrow title={product.name} >
                        <Typography fontWeight='bold' noWrap >{product.name} </Typography>
                      </Tooltip> :
                      <Typography fontWeight='bold' noWrap >{product.name} </Typography>
                  }

                </Grid>
                {allowChanges ? (
                  <Grid
                    xs={4}
                    item
                    md={tot === 0 ? 3.5 : 5}
                  >
                    <QuantityModifierAux
                      product={product}
                      state={state}
                      setState={setState}
                      allowChanges={allowChanges}
                      quantityKey={
                        typeof product.confirmedQuantity === 'number'
                          ? 'confirmedQuantity'
                          : 'requestedQuantity'
                      }
                      shouldRemoveEmpty={shouldRemoveEmpty}
                    />
                  </Grid>
                ) : (
                  <Grid
                    xs={6}
                    item
                    md={tot === 0 ? 5 : 5}
                  >
                    <QuantityAmount product={product} subscription={subscription} total={tot} />
                  </Grid>
                )}
                {
                  tot === 0 || !subscription || subscription.plan.abreviacion === 'estandar' || subscription.plan.abreviacion === 'free' ? null :
                    <Grid
                      xs={2}
                      item
                      md={2}
                    >
                      <Typography textAlign='end' fontWeight={'bold'} > {pre ? `$${formatNumber(pre)}` : '-'}</Typography>
                    </Grid>
                }
              </Grid>

              {i === state.products.length - 1 ? null :
                <Divider color='#e8e8e8' sx={{ opacity: 0.3 }} />

              }
            </Box>
          )
        })}
      {
        tot === 0 || !subscription || subscription.plan.abreviacion === 'estandar' || subscription.plan.abreviacion === 'free' ? null :
          <Box mt={1} >
            <Box sx={{ borderColor: '#E9E7E2' }} width={'100%'} border={0.5} />
            <Grid mt={2} item display='flex' justifyContent='space-between' width={'100%'}   >
              <Typography fontSize={15} fontWeight='bold' >Subtotal: </Typography>
              <Typography fontSize={15} fontWeight='bold' > ${formatNumber(tot)} </Typography>
            </Grid>
            <Grid mt={1.2} item display='flex' justifyContent='space-between' width={'100%'}   >
              <Typography fontSize={15} fontWeight='bold' >IVA 12%: </Typography>
              <Typography fontSize={15} fontWeight='bold' > ${formatNumber(iva)}</Typography>
            </Grid>
            <Grid mt={1.2} item display='flex' justifyContent='space-between' width={'100%'}   >
              <Typography fontSize={15} fontWeight='bold' >Total de la orden: </Typography>
              <Typography fontSize={15} fontWeight='bold' > ${formatNumber(totalOrd)} </Typography>
            </Grid>
          </Box>

      }

    </div>
  );


};

ProductReview.propTypes = {
  allowChanges: PropTypes.bool.isRequired,
  // state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  origin: PropTypes.string,
  shouldRemoveEmpty: PropTypes.bool,
};

export default ProductReview;
