import React, { Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './router';
import { Toaster } from 'react-hot-toast';
import Loading from 'components/Loading';
import Sidebar from 'components/Sidebar';
import HamburgerMenu from 'components/HamburgerMenu';

import { getUser } from 'api/user';

import { toast } from 'react-hot-toast';

import useStore from 'store';
import AppRouter from './router/Router';
import { useDispatch } from 'react-redux';
import { me } from './redux/authSlice/thunks';

const App = () => {



  return (

  
<BrowserRouter>
    <AppRouter />

</BrowserRouter>


  );
};

export default App;
