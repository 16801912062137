import React from 'react'
import CTARed from '../../../CTARed'
import { getArrayOfErrors } from 'utils/errors';
import { toast } from 'react-hot-toast';
import styles from '../../pop-up.module.scss';
import CTA from '../../../CTA';
import { updateProduct } from '../../../../api/products';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorToken } from '../../../../redux/authSlice/authSlice';


export const ChangeStock = ({ producto, setShowModal, msg, marcar, supplier }) => {
    const [loading, setLoadingBtn] = useState()
    const dispatch = useDispatch()
    const { user, token } = useSelector((state) => state.auth)

    const changeProductStock = async () => {

        setLoadingBtn(true)

        if (marcar) {
            const data = {
                subcategoryId: producto.subcategoryId.toString(),
                name: producto.productName,
                measurementUnit: producto.measurementUnit,
                price: producto.price,
                isActive: false
            }

            try {

                const resp = await updateProduct(producto.productId, supplier, data, token)
                toast.success('Producto actualizado');
                setTimeout(() => {
                    window.location.reload()
                    setShowModal(false)
                    setLoadingBtn(false)
                }, 1000);


            } catch (error) {
                if (error.response) {
                    const errors = getArrayOfErrors(error.response.data);

                    if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {

                        dispatch(setErrorToken(true))

                    } else {
                        for (let i = 0; i < errors.length; i++) {
                            toast.error(errors[i]);
                        }
                    }
                } else {
                    toast.error('Error interno. Inténtelo más tarde');
                }
            }

        } else {
            const data = {
                subcategoryId: producto.subcategoryId.toString(),
                name: producto.productName,
                measurementUnit: producto.measurementUnit,
                price: producto.price,
                isActive: true
            }
            try {

                const resp = await updateProduct(producto.productId, supplier, data, token)
                toast.success('Producto actualizado');
                setTimeout(() => {
                    window.location.reload()
                    setShowModal(false)
                    setLoadingBtn(false)
                }, 1000);

            } catch (error) {
                if (error.response) {
                    const errors = getArrayOfErrors(error.response.data);

                    if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {

                        dispatch(setErrorToken(true))

                    } else {
                        for (let i = 0; i < errors.length; i++) {
                            toast.error(errors[i]);
                        }
                    }
                } else {
                    toast.error('Error interno. Inténtelo más tarde');
                }

            }

        }



    }

    return (
        <div className={styles.content}>
            <div className={styles.header}>
                {marcar ?
                    <h1 className={styles.title}>Marcar producto fuera de stock</h1> :
                    <h1 className={styles.title}>Marcar producto en stock</h1>

                }

            </div>
            <h2 className={styles.subtitle}>{msg}</h2>


            <div className={styles.bottomContainer}>
                <div className={styles.stepsContainer}>
                    <div className={`${styles.dot}`}></div>
                    <div className={`${styles.dot}`}></div>
                </div>
                {
                    marcar ?
                        <CTARed isDisabled={loading} text="Confirmar" onClick={changeProductStock} />
                        :
                        <CTA isDisabled={loading} text='Confirmar' onClick={changeProductStock} />
                }

            </div>
        </div>
    )
}
