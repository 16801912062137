
import { getCustomers } from "../../api/restaurants"
import { setCustomers, setError, startCustomers } from "./customersSlice"
import { getArrayOfErrors } from 'utils/errors';
import { setErrorToken } from '../authSlice/authSlice'




export const getCustom = (id, user_id, query, token) => {


    return async (dispatch) => {

        if (query === '') {
            dispatch(startCustomers())
        }else{
            null
        }
       

        try {

            const customers = await getCustomers(id, user_id, query, token)
      
       
            dispatch( setCustomers(customers) )


        } catch (error) {
            if (error.response) {
                const errors = getArrayOfErrors(error.response.data);

                if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {

                    dispatch(setErrorToken(true))

                } else {
                    for (let i = 0; i < errors.length; i++) {
                        dispatch(setError(errors[i]))
                    }
                }
            } else {
                dispatch(setError('Error interno. Inténtelo más tarde'))
            }
         
           
            // setLoginError(error.response?.data);
        }


    }
}









