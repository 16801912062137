
import { Button } from '@mui/material'
import React from 'react'

export const CTAMUI = ({ text, borderColor, backgroundColor, color, disabled, onClick, width='100%' }) => {
    return (
        <Button
            onClick={onClick}
            disabled={disabled}
            size='small'
            variant='contained'
            sx={{
                border:1,
                color:color,
                borderColor:borderColor,
                fontSize:15,
                fontWeight:500,
                backgroundColor: backgroundColor, 
                width:width,
                borderRadius: 15,  
                
                '&:hover': {
                    backgroundColor: backgroundColor,
                },
            }}

        >
            {text}
        </Button>
    )
}
