import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/logo_name_white.png';

import { shouldHideNavbar } from './helpers.js';

import  { linkOrder, linksGold, linksS } from './links';
import styles from './sidebar.module.scss';
import { LinkC } from './LinkStandar';
import useStore from 'store';
import { useSelector } from 'react-redux';
import { LinkMenu } from './LinkMenu';
import { usePopUp } from '../../hooks/usePopUp';
import PopUp from '../PopUp/PopUp';
import Closesession from '../PopUp/components/CloseSesion/Closesession';





const Sidebar = () => {
  const { user, subscription } = useSelector((state) => state.auth)
  const { show, setShow } = usePopUp()
  const { pathname } = useLocation();



  const isCurrentPath = (route) => {
    if (route === '/') {
      return route === pathname;
    } else {
      return pathname.startsWith(route);
    }
  };

  if (shouldHideNavbar(pathname)) return null;



  return (
    <div className={styles.container}>
        <PopUp setShowModal={setShow} showModal={show}>
        <Closesession
          setShowModal={setShow}
        />
      </PopUp>
      <nav className={styles.nav}>
        <img src={logo} className={styles.logo} alt="cado logo" />
        <ul className={styles.link}>Administrador</ul>

    


        {
            !user ? 
    
            <Link
            to={linkOrder[0].to}
            className={styles.link}
            onClick={() => setIsOpen(false)}
           
          >
            <img 
             width={ 25} 
            src={linkOrder[0].icon} className={styles.icons} />
            {linkOrder[0].name}
          </Link> :
          !subscription || subscription.plan.abreviacion === "base" || !user ?

          linksS.map((link, i) => {
            return (
              <Link
                to={link.to}
                key={`${i}_${link.to}`}
                className={`${styles.link} ${
                  isCurrentPath(link.to) ? styles.currentLink : ''
                }`}
                // style={{
                //   display:
                //   (link.name === 'Órdenes' || link.name === 'Perfil' || link.name === 'Cerrar sesión' || link.name === 'Catálogo' || link.name === 'Clientes' || link.name === 'Mensajes') && !user  ? 'none' : user?.roles && link.name === 'Iniciar sesión' ? 'none'
                //         : null,
                // }}
                style={{
                  display:
                  (link.name === 'Órdenes' || link.name === 'Perfil' || link.name === 'Cerrar sesión') && !user
                      ? 'none' : user?.roles && link.name === 'Iniciar sesión' ? 'none'
                      : null,
                }}
              >
             <img 
                  width={link.name === 'Iniciar sesión' ? 25 : null} 
                  src={link.icon} 
                  className={styles.icons} 
                />
                {link.name}
              </Link>
            );
          })
          :
          linksGold.map((link, i) => link.list === 0 ?
          (
            <LinkC
            restaurant={restaurant}
            key={i}
            to={link.to}
            name={link.name}
            icon={link.icon} />
          ):
          (
                  <LinkMenu
                to={link.to}
                key={i}
                icon={link.icon}
                links={link.list}
                name={link.name}  
                >
                </LinkMenu>
          )
          )
        }
      </nav>
    </div>
  );
};

export default Sidebar;
