import React from 'react';
import PropTypes from 'prop-types';
import { getDisplayDate } from 'utils/dates';
import './printComponent.scss';
import { Box, Typography } from '@mui/material';
//import { useQRCode } from 'next-qrcode';
//import { useSelector } from 'react-redux';

// eslint-disable-next-line react/display-name
const PrintComponent = React.forwardRef(({ orders }, ref) => {
  //const { restaurant } = useSelector((state) => state.restaurantsCReducer)
  // const { Canvas } = useQRCode();




  return (
    <div className="print-container" id="content-to-print" ref={ref}>

      <div>
        <div>
          {orders?.map((day) => {
            return day.orders.map((order, i) => {
              const date = order.deliveryDate
                ? getDisplayDate(order.deliveryDate)
                : getDisplayDate(order.scheduleDate);
              if (order.status !== 'f') {
                return (
                  <div key={`${i}_${order.id}`} className="print-entry">
                    <div className="print-entry-header">
                      <div className="print-header-info">
                        <span>
                          <Typography className="print-label">Fecha de entrega:  {date}</Typography>
                        </span>
                        <span>
                          <Typography className="print-label">Comercio:   {order.restaurant.name} </Typography>

                        </span>
                        <span>
                          <Typography className="print-label">Código: {order.code}</Typography>
                        </span>
                        <span>
                          <Typography className="print-label">Horario de entrega: {order.restaurant.hourStart} a {order.restaurant.hourEnd
                          }  </Typography>
                        </span>
                      </div>
                      {/* <Canvas
                        text={`https://app.cado.ai/ordenes/detalle/${order.code
                          }/${restaurant}`}
                        options={{
                          type: 'image/jpeg',
                          quality: 0.8,
                          level: 'M',
                          margin: 1,
                          scale: 4,
                          width: 75,
                          color: {
                            dark: '#000000',
                            light: '#FFFFFF',
                          },
                        }}
                      /> */}
                    </div>
                    <div className="print-entry-body">
                      <table className="print-table">
                        <thead>
                          <tr>
                            <th className="print-column"> <Typography>Producto</Typography> </th>
                            <th className="print-column"> <Typography>Medida</Typography></th>
                            <th className="print-column"><Typography>Cantidad</Typography> </th>
                          </tr>
                        </thead>
                        <tbody>
                          {order.products.map((product) => {

                            return (
                              <tr key={product.id}>

                                <td className="print-column"> <Typography>{product.name}</Typography>  </td>
                                <td className="print-column">
                                  <Typography>{product.measurementUnit}</Typography>
                                </td>
                                <td className="print-column">

                                  {product.deliveredQuantity !== null
                                    ? <Typography>{product.deliveredQuantity}</Typography>
                                    : product.confirmedQuantity !== null
                                      ? <Typography>{product.confirmedQuantity}</Typography>
                                      : <Typography>{product.requestedQuantity}</Typography>
                                  }
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <br />
                    <span>
                      <Typography className="print-label">Notas del Cliente: </Typography>
                      <Typography className="print-label">{order.note} </Typography>

                    </span>
                  </div>
                );
              }
            });
          })}
        </div>
      </div>
    </div>
  );
});

PrintComponent.propTypes = {
  orders: PropTypes.array,
};

export default PrintComponent;
