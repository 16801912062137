


import React, { useState, useEffect } from 'react'

import { useSelector } from 'react-redux'



export const usePopUp = () => {
    const [show, setShow] = useState(false)

    const { errorToken } = useSelector((state) => state.auth)
      
    useEffect(() => {

        errorOrders()

    }, [errorToken])

    useEffect(() => {

    }, [show])


    const errorOrders = () => {

        if (errorToken) {

            setShow(true)

        }

    }








    return {

        show,
        setShow

    }
}
