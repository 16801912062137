import { setError, setPass, setToken, setUser, startAuth } from "./authSlice";
import { getSubscription, getUser } from "../../api/user";
import useStore from 'store';
import { login } from "../../api/auth";
import { useNavigate } from "react-router-dom";







export const auth =  (data)=>{

  return async (dispatch)=>{
  

    try {

      dispatch(startAuth())
      const token =  await login(data);
      

     
        if (token.data.app === 'supplier') {   
          dispatch(setToken( token.data.token ))
       
        const user = await getUser(token.data.token, data.phone );
        const subscription = await getSubscription(user?.id, token.data.token )

          if (!user.complete) {
            dispatch(setUser( {user, status:'not-complete', subscription:subscription.data } ))  
            dispatch(setPass(data.password))  
          }else{

            dispatch(setUser( {user, status:'authenticated', subscription:subscription.data } ))
            dispatch(setPass(data.password))
          }
       
       }else{
        dispatch(setError('Esta cuenta no esta registrada como proveedor')) 
      }
    } catch (error) {
   
        if (error.response.data === 'Error interno') {
         
          dispatch(setError('Error interno por favor intentalo más tarde'))

        }else{
          dispatch(setError(error.response.data))
        }
      
    }

  }

} 



export const me = ()=>{

  
  return async (dispatch)=>{
  

    try {
   
        const user = await getUser(token.data.token, data.phone );
    
            dispatch(setUser( {user, status:'get-user', subscription:null } ))  
               
   
    } catch (error) {

      
       
        if (error.response.data === 'Error interno') {
          
          dispatch(setError('Error interno por favor intentalo más tarde'))

        }else{
          dispatch(setError(error.response.data))
        }
      
    }

  }


}


// export const auth = (data) => {
//   //  const store = useStore((state) => state);
 
//   return async (dispatch) => {

//     dispatch(startAuth()) 

//     try {


//       if (data) {

//           dispatch(setPass(data.password))
        
//       const r =  await login(data);

//         console.log('Respuesta', r)

//     //  getSubscription
//         const user = await getUser(r.data.token, data.phone ); // searching for a match and return user
       

//         if (user.type === 'supplier') {
//             const subscription = await getSubscription(user?.id)
//           dispatch(setUser( {user, subscription:subscription.data  } ))
//           //  store.setUser(user);
//           //  navigate(routes.orders, { replace: true });
//         } else {
//           dispatch(setUser(null))
//           //  store.setUser(null);
//           setLoginError('Usuario no esta registrado como proveedor');
//         }

//       }else{

//          const user = await getUser()
//          if (user.role === "guestUser") {
//           dispatch(setUser({user, subscription:null } ))
//          }else{
//              const subscription = await getSubscription(user?.id)
//          dispatch(setUser({user, subscription:subscription.data } ))

//          }
      

//       }


//     } catch (error) {
//      dispatch(setError(error.response?.data)) 
//       // setLoginError(error.response?.data);
//     }


//   }
// }



