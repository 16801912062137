import React from 'react'
import styles from './sidebar.module.scss';
import { Link, useLocation } from 'react-router-dom';

export const LinkC = ({ icon, to, name, }) => {
    const { pathname } = useLocation();
    const isCurrentPath = (route) => {
        if (route === '/') {
            return route === pathname;
        } else {
            return pathname.startsWith(route);
        }
    };
    return (
        <Link
            //onClick={handleClick}
            to={to}

            className={`${styles.link} ${isCurrentPath(to) ? styles.currentLink : ''
                }`}
        >
            <img src={icon} className={styles.icons} />
            {name}
        </Link>
    )
}
