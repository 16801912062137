import React from 'react';
import Step1 from 'components/Recover/Step1';
import Step2 from 'components/Recover/Step2';
import ConfirmRecover from 'components/Recover/ConfirmRecover';
import 'react-phone-number-input/style.css';
import useAuth from 'hooks/useAuth';

const RecoverPassword = ({
  recoveryStep,
  recoverPasswordState,
  handleRecoverChange,
  handleRecoverPhoneChange,
  handleRecoveryCodeChange,
  recoverError,
  disablePassRecovery,
  recoverPassword,
  verifyRecoveryCode,
  toLogin,
  handleRecoveryCode,
  validateRecoverPassword,
  recoveryCode,
  verifyRecoveryError,
  disableRecoveryCode,
  validateRecoveryCode,
  goBackRecovery,
}) => {

  const { phone, newPassword } = recoverPasswordState;

  switch (recoveryStep) {
    case 1:
      return (
        <Step1
          phone={phone}
          newPassword={newPassword}
          recoverPasswordState={recoverPasswordState}
          handleRecoverChange={handleRecoverChange}
          handleRecoverPhoneChange={handleRecoverPhoneChange}
          disablePassRecovery={disablePassRecovery}
          recoverError={recoverError}
          toLogin={toLogin}
          recoverPassword={recoverPassword}
          validateRecoverPassword={validateRecoverPassword}
        />
      );
    case 2:
      return (
        <Step2
          verifyRecoveryCode={verifyRecoveryCode}
          recoveryStep={recoveryStep}
          handleRecoveryCode={handleRecoveryCode}
          recoveryCode={recoveryCode}
          recoverPasswordState={recoverPasswordState}
          verifyRecoveryError={verifyRecoveryError}
          disableRecoveryCode={disableRecoveryCode}
          validateRecoveryCode={validateRecoveryCode}
          handleRecoveryCodeChange={handleRecoveryCodeChange}
          goBackRecovery={goBackRecovery}
        />
      );
    case 3:
      return <ConfirmRecover toLogin={toLogin} />;
  }
};

export default RecoverPassword;
