
import React, { useState, useEffect } from 'react';
import { getArrayOfErrors } from 'utils/errors';
import CTA from 'components/CTA';
import { toast } from 'react-hot-toast';
import useForm from 'hooks/useForm';
import styles from '../../pop-up.module.scss';
import { useSelector } from 'react-redux';
import { addSubCategorie } from '../../../../api/categories';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';



const AddSubCateg = ({ setRerender, setShowModal, categories, categoriesAll }) => {
  const { user } = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState('')


  const { formData, handleInputChange } = useForm({

    id: '',
    name: '',
    subCateName: ''

  });



  const handleChange = (event) => {

    setCategory(event.target.value);
  };


  const createSubCategory = async () => {

    const data = {
      name: formData.subCateName
    }

    try {

      const create = await addSubCategorie(user?.supplierId, category, data)
   
      if (create.includes('existe')) {
        toast.error(create);
      }else{
        toast.success(create);
      }
     
      setTimeout(() => {
        window.location.reload();
        setLoading(false)
      }, 800);


    } catch (error) {

      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    }
  };




  return (
    <div className={styles.content}>
      <h1 className={styles.title}>Nueva subcategoría</h1>

      <input
        className={styles.input}
        type="text"
        placeholder="Nombre"
        name="subCateName"
        value={formData.subCateName}
        onChange={handleInputChange}
        inputMode="text"
        autoCorrect="off"
      />
      <Box mt={1} >
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id="demo-simple-select-label">Selecciona una categoría</InputLabel>
          <Select
            label='Selecciona una categoria'
            displayEmpty
            value={category}
            onChange={handleChange}
            fullWidth
            variant='outlined'
          >
            {categoriesAll && categoriesAll?.map((category, i) => {
              return (
                <MenuItem key={i} value={category.categoryId}>
                  {category.name}
                </MenuItem>
              )
            }
            )}
          </Select>
        </FormControl>
      </Box>
      <div className={styles.bottomContainer}>
        <div className={styles.stepsContainer}>
          <div className={`${styles.dot}`}></div>
          <div className={`${styles.dot}`}></div>
        </div>
        <CTA
          text={"Continuar"}
          isDisabled={category === '' || loading ? true : false}
          onClick={createSubCategory}
        />
      </div>
    </div>
  );



};


export default AddSubCateg;
