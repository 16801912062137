



export const total = ( products  )=>{

 

    for (let i = 0; i < products.length; i++) {

            if (products[i].confirmedQuantity === undefined ) {
             
                let reduce = products.reduce((acumulador, actual) => acumulador + actual.price * actual.requestedQuantity , 0);
               
            return reduce
            
            }else if (products[i].confirmedQuantity === null && products[i].deliveredQuantity === undefined) {
               
                let reduce = products[i].price * products[i].requestedQuantity
                return reduce.toFixed(2)
              } else if (products[i].confirmedQuantity === null &&  products[i].deliveredQuantity === null ) {
          
                let reduce = products.reduce((acumulador, actual) => acumulador + actual.price * actual.requestedQuantity , 0);
                return reduce.toFixed(2)
            }else if(products[i].confirmedQuantity != null && products[i].confirmedQuantity === products[i].requestedQuantity ) {
              
                let reduce = products.reduce((acumulador, actual) => acumulador + actual.price * actual.requestedQuantity , 0);
                return reduce.toFixed(2)
            }else if(products[i].confirmedQuantity === null &&  products[i].deliveredQuantity != products[i].requestedQuantity ) {
               
                let reduce = products.reduce((acumulador, actual) => acumulador + actual.price * actual.deliveredQuantity , 0);
                return reduce.toFixed(2)
            }else if (products[i].confirmedQuantity === null && products[i].deliveredQuantity === products[i].requestedQuantity) {
               

                let reduce = products.reduce((acumulador, actual) => acumulador + actual.price * actual.requestedQuantity , 0);

                return reduce

            }else if(products[i].deliveredQuantity != null && products[i].deliveredQuantity !== products[i].requestedQuantity && products[i].deliveredQuantity !== products[i].confirmedQuantity){

                let reduce = products.reduce((acumulador, actual) => acumulador + actual.price * actual.deliveredQuantity , 0);

                return reduce

            }else{
               
                let reduce = products.reduce((acumulador, actual) => acumulador + actual.price * actual.confirmedQuantity , 0);
                return reduce.toFixed(2)
            }
      
              
    }
  
  
}