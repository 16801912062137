import routes from '../../router/routeList';
import receiptIcon from 'assets/receipt.svg';
import client from '../../assets/client.png'
import userIcon from 'assets/user.svg';
import logout from 'assets/logout.svg';
import login from '../../assets/login.png';
import msg from '../../assets/msn.png'
import packageIcon from '../../assets/catalogWithe.png';


// export default [
//   { name: 'Órdenes', to: routes.orders, icon: receiptIcon },
//   { name: 'Catalogo', to: routes.catalog, icon: packageIcon },
//   { name: 'Clientes', to: routes.customers, icon: client },
//   { name: 'Perfil', to: routes.profile, icon: userIcon },
//   { name: 'Iniciar sesión', to: routes.login, icon: login },
//   { name: 'Cerrar sesión', to: routes.logout, icon: logout },
// ];


export const linksS = [

  { name: 'Órdenes', 
    to: routes.orders, 
    icon: receiptIcon 

  },
  { name: 'Perfil', 
    to: routes.profile, 
    icon: userIcon 
  },
  // { name: 'Iniciar sesión', 
  //   to: routes.login, 
  //   icon: login 
  // },
  { name: 'Cerrar sesión', 
    to: routes.logout, 
    icon: logout 
  },

]

export const linksGold = [

  { name: 'Órdenes', 
    to: routes.orders, 
    icon: receiptIcon,
    list:[]
  },
  { name: 'Catálogo', 
    to: routes.catalog, 
    icon: packageIcon,
    list:[]
  },
  // { name: 'Catálogo', 
  //   to: routes.catalog, 
  //   icon: packageIcon, 
  //   list:[
  //     {
  //       name:'Productos',
  //       to: routes.catalog, 
  //    },
  //    {
  //     name: 'Categorias', 
  //     to: routes.category,
  //  },

  //   ]
  // },
  { name: 'Clientes', 
    to: routes.customers, 
    icon: client, 
    list:[]
  },
    { name: 'Mensajes', 
    to: routes.messages, 
    icon: msg, 
    list:[]
  },
  { name: 'Perfil', 
    to: routes.profile, 
    icon: userIcon, 
    list:[]
  },
  // { name: 'Iniciar sesión', 
  //   to: routes.login, 
  //   icon: login ,
  //   list:[]
  // },
  { name: 'Cerrar sesión', 
    to: routes.logout, 
    icon: logout, 
    list:[]
  },


]


export const linkOrder = [


  { name: 'Iniciar sesión', 
    to: routes.login, 
    icon: login 
  },
  

]