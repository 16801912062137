import axios from "axios";

export const getAlltemplatesSupplier = async (id, id_user, token) => {

  const response = await axios.get(`${process.env.API_URL}/suppliers/${id}/users/${id_user}/templates`,


    {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'

      }
    }
  );

  return response.data;
};

export const deleteTemplateSupplier = async (id_supplier, id_template, token) => {


  const response = await axios.delete(`${process.env.API_URL}/suppliers/${id_supplier}/templates/${id_template}`,

    {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'

      }
    }
  );

  return response.data;
};


export const getAlltemplates = async (token) => {


  const response = await axios.get(`${process.env.API_URL}/templates`,

    {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'

      }
    }

  );

  return response.data;
};

export const createTemplateSupplier = async (id, data, token) => {


  const response = await axios.post(`${process.env.API_URL}/suppliers/${id}/template`,
    data,

    {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'

      }
    }


  );

  return response.data;
};

export const updateTemplate = async (id, data, token) => {

 


  const response = await axios.put(`${process.env.API_URL}/suppliers/template/${id}`,
    data,

    {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'

      }
    }


  );

  return response.data;
};