export const sumAndSubstract = (number) => {
  let value;
  if (number.toString().includes('.')) {
    value = parseFloat(number.toFixed(2));
  } else {
    value = number;
  }
  return value;
};
export const formatNumber=(num)=>{

  const formattedNum = Number.parseFloat(num).toFixed(2);

  return formattedNum; 

}