import create from 'zustand';

const useStore = create((set) => ({
  user: null,
  setUser: (user) => set(() => ({ user })),
  isUserLoading: true,
  setIsUserLoading: (isUserLoading) => set(() => ({ isUserLoading })),
}));

export default useStore;
