import React from 'react';
import PropTypes from 'prop-types';
import styles from './summaryCTA.module.scss';
 
const SummaryCTA = ({ products, changeStep }) => {
  return (
    <button
      disabled={products.length > 0 ? false : true}
      className={styles.button}
      onClick={() => {
        changeStep();
      }}
    >
      Resumen {products.length > 0 ? `(${products.length})` : ''}
    </button>
  );
};

SummaryCTA.propTypes = {
  products: PropTypes.array.isRequired,
  changeStep: PropTypes.func.isRequired,
};

export default SummaryCTA;
