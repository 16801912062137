import React, { useEffect } from 'react';
import logo from '../../assets/logo_name_white.png';
import InputTag from 'components/Input';
import useAuth from 'hooks/useAuth';
import styles from './login.module.scss';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Toaster } from 'react-hot-toast';
import useStore from 'store';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUser } from '../../api/user';


const Login = () => {

  const {  errorData } = useSelector((state) => state.auth)

  const {
    loginData,
    handleLoginChange,
    handleLoginPhoneChange,
    loginUser,
    loginError,
    toRecover,
    disableLogin,
    validateLogin,
    toRegister,
  } = useAuth();

  const navigate = useNavigate();

  const user = useStore((state) => state.user);

  const { phone, password } = loginData;

  useEffect(validateLogin, [loginData]);
 
 useEffect(() => {
   

 
 }, [])
 


 
  return (
    <>
      <div className={styles.imageContainer}>
        <div className={styles.container}>
          <img src={logo} className={styles.logo} alt="cado logo" />
          <form
            className={styles.infoContainer}
            onSubmit={(e) => loginUser(e, loginData)}
          >
            <div className={styles.styleInfoContainer}>
              <div className={styles.headerContainer}>
                <p className={styles.authHeader}>Iniciar Sesión</p>
                <p className={styles.authText}>
                  Por favor ingresa tus datos para acceder a Cado.
                </p>
              </div>
              <div className={styles.inputContainer}>
                <div
                  className={styles.individualInputContainer}
                  data-error={errorData ? errorData.phone : null}
                >
                  <PhoneInput
                    type="text"
                    value={phone}
                    defaultCountry="EC"
                    name="phone"
                    placeholder="Teléfono"
                    onChange={(e) => handleLoginPhoneChange(e)}
                    className={styles.input}
                    required
                    style={{ padding: '16px 16px', height: '54px' }}
                  />
                </div>
                <div
                  className={styles.individualInputContainer}
                  data-error={errorData ? errorData.password : null}
                >
                  <InputTag
                    type="password"
                    value={password}
                    name="password"
                    placeholder="Contraseña"
                    onChange={(e) => handleLoginChange(e)}
                    className={styles.input}
                  />
                </div>
                { typeof errorData === 'string' ? (
                  <span className={styles.errorSpan}>{errorData}</span>
                ) : (
                  ''
                )}
                <Toaster position="top-center" />
                <div className={styles.navigateContainer}>
                  <a className={styles.toRecover} onClick={(e) => toRecover(e)}>
                    Recuperar contraseña
                  </a>
                  <a className={styles.register} onClick={(e) => toRegister(e)}>
                    Registro
                  </a>
                </div>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <input
                type="submit"
                value="Iniciar Sesión"
                disabled={disableLogin}
                className={styles.submitAuth}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
