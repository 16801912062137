import { Box, Typography } from '@mui/material'
import styles from '../../message.module.scss'
import flyIcon from 'assets/fly.svg';
import React from 'react'
import { CTAMUI } from '../../../../components/CTA/CTAMUI';
import { useNavigate } from 'react-router-dom';

export const Confirmation = () => {

    const navigate = useNavigate()

    return (

        <Box


            height='100vh'
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent={'center'}
        >

            <Box
                maxWidth={450}
                padding={3}
                bgcolor={'white'}

            >

                <Typography
                    fontSize={32}
                    fontWeight='bold'
                >
                    Mensaje Programado
                </Typography>
                <Typography
                    mt={1}
                >
                    ¡Tu mensaje ha sido programado. Tus clientes estarán al tanto de tus comunicaciones pronto!
                </Typography>

                <Box

                    mt={4}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems='center'
                >
                    <img width={'190px'} height={'160px'} src={flyIcon} alt="basket" />
                </Box>
                <Box

                    mt={4}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems='center'
                >
                    <CTAMUI
                        text='Ir a Mis Mensajes'
                        onClick={() => navigate('/mensajes')}
                        backgroundColor='#49A35B'
                        borderColor='#49A35B'
                    />
                </Box>



            </Box>




        </Box>

    )
}
