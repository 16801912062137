import axios from "axios";






export const getCustomers = async (id, user_id,query,  token) => {
   
  const response = await axios.get(
    `${process.env.API_URL}/suppliers/${id}/users/${user_id}/clients?query=${query}`,
    {
      withCredentials:true,
     headers: {
         'Authorization': `Bearer ${token}`,
         'Accept': '*/*',
         'content-type': 'application/json'
         
     }
   } 
  );
  return response.data;
};


export const getRestaurantFiter = async ( query, token )=>{

  const response = await axios.get(
    `${process.env.API_URL}/restaurants/filters?query=${query}`,

    {
      withCredentials:true,
     headers: {
         'Authorization': `Bearer ${token}`,
         'Accept': '*/*',
         'content-type': 'application/json'
         
     }
   } 
  );


  return response.data

}

export const validateByIdentifier = async ( data, token )=>{

  const response = await axios.post( `${process.env.API_URL}/restaurants/client_status`, data,
  
  {
    withCredentials:true,
   headers: {
       'Authorization': `Bearer ${token}`,
       'Accept': '*/*',
       'content-type': 'application/json'
       
   }
 } 
  
  
  
  );

  return response.data

}
export const createCustomer = async ( id, data, token )=>{

  const response = await axios.post( `${process.env.API_URL}/suppliers/users/${id}/client`, data,
  
  
  {
    withCredentials:true,
   headers: {
       'Authorization': `Bearer ${token}`,
       'Accept': '*/*',
       'content-type': 'application/json'
       
   }
 } 
  
  
  
  );


  return response

}

export const createCustomerExist = async ( id_supplier, data, token )=>{

  const response = await axios.post( `${process.env.API_URL}/suppliers/users/${id_supplier}/client`, data,

  
  {
    withCredentials:true,
   headers: {
       'Authorization': `Bearer ${token}`,
       'Accept': '*/*',
       'content-type': 'application/json'
       
   }
 } 
  
  
  
  );

  return response

}
