import React from 'react'
import { HomeLayouts } from '../../../../components/layouts/HomeLayout'
import styles from '../../catalog.module.scss';
import { Box, Button, Divider, Grid, IconButton, NativeSelect, Typography } from '@mui/material';

import { AiOutlineDownload } from '@react-icons/all-files/ai/AiOutlineDownload';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { getArrayOfErrors } from 'utils/errors';
import { gatSubCate, getCategoAll } from '../../../../redux/categories/thunks';
import { useState } from 'react';
import dele from '../../../../assets/deleteBlack.png'
import PopUp from '../../../../components/PopUp';
import { model } from '../../../../components/models/formModel';
import { getUdm, saveProducts } from '../../../../api/products';
import { FormAddProducts } from '../../../../components/Forms/FormAddProducts';
import { ImportFile } from '../../../../components/PopUp/components/ImportFile/ImportFile';
import { useNavigate } from 'react-router-dom';
import { setErrorToken } from '../../../../redux/authSlice/authSlice';


export const AddPproducts = () => {
    const { categories, categoriesAll, subCategories, isLoading } = useSelector((state) => state.categories)
    const { modelF, data } = useSelector((state) => state.modelFile)
    const { user, token } = useSelector((state) => state.auth)
    const [showModal, setShowModal] = useState(false);
    const [udm, setUdm] = useState([]);
    const navigate = useNavigate();
    const [productos, setProducts] = useState([]);
    const [loading, setloading] = useState(false)
    const [step, setStep] = useState(true);
    const [initial, setInitial] = useState([]);
    const dispatch = useDispatch()


  

    useEffect(() => {
        dispatch(getCategoAll(user.supplierId, token))
        dispatch(gatSubCate(token))
        getUDM()
        valueInitial(model)
    }, [])

    useEffect(() => {

    }, [step])

    useEffect(() => {

        validate()
    }, [initial])



    const getUDM = async () => {

        const udm = await getUdm(token)
        setUdm(udm)

    }

    const deleteItem = (i) => {


        setInitial(prev => ([
            ...prev,
            initial[i].code = '',
            initial[i].name = '',
            initial[i].measurementUnit = 'und',
            initial[i].subcategoryId = ''

        ]))


    }

    const valueInitial = (array) => {



        let initialValues = [];


        array.map(campos => {


            const value = {}

            for (const campo of campos.campos) {
                if (campo.name === 'measurementUnit') {
                    value[campo.name] = 'und'
                } else {
                    value[campo.name] = ''
                }


            }
            initialValues.push(value)
        })

        setInitial(initialValues)


    }



    const handleOnchage = (i, e) => {



        setInitial(prev => ([
            ...prev,
            initial[i][e.target.name] = e.target.value

        ])

        )

    }




    const validate = () => {

        const data = initial.filter((t) => {


            return (typeof t === 'object')

        })


        const val = data.map((n, i) => {

            if (n.name != '' && n.measurementUnit != '' && n.subcategoryId != '') {
                return true
            } else {
                return false
            }

        })


        if (val.includes(true)) {
            setStep(false)
        } else {
            setStep(true)
        }



    }

    const createProducts = async () => {

        setloading(true)

        setStep(true)

        const data = initial.filter((t) => {

            return (typeof t === 'object')


        })


        const filter = {
            name: '',
            measurementUnit: '',
            subcategoryId: ''
        };

        let dataF = data.filter(item => {
            for (let key in filter) {
                if (item[key] === filter[key])
                    return false;
            }
            return true;
        });
        // const dataF = data.filter(x => x.name != '' && x.measurementUnit == '' && x.subcategoryId != '');




        try {

            await saveProducts(user.supplierId, dataF, token)
            toast.success('Productos creados de manera correcta');
            setloading(false)
            setTimeout(() => {
                navigate('/catalogo')
            }, 700);


        } catch (error) {
            setloading(false)
            if (error.response) {
                const errors = getArrayOfErrors(error.response.data);

                if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {

                    dispatch(setErrorToken(true))

                } else {
                    for (let i = 0; i < errors.length; i++) {
                        toast.error(errors[i]);
                    }
                }
            } else {
                toast.error('Error interno. Inténtelo más tarde');
            }


        }




    }

    return (
        <HomeLayouts>
            <PopUp showModal={showModal} setShowModal={setShowModal} >
                <ImportFile
                    setShowModal={setShowModal}

                />
            </PopUp>
            <div className={styles.content} >

                <div className={styles.header} >
                    <h1 className={styles.title}>Añadir Productos</h1>
                </div>
            </div>

            <Box className={styles.desktop} mt={10} mb={4}  >

                <Box padding={2} borderBottom={1} borderColor={'#E9E7E2'}  >
                    <Grid container gap={1} >
                        <Grid item md={2} xs={5}  >
                            <Typography fontSize={18} fontWeight={'bold'} >Código</Typography>
                        </Grid>
                        <Grid item md={3.8} xs={5} >
                            <Typography fontSize={18} fontWeight={'bold'} >Producto/Descripción</Typography>
                        </Grid>
                        <Grid item md={2} xs={5} >
                            <Typography fontSize={18} fontWeight={'bold'} >UDM</Typography>
                        </Grid>
                        {/* <Grid item md={1.8} >
                            <Typography fontSize={18} fontWeight={'bold'} >Precio Unitario</Typography>
                        </Grid> */}
                        <Grid item md={2} xs={5} >
                            <Typography fontSize={18} fontWeight={'bold'} >Categoría</Typography>
                        </Grid>
                        <Grid item md={2} xs={5} >
                            <Typography fontSize={18} fontWeight={'bold'} >  </Typography>
                        </Grid>
                    </Grid>
                </Box>

                {
                    modelF.length < 1 ?

                        <>
                            {
                                model && model.map((models, j) => {



                                    return (

                                        <Box key={j} >
                                            <Box key={j} padding={2} >
                                                <Grid container  >


                                                    {models && models.campos.map((campo, i) => {
                                                          
                                                        switch (campo.type) {
                                                            case 'code':

                                                                return (
                                                                    <Grid key={i} item md={2} xs={12}  sx={{ marginBottom:{xs:2, md:0} }}   >
                                                                        <input
                                                                            value={initial.length < 1 ? '' : initial[j].code}
                                                                            onChange={(e) => handleOnchage(j, e)}
                                                                            name={campo.name}
                                                                            className={styles.inputAddP}
                                                                            placeholder={campo.labeCodigo}
                                                                            type="text"

                                                                        />

                                                                    </Grid>
                                                                );

                                                            case 'product':
                                                                return (
                                                                    <Grid key={i} item md={3.8} xs={12}  sx={{ marginBottom:{xs:2, md:0} }}  >
                                                                        <input
                                                                            value={initial.length < 1 ? '' : initial[j].name}
                                                                            onChange={

                                                                                (e) => handleOnchage(j, e)
                                                                            } name={campo.name} className={styles.inputAddP} placeholder={campo.labeProduct} type="text" />

                                                                    </Grid>

                                                                )
                                                            case 'udm':
                                                                return (
                                                                    <Grid key={i} item md={2} xs={12} sx={{ marginBottom:{xs:2, md:0} }}  >

                                                                        <select
                                                                            value={initial.length < 1 ? '' : initial[j].measurementUnit}
                                                                            onChange={

                                                                                (e) => handleOnchage(j, e)
                                                                            } name={campo.name} className={styles.selectAdd} >

                                                                            {udm && udm.map((udm) => {
                                                                                return <option key={udm.id} value={udm.name} >{udm.name} </option>
                                                                            })}


                                                                        </select>


                                                                    </Grid>

                                                                )
                                                            case 'category':
                                                                return (
                                                                    <Grid key={i} item md={2} xs={5}  sx={{ marginBottom:{xs:2, md:0} }}  >
                                                                        <select
                                                                            value={initial.length < 1 ? '' : initial[j].subcategoryId}
                                                                            onChange={

                                                                                (e) => handleOnchage(j, e)
                                                                            } name={campo.name} className={styles.selectAdd} >

                                                                            <option value="">{campo.labelCategory} </option>
                                                                            {
                                                                                subCategories && subCategories.map((category, i) => {
                                                                                    return (
                                                                                        <optgroup key={i} style={{ fontSize: 12, color: '#49A35B' }} label={category.categoryName}  >
                                                                                             {
                                                                                category.subcategories.map((subCate, j) => {

                                                                                    return <option key={j} style={{ fontSize: 14, color: 'black' }} value={subCate.subcategoryId} > {subCate.subcategoryName}   </option>

                                                                                })
                                                                            }
                                                                                            {/* <option style={{ fontSize: 14, color: 'black' }} value={category.subcategoryId} > {category.subcategoryName}   </option> */}
                                                                                        </optgroup>

                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>

                                                                    </Grid>

                                                                )
                                                            default:

                                                                break;
                                                        }



                                                    })}
                                                    <Grid item md={2} display='flex' alignItems='center' justifyContent={'center'} xs={5}>
                                                        <IconButton onClick={() => deleteItem(j)} aria-label="delete" size="large">
                                                            <img src={dele} alt="" />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>

                                            </Box>
                                            <Box sx={{ display:{ xs:'block', md:'none' } }} bgcolor={'#D8D9DA'} >
                                              <Divider  />
                                            </Box>
                                            
                                        </Box>
 

                                    )

                                })
                            }
                            <Box sx={{ display:{ xs:'block', md:'flex' }, marginBottom:{ xs:2, md:0}   }} padding={2}  flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}  >
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}  >
                                    <a className={styles.link} href="https://cado-space.nyc3.cdn.digitaloceanspaces.com/docs/template_products.xlsx">Descarga formato de productos  </a>
                                    <AiOutlineDownload className={styles.iconDow} />
                                </Box>

                                <Box sx={{ display:{ xs:'block', md:'flex' }  }} display={'flex'} flexDirection={'row'} alignItems={'center'} >
                                    <Box mr={2} sx={{ marginBottom:{ xs:2, md:0}, marginTop:{ xs:2, md:0} }} >
                                        <Button
                                            onClick={() => setShowModal(true)}
                                            variant='contained'
                                            sx={{
                                                backgroundColor: '#94A3B8', padding: 1.5, borderRadius: 8, width: 180, '&:hover': {
                                                    backgroundColor: '#94A3B8',
                                                },
                                            }} >
                                            Importar archivo
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Button
                                            type='submit'

                                            disabled={step || loading }
                                            onClick={createProducts}
                                            variant='contained'
                                            sx={{
                                                padding: 1.5, borderRadius: 8, width: 180
                                            }} >
                                            Confirmar productos
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                        :

                        <FormAddProducts
                               
                            user={user.supplierId}
                            setShowModal={setShowModal}
                            data={data}
                            udm={udm}
                            subCategories={subCategories}
                            model={modelF}
                        />

                }




            </Box>

        </HomeLayouts>
    )
}
