import React, { useEffect } from 'react';
import InputTag from 'components/Input';
import styles from './StyleRegister/step2.module.scss';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import SwipeDots from 'components/SwipeDots';
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';

const Step2 = ({
  registerUser,
  name,
  phone,
  email,
  password,
  handleRegisterChange,
  handleRegisterPhoneChange,
  registerData,
  goBack,
  registerError,
  disableRegister2,
  validateRegister2,
  showRegisterPassword,
  registerStep,
  setRegisterStep,
  handleRecoveryCodeChange,
  step2RegisterError,
  toLogin,
}) => {
  useEffect(validateRegister2, [name, phone, email, password]);

  return (
    <form
      className={
        step2RegisterError === false ? styles.infoContainer : styles.infoContainer2
      }
      onSubmit={(e) => registerUser(e, registerData)}
    >
      <div className={styles.headerContainer}>
        <div className={styles.backButtonContainer}>
          {/* <Back className={styles.backButton} onClick={(e) => goBack(e)} /> */}
          <p className={styles.authHeader}>Registro</p>
        </div>
        <p className={styles.authText}>
          Por favor ingresa tus datos personales para continuar.
        </p>
        <div className={styles.inputContainer}>
          <div
            className={styles.individualInputContainer}
            data-error={registerError ? registerError.name : null}
          >
            <InputTag
              type="text"
              value={name}
              name="name"
              placeholder="Nombre Completo"
              onChange={(e) => handleRegisterChange(e)}
              className={styles.input}
              required
            />
          </div>
          <div
            className={styles.individualInputContainer}
            data-error={registerError ? registerError.phone : null}
          >
            <PhoneInput
              type="text"
              value={phone}
              name="phone"
              defaultCountry="EC"
              placeholder="Teléfono"
              onChange={(e) => handleRegisterPhoneChange(e)}
              className={styles.input}
              required
            />
          </div>
          <div
            className={styles.individualInputContainer}
            data-error={registerError ? registerError.email : null}
          >
            <InputTag
              type="text"
              value={email}
              name="email"
              placeholder="Correo electrónico"
              onChange={(e) => handleRegisterChange(e)}
              className={styles.input}
              required
            />
          </div>
          <div
            className={styles.individualInputContainer}
            data-error={registerError ? registerError.password : null}
          >
            <InputTag
              type="password"
              value={password}
              name="password"
              placeholder="Contraseña (min. 8 caracteres)"
              onChange={(e) => handleRegisterChange(e)}
              className={styles.input}
              required
            />
          </div>
          {typeof registerError === 'string' ? (
            <span className={styles.errorSpan}>{registerError}</span>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <SwipeDots registerStep={registerStep} />
        <input
          type="submit"
          value="Continuar"
          className={styles.submitAuth}
          disabled={disableRegister2}
        ></input>
        <div>
          <label className={styles.texttoLogin}>¿Ya tienes una cuenta?</label> &nbsp;
          <a className={styles.toLogin} onClick={(e) => toLogin(e)}>
            Iniciar Sesión
          </a>
        </div>
      </div>
    </form >
  );
};

export default Step2;
