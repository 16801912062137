import React, { useEffect, useRef } from 'react';
import InputTag from 'components/Input';
import styles from './StyleRegister/step3.module.scss';
import SwipeDots from 'components/SwipeDots';
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';

const Step3 = ({
  handleRegisterCode,
  verifyUserRegister,
  registerCode,
  registerData,
  verifyRegisterError,
  disableRegister3,
  validateRegister3,
  goBack,
  registerStep,
  handleRegisterCodeChange,
}) => {
  useEffect(validateRegister3, [registerCode]);

  return (
    <form
      className={styles.infoContainer}
      onSubmit={(e) => verifyUserRegister(e, registerData, registerCode)}
    >
      <div className={styles.headerContainer}>
        <div className={styles.backButtonContainer}>
          <Back className={styles.backButton} onClick={(e) => goBack(e)} />
          <p className={styles.authHeader}>Registro</p>
        </div>
        <p className={styles.authText}>
          Por favor ingresa el código de verificación que hemos enviado a tu teléfono.
        </p>
        <div />
        <div
          className={styles.individualInputContainer}
          data-error={verifyRegisterError ? verifyRegisterError.code : null}
        >
          <div className={styles.styleInputContainer}>
            <input
              type="text"
              defaultValue={registerCode[0]}
              name="0"
              placeholder="0"
              className={styles.input1}
              maxLength={1}
              onKeyUp={(e) => handleRegisterCodeChange(e)}
              autoFocus
            />
            <input
              type="text"
              defaultValue={registerCode[1]}
              name="1"
              placeholder="0"
              className={styles.input2}
              onKeyUp={(e) => handleRegisterCodeChange(e)}
              maxLength={1}
            />
            <input
              type="text"
              defaultValue={registerCode[2]}
              name="2"
              placeholder="0"
              className={styles.input3}
              onKeyUp={(e) => handleRegisterCodeChange(e)}
              maxLength={1}
            />
            <input
              type="text"
              defaultValue={registerCode[3]}
              name="3"
              placeholder="0"
              className={styles.input4}
              onKeyUp={(e) => handleRegisterCodeChange(e)}
              maxLength={1}
            />
          </div>
          {typeof verifyRegisterError === 'string' ? (
            <span className={styles.errorSpan}>{verifyRegisterError}</span>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <input
          type="submit"
          value="Confirmar"
          disabled={disableRegister3}
          className={styles.submitAuth}
        ></input>
      </div>
    </form>
  );
};

export default Step3;
