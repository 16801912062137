import React from 'react';
import useAuth from 'hooks/useAuth';
import { Step1, Step2, Step3, ConfirmRegister } from 'components/Register';

const Registration = ({
  registerStep,
  nextStep,
  goBack,
  registerData,
  handleRegisterChange,
  handleRegisterPhoneChange,
  handleRegisterCode,
  registerUser,
  registerCode,
  verifyUserRegister,
  completeRegister,
  registerError,
  verifyRegisterError,
  disableRegister1,
  disableRegister2,
  disableRegister3,
  validateRegister1,
  validateRegister2,
  validateRegister3,
  showRegisterPassword,
  toLogin,
  toRecover,
  handleRegisterCodeChange,
  step2RegisterError,
}) => {
  const { name, email, phone, password, restaurantName, restaurantIdentifier } =
    registerData;

  switch (registerStep) {
    case 1:
      return (
        <Step2
          registerStep={registerStep}
          nextStep={nextStep}
          goBack={goBack}
          name={name}
          phone={phone}
          email={email}
          password={password}
          handleRegisterChange={handleRegisterChange}
          handleRegisterPhoneChange={handleRegisterPhoneChange}
          registerData={registerData}
          registerUser={registerUser}
          registerError={registerError}
          disableRegister2={disableRegister2}
          validateRegister2={validateRegister2}
          showRegisterPassword={showRegisterPassword}
          step2RegisterError={step2RegisterError}
          toLogin={toLogin}
        />
      );
    case 2:
      return (
        <Step3
          registerStep={registerStep}
          nextStep={nextStep}
          registerCode={registerCode}
          registerData={registerData}
          handleRegisterCode={handleRegisterCode}
          verifyUserRegister={verifyUserRegister}
          verifyRegisterError={verifyRegisterError}
          disableRegister3={disableRegister3}
          validateRegister3={validateRegister3}
          handleRegisterCodeChange={handleRegisterCodeChange}
          goBack={goBack}
        />
      );
    case 3:
      return <ConfirmRegister completeRegister={completeRegister} />;
  }
};

export default Registration;
