import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { getOrderById } from 'api/orders';

import Loading from 'components/Loading';
import { toast } from 'react-hot-toast';

import CTA from 'components/CTA';

import styles from 'styles/common/confirmation.module.scss';

import { getArrayOfErrors } from 'utils/errors';
import { getDisplayDate } from 'utils/dates';
import { HomeLayouts } from '../../../../components/layouts/HomeLayout';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorToken } from '../../../../redux/authSlice/authSlice';
 
const OrderConfirmation = () => {
  const { user, token } = useSelector((state) => state.auth)
  const { id, restaurant } = useParams();
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);

//id_user, idRestaurant, id, token
  useEffect(async () => {
    try {
     const order = await getOrderById( user?.id, restaurant, id, token);
      setOrder(order);
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);

        if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {

          dispatch(setErrorToken(true))

        } else {
          for (let i = 0; i < errors.length; i++) {
            toast.error(errors[i]);
          }
        }



      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    } finally {
      setLoading(false);
    }
  }, []);

  if (loading) return  <HomeLayouts><Loading /></HomeLayouts> ;
  if (!order) return <HomeLayouts></HomeLayouts>;
  if (order.status !== 'aceptada') return <Navigate to="/" />;

  const details = [
    {
      label: 'Cliente: ',
      value: order.restaurant.name,
    },
    {
      label: 'Fecha de entrega estimada: ',
      value: getDisplayDate(order.scheduleDate),
    },
  ];

  return (



    <HomeLayouts>
 <div className={styles.content}>
      <div className={styles.mainContainer}>
        <h1 className={styles.title}>¡Orden Aceptada!</h1>
        <h2 className={styles.subtitle}>
          Has aceptado la orden solicitada por {order.restaurant.name}. ¡Tu cliente
          está esperando tus productos!
        </h2>
        <div className={styles.detailsContainer}>
          <h3 className={styles.orderNumber}>Orden # {order.code}</h3>
          {details.map((detail) => (
            <div className={styles.detail} key={detail.label}>
              <span className={styles.label}>{detail.label}</span>
              <span className={styles.value}>{detail.value}</span>
            </div>
          ))}
          <span
            className={styles.span}
            onClick={() => {
              navigate(`/ordenes/detalle/${id}/${restaurant}`);
            }}
          >
            Ver Detalle
          </span>
        </div>
      </div>
      <div className={styles.ctaContainer}>
        <CTA
          text="Ir a Mis Órdenes"
          isDisabled={false}
          onClick={() => {
            navigate('/ordenes');
          }}
        />
      </div>
    </div>
    </HomeLayouts>

   
  );
};

export default OrderConfirmation;
