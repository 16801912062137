import React from 'react';
import styles from '../../../PopUp/pop-up.module.scss'
import CTARed from 'components/CTARed';
import { useState } from 'react';


const CancelOrder = ({
    setShowModal,
    suppliuer,
    date,
    onClick

}) => {

    const [isLoading, setIsloading] = useState(false)

    const handleSubmit = () => {

        setIsloading(true)
        onClick()
        setTimeout(() => {
            setShowModal(false)

            setIsloading(false)
        }, 500);

    }


    return (
        <div className={styles.content}>
            <h1 className={styles.title}>Cancelar Orden</h1>
            <h2 className={styles.subtitle}> Desea cancelar la orden de <span className={styles.span} > {`${suppliuer}`} </span>  para el <span className={styles.span} > {`${date}? `} </span>   </h2>


            <div className={styles.bottomContainer}>
                <CTARed
                    isDisabled={isLoading}
                    text="Confirmar"
                    onClick={handleSubmit}
                />
            </div>
        </div>
    );
};



export default CancelOrder;
