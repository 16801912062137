import axios from '../lib/axios';



export const changeContactClient = async (id,  data, token) => {
  const response = await axios.put(`${process.env.API_URL}/suppliers/${id}/contact`, data,
  
  {
    withCredentials:true,
   headers: {
       'Authorization': `Bearer ${token}`,
       'Accept': '*/*',
       'content-type': 'application/json'
       
   }
 } 

  );

  return response.data;
};







































export const getCurrentSupplier = async () => {
  const response = await axios.get('/suppliers/current');
  return response.data;
};



//