/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';

import { changePassword, getSubscription, getUser } from '../../api/user';

import { useNavigate } from 'react-router-dom';

import useStore from 'store';
import { toast } from 'react-hot-toast';
import { getArrayOfErrors } from 'utils/errors';

import useAuth from '../../hooks/useAuth';

import InputTag from '../../components/Input';

import styles from './password.module.scss';
import 'rc-tooltip/assets/bootstrap_white.css';
import { useDispatch, useSelector } from 'react-redux';
import { HomeLayouts } from '../../components/layouts/HomeLayout';
import { setUser } from '../../redux/authSlice/authSlice';


const Password = () => {
    const { registerError } = useAuth();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { user, pass, token } = useSelector((state) => state.auth)
    const userDataLogin = useStore((state) => state.dataLogin);
    const [password, setPassword] = useState('');
    const [disabledRegister, setDisabledRegister] = useState(true);
   

    useEffect(() => {
        if (password.length >= 8) return setDisabledRegister(false);
        return setDisabledRegister(true);

    }, [password]);

    useEffect(() => {
        if (user.complete) {
            navigate('/');
        }
    }, [user]);





    const handlePassword = (e) => setPassword(e.target.value);



    const handleSendPassword = async (e) => {
        e.preventDefault();

        try {
            const passwordObject = {
                currentPassword: pass,
                newPassword: password,
            };
            const response = await changePassword(passwordObject, user?.id, token);
         
              const userf = await getUser( token, user?.phone);
                const subscription = await getSubscription(user?.id, token )
                dispatch(setUser( {user:userf, status:'authenticated',  subscription:subscription.data } ))
            
        } catch (error) {
            if (error.response) {
                const errors = getArrayOfErrors(error.response.data);
                for (let i = 0; i < errors.length; i++) {
                    toast.error(errors[i]);
                }
            } else {
                toast.error('Error interno. Inténtelo más tarde');
            }
        }
    }

    return (

        <HomeLayouts>
            <div>
                <form className={styles.infoContainer} onSubmit={(e) => handleSendPassword(e)}>
                    <div className={styles.content}>
                        <div className={styles.headerContainer}>
                            <h1 className={styles.header}>Crear Nueva Contraseña</h1>
                        </div>
                        <div className={styles.inputContainer}>
                            <div
                                className={styles.individualInputContainer}
                                data-error={registerError ? registerError.password : null}
                            >
                                <InputTag
                                    type="password"
                                    value={password}
                                    name="password"
                                    placeholder="Contraseña (min. 8 caracteres)"
                                    onChange={handlePassword}
                                    className={styles.input}
                                    required
                                />
                            </div>
                        </div>
                        <div className={styles.buttonContainer}>
                            <input
                                type="submit"
                                value="Continuar"
                                className={styles.submitAuth}
                                disabled={disabledRegister}
                            ></input>
                        </div>
                    </div>
                </form>
            </div>
        </HomeLayouts>

    );
};

export default Password;
