

import React, { useEffect, useState } from 'react'
import { HomeLayouts } from '../../components/layouts/HomeLayout'
import { useDispatch, useSelector } from 'react-redux'
import { Customers } from './Customers'
import { Products } from './Products'
import { Typography } from '@mui/material'
import Summary from './Summary/Summary'
import { Resume } from './components/Resume'
import { useLocation } from 'react-router-dom'
import { setStep } from '../../redux/newOrderSlice/nOrderSlice'
import Confirmation from './components/Confirmation/Confirmation'

export const NewOrder = () => {

  const { step, customer } = useSelector((state) => state.ordern)
const dispatch = useDispatch()
  const stateL = useLocation();

  const [state, setState] = useState({
    supplierId: JSON.parse(localStorage.getItem('state'))?.supplierId || null,
    products: JSON.parse(localStorage.getItem('state'))?.products || [],
    note: JSON.parse(localStorage.getItem('state'))?.note || '',
    orderId: JSON.parse(localStorage.getItem('state'))?.orderId || null,
    subtotal: 0,
    iva: 0,
    total: 0
  });
  const resetProcess = () => {
    window.localStorage.removeItem('state');
    setState({
      supplierId: null,
      products: [],
      note: '',
      orderId: null,
    });
    dispatch(setStep({ step: 1, customer: '' }))
  };

  useEffect(() => {
    window.localStorage.setItem('state', JSON.stringify(state));
  }, [state]);


  useEffect(() => {
    setState({
      ...state,
      supplierId: customer,
    })
  }, [customer])



  return (

    <HomeLayouts>

      {
        step === 1 ?
          <>
            <Customers state={state} setState={setState} />
          </>

          : step === 2 ?
            <>
              <Products state={state} setState={setState} />
            </>

            : step === 3 ?

              <>
                <Summary state={state} setState={setState} />
              </>
              :
              <>
                
        <Confirmation
         
          setState={setState}
          state={stateL.state != null ? stateL.state.approvedOrder : state}
          resetProcess={resetProcess}
        />
    
              </>

      }


    </HomeLayouts>

  )
}
