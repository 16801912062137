import { getAllUsers } from "../../api/user"
import { setErrorToken, setUser } from "../authSlice/authSlice"
import { setError, setUsers, startUsers } from "./usersSlice"









export const getUsers = (id, token, iduser, ) => {
    //  const store = useStore((state) => state);


    return async (dispatch) => {

        dispatch(startUsers())

        try {

            const users = await getAllUsers(id, token)
            const creationDate = new Date();
            const usersOr =  users.sort(function(a, b){

                if (a.id === iduser) {
                    return  -1
                }        

            })
            const usersList = usersOr.map(val => {
                return {
                    id: val.id,
                    name: val.name,
                    role: val.secondaryRole === null ? 'OWNER' : val.secondaryRole,
                    phone: val.phone,
                    clientes: val.clientes,
                    local: val.restaurant,
                    actions: "",
                    creation: `Se unió: ${creationDate.toLocaleString('en-US', { month: 'short', })} ${creationDate.getDay().toString()}, ${creationDate.getFullYear()}`,

                }
            });

            const userListObject = usersOr.map(val => {
                return {
                    id: val.id,
                    name: val.name,
                    role: val.secondaryRole === null ? 'OWNER' : val.secondaryRole,
                    phone: val.phone,
                    clientes: val.clientes,
                    local: val.restaurant,
                    actions: "",
                    creation: `Se unió: ${creationDate.toLocaleString('en-US', { month: 'short', })} ${creationDate.getDay().toString()}, ${creationDate.getFullYear()}`,

                }
            });

            const admin = userListObject.filter((user) => {
                return user.role === 'ADMIN'

            })

            const asesor = userListObject.filter((user) => {
                return user.role === 'ASESOR'

            })


            const orderUser = userListObject.sort((a,b)=>{
                  
                if (a.name < b.name) {
                    return -1;
                }
             
                if (a.name > b.name) {
                    return 1;
                }
             
                return 0;

            })

                 
            dispatch(setUsers(
                {
                    users: orderUser,
                    usersOr:usersList,
                    admin: admin.length,
                    asesor: asesor.length
                }
            ))


        } catch (error) {

            if (error.response) {
                if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {
                     dispatch(setErrorToken(true))
                 } else {
                     dispatch(setError(error.response?.data))
                 }
             } else {
                 dispatch(setError('Error interno. Inténtelo más tarde'))
            }
         
           
        }


    }
}



