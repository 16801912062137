import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getOrderById } from 'api/orders';
import PropTypes from 'prop-types';

import Loading from 'components/Loading';
import { toast } from 'react-hot-toast';

import { getDisplayDate } from 'utils/dates';

import { getArrayOfErrors } from 'utils/errors';

import CTA from 'components/CTA';

import styles from './confirmation.module.scss';

import useStore from 'store';
import { useDispatch, useSelector } from 'react-redux';


import { HomeLayouts } from '../../../../components/layouts/HomeLayout';


const Confirmation = ({ state, resetProcess }) => {
  const { user, token } = useSelector((state) => state.auth)
 // const { orders_allowed } = useSelector((state) => state.orderReducer)
 
  const dispatch = useDispatch()
  //const user = useStore((state) => state.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);




  const redirecc = () => {

    navigate('/ordenes/nueva')
    resetProcess();

  }

  useEffect(async () => {
    try {
      
      const order = await getOrderById(user.id, state.supplierId, state.orderId === undefined ? state.id : state.orderId, token);

      setOrder(order);
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    } finally {
      setLoading(false);
    }
  }, []);

  if (loading) return <Loading />;
  if (!order) return null;

  const details = [
    {
      label: 'Comercio: ',
      value: order.restaurant.name,
    },
    {
      label: 'Contacto: ',
      value: order.restaurantUser.name,
    },
    {
      label: 'Fecha de entrega estimada: ',
      value: getDisplayDate(order.scheduleDate),
    },
  ];

 

  return (

    <>
      <div className={styles.content}>
        <div className={styles.mainContainer}>
          <h1 className={styles.title}>¡Orden Creada!</h1>
          <h2 className={styles.subtitle}>
          Tu orden se ha creado exitosamente. Tu cliente recibirá una notificación de lo que ordenó.
          </h2>
          <div className={styles.detailsContainer}>
            <h3 className={styles.orderNumber}>Orden # {order.code}</h3>
            {details.map((detail) => (
              <div className={styles.detail} key={detail.label}>
                <span className={styles.label}>{detail.label}</span>
                <span className={styles.value}>{detail.value}</span>
              </div>
            ))}
            <span
              className={styles.span}
              onClick={() => {
                navigate(
                  `/ordenes/detalle/${order.code}/${state.supplierId}`,
                );
              }}
            >
              Ver Detalle
            </span>
          </div>
        </div>
        <div className={styles.ctaContainer}>
        
            <CTA
              text="Nueva Orden"             
              onClick={redirecc}
            />
          
          <CTA
            text="Ir a Mis Órdenes"
            isDisabled={false}
            onClick={() => {
             
              navigate('/ordenes');
              window.localStorage.setItem('state', JSON.stringify({}));
              window.localStorage.setItem('step', 1);
            }}
            color="secondary"
          />
        </div>
      </div>
    </>

  );
};

Confirmation.propTypes = {
  state: PropTypes.object.isRequired,
  resetProcess: PropTypes.func.isRequired,
};

export default Confirmation;
