import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import routes from '../routeList.js';
import useStore from 'store';

const PrivateRoute = ({ children }) => {
  const store = useStore((state) => state);
  if (store.user) return children;
  return <Navigate to={routes.login} />;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
