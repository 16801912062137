import React, { useEffect } from 'react'
import { HomeLayouts } from '../../../components/layouts/HomeLayout'
import { Box, Button, Container, Grid, Typography } from '@mui/material'

import styles from '../message.module.scss'
import Loading from '../../../components/Loading/Loading'
import { IoChevronBack as Back } from '@react-icons/all-files/io5/IoChevronBack';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { getArrayOfErrors } from 'utils/errors';
import { _renderMessage } from './RenderMessage/RenderMessage';
import { CTAMUI } from '../../../components/CTA/CTAMUI';
import { createTemplateSupplier, getAlltemplates } from '../../../api/templates';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { setData, setValidate } from '../../../redux/templateSlice/templateSlice';
import { Confirmation } from './Confirmation/Confirmation'


export const Message = () => {


    const [step, setStep] = useState(1)
    const dispatch = useDispatch()
    const { data, validate } = useSelector((state) => state.template)
    const [isloading, setIsloading] = useState(true)
    const [loading, setLoading] = useState(false)
    const { user, token } = useSelector((state) => state.auth)
    const [val, setVal] = useState([])
    const [inputs, setInputs] = useState([])
    const [templates, setTemplates] = useState([])
    const [formData, setFormData] = useState({
        msg: '',
        templateId: '',
        name: "",
        clients: [
        ],
        values: [
        ],
        recurrent: false,
        fromsend: "",
        hoursend: "",
        day: "",
        fromsend: "",
        tosend: "",
        hoursend: "",
        text: null,
    },)

    const navigate = useNavigate();


    useEffect(() => {

        gettempl()

    }, [])

 




    // const datafull = ()=>{


    //     if (data?.text === false && data?.templateId !== '' && data?.name !== '') {
    //         setIsloading(false)
    //     }else if (data?.text === true && data?.templateId !== '' && data?.name !== '' && data?.values.length > 0) {
    //         setIsloading(false)
    //     }else{
    //         setIsloading(true)
    //     }

    // }

    const gettempl = async () => {

        setLoading(true)

        try {
            const templates = await getAlltemplates(token)
            setTemplates(templates)
            setLoading(false)
        } catch (error) {
            if (error.response) {
                setLoading(false)
                const errors = getArrayOfErrors(error.response.data);
                for (let i = 0; i < errors.length; i++) {
                    toast.error(errors[i]);
                }
            } else {
                setLoading(false)
                toast.error('Error interno. Inténtelo más tarde');
            }

        }
    }



    const next = async () => {

        if (step === 3 && data.recurrent === false) {

            const dataF = {
                templateId: data.templateId,
                name: data.name,
                clients: data.clients,
                values: data.values,
                recurrent: data.recurrent,
                fromsend: data.fromsend,
                hoursend: data.hoursend

            }

            try {
                const create = await createTemplateSupplier(user.supplierId, dataF, token)
                setStep(step + 1)
            } catch (error) {
                if (error.response) {
                    const errors = getArrayOfErrors(error.response.data);
                    for (let i = 0; i < errors.length; i++) {
                        toast.error(errors[i]);
                    }
                } else {
                    toast.error('Error interno. Inténtelo más tarde');
                }
                setStep(3)
            }


        } else if (step === 3 && data.recurrent === true) {
            const dataF = {
                templateId: data.templateId,
                name: data.name,
                clients: data.clients,
                values: data.values,
                recurrent: data.recurrent,
                fromsend: data.fromsend,
                day: data.day,
                tosend: data.tosend,
                hoursend: data.hoursend

            }

            try {
                const create = await createTemplateSupplier(user.supplierId, dataF, token)
                setStep(step + 1)
            } catch (error) {
                if (error.response) {
                    const errors = getArrayOfErrors(error.response.data);
                    for (let i = 0; i < errors.length; i++) {
                        toast.error(errors[i]);
                    }
                } else {
                    toast.error('Error interno. Inténtelo más tarde');
                }
                setStep(3)
            }


        } else {
            setStep(step + 1)
            dispatch(setValidate(false))
        }



    }

    const prev = () => {
        dispatch(setData(data))
        setStep(step - 1)

    }


    if (loading) return <HomeLayouts><Loading /></HomeLayouts>;


    return (

        <HomeLayouts>

            {
                step === 4 ?


                    <Confirmation />   
                
                :

                    <Container>
                        <Box padding={3}  >
                            <Grid
                                display='flex'
                                flexDirection='row'
                                alignItems='center'
                                justifyContent='space-between'
                                container >
                                <Grid
                                    display={'flex'}
                                    flexDirection='row'
                                    alignItems='center'
                                    item

                                >

                                    <Back
                                        cursor={'pointer'}
                                        fontSize={25}
                                        className={styles.back}
                                        onClick={() => navigate(`/mensajes`)}
                                    />
                                    <Typography
                                        fontSize={28}
                                        fontWeight='bold'
                                    > Nuevo Mensaje </Typography>
                                </Grid>

                            </Grid>
                        </Box>


                        <Box  padding={4} mt={5} >

                            <Grid
                                sx={{ border: 'solid 2px #E9E7E2', borderBottomLeftRadius: 6, borderBottomRightRadius: 6 }}
                             
                                container
                            >
                                <Grid
                                    sx={{
                                        borderRight: 'solid 2px #E9E7E2',
                                       
                                    }}
                                    item
                                 
                                    padding={3}
                                    md={2.5}
                                    xs={12}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: { xs: 'row', md: 'column' }

                                        }}
                                        display='flex'

                                    >
                                        <Box
                                            sx={{
                                                display: { xs: step === 1 ? 'flex' : 'none', md: 'flex' },
                                                marginBottom: { xs: 2, md: 6 }
                                            }}

                                            mb={6}
                                            component={'div'}
                                        >
                                            <Typography


                                                sx={{ fontSize: { xs: 20, md: 16 }, textAlign: { md: 'start', xs: 'center' } }}
                                                fontWeight={step === 1 ? 'bold' : null}
                                            >
                                                Detalle
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: { xs: step === 2 ? 'flex' : 'none', md: 'flex' },
                                                marginBottom: { xs: 2, md: 6 }
                                            }}

                                            component={'div'}
                                        >
                                            <Typography
                                                sx={{ fontSize: { xs: 20, md: 16 }, textAlign: { md: 'start', xs: 'center' } }}
                                                fontWeight={step === 2 ? 'bold' : null}
                                            >
                                                Recurrencia
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: { xs: step === 3 ? 'flex' : 'none', md: 'flex' },
                                                marginBottom: { xs: 2, md: 6 }
                                            }}
                                            mb={6}
                                            component={'div'}
                                        >
                                            <Typography
                                                sx={{ fontSize: { xs: 20, md: 16 }, textAlign: { md: 'start', xs: 'center' } }}
                                                fontWeight={step === 3 ? 'bold' : null}
                                            >
                                                Seleccionar cliente
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid
                                    xs={12}
                                    item
                                    padding={2}
                                    md={9}
                                >
                                    {
                                        _renderMessage(step, templates, setFormData, formData, val, setVal, inputs, setInputs)
                                    }
                                </Grid>
                            </Grid>
                            <Box

                                mt={2}
                                padding={2}
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'end'}
                                gap={2}
                            >
                                <CTAMUI
                                    width={100}
                                    onClick={prev}
                                    disabled={step === 1 ? true : false}
                                    color='#49A35B'
                                    borderColor='#49A35B'
                                    backgroundColor='white'
                                    text='Volver'
                                />
                                <CTAMUI
                                    disabled={!validate ? true : false}
                                    width={100}
                                    onClick={next}
                                    text='Continuar'
                                    backgroundColor='#49A35B'
                                    borderColor='#49A35B'
                                />
                            </Box>
                        </Box>
                    </Container>

            }
        </HomeLayouts>

    )
}
