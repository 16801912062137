import React from 'react'
import CTA from 'components/CTA';
import { HomeLayouts } from '../../components/layouts/HomeLayout'
import styles from './users.module.scss';
import Options from '../../assets/dots.png'
import Delete from '../../assets/delete.png';
import Edit from '../../assets/editing.png';
import PopUp from '../../components/PopUp';
import 'rc-tooltip/assets/bootstrap_white.css';
import Tooltip from 'rc-tooltip';
import ProfileInfo from '../../components/PopUp/components/ProfileInfo';
import { Box, Divider, Typography } from '@mui/material';
import { userListObject, usersByOwner } from './data';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import { useEffect } from 'react';

import { getUsers } from '../../redux/usersSlice/thunk';
 

const heading = ['Id', 'Nombre', 'Rol', 'Clientes', 'Local', 'Creado', 'Acciones'];

export const Users = () => {
    const { user, token } = useSelector((state) => state.auth)
    const { usersOrder, isLoadin } = useSelector((state) => state.users)
    const dispatch = useDispatch()
    const [clickedCard, setClickedCard] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showModalDeleteConfirmation, setShowModalDeleteConfirmation] = useState(false);
    const [userList, setuserList] = useState(null)
    const [popUp, setPopUp] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [idPop, setIdPop] = useState(null);
    const [userRow, setUserRow] = useState(null);
    const creationDate = new Date();

  

    useEffect(() => {

        dispatch(getUsers(user?.supplierId,token, user.id, ))

    }, [])



    const handleOpenPopUp = () => {
        if (popUp) {
            setIdPop(null);
        }
        setPopUp(!popUp)
    };
    const openRow = (index) => {

        if (index !== idPop) return setIdPop(index);
        return setIdPop(null);
    };

    const handleDeleteUser = async (row, setClick) => {


        try {
            setPopUp(false);
            setIdPop(null);
            setShowModalDeleteConfirmation(true);
            setClickedCard(setClick);
        } catch (error) {
            if (error.response) {
                const errors = getArrayOfErrors(error.response.data);
                for (let i = 0; i < errors.length; i++) {
                    toast.error(errors[i]);
                }
            } else {
                toast.error('Error interno. Inténtelo más tarde');
            }
        }
    };

    const popOverComponent = (row) => {

        if (row.role != 'OWNER') {
            return (
                <>
    
                    
    
                    <div className={styles.container} onClick={() => handleDeleteUser(row, 'userDelete')}>
                        <div className={styles.image}>
                            <img src={Delete} width={24} height={22} />
                        </div>
                        <div className={styles.text}>
                            Eliminar usuario
                        </div>
                    </div>
                    {/* <div className={styles.containerSup} onClick={() => handleDeleteUser(row, 'updateUser')}>
                        <div className={styles.image}>
                            <img src={Edit} width={25} height={22} />                                            </div>
                        <div className={styles.text}>
                            Editar
                        </div>
                    </div> */}
                    {/* <Divider /> */}
    
    
                    {/* {
                    user?.roles[0].name === 'Administrator' || user?.roles[0].name === 'Super Admin' ?
                        <div className={styles.container} onClick={() => handleDeleteUser(row, 'userDelete')}>
                            <div className={styles.image}>
                                <img src={Delete} width={10} height={15} />
                            </div>
                            <div className={styles.text}>
                                Eliminar usuario
                            </div>
                        </div> :
    
                        <>
    
                            {
                                user?.approval === true && row.role === 'Administrator' || user?.approval === true && row.role === 'Super Admin' ?
    
                                    <>
    
                                        <div className={styles.containerSup} onClick={() => handleDeleteUser(row, 'updateUser')}>
                                            <div className={styles.image}>
                                                <img src={Edit} width={25} height={22} />                                            </div>
                                            <div className={styles.text}>
                                                Editar
                                            </div>
                                        </div>
                                        <Divider />
                                    </>
                                    : null
    
                            }
    
                            <div className={styles.container} onClick={() => handleDeleteUser(row, 'userDelete')}>
                                <div className={styles.image}>
                                    <img src={Delete} width={25} height={20} />
                                </div>
                                <div className={styles.text}>
                                    Eliminar usuario
                                </div>
                            </div>
    
                        </>
    
                } */}
    
    
    
                </>
    
            );
        }else{
            return null
        }


      
    };

    if (isLoadin) {
        return <HomeLayouts>
            <Loading />
        </HomeLayouts>
    }

    return (

        <HomeLayouts>


            <div className={styles.content} >
                <PopUp showModal={showModal} setShowModal={setShowModal}>
                    <ProfileInfo
                        clickedCard={clickedCard}
                        user={user}
                        setShowModal={setShowModal}
                        local={[]}
                    />
                </PopUp>
                <PopUp showModal={showModalDeleteConfirmation} setShowModal={setShowModalDeleteConfirmation}>
                    <ProfileInfo
                        clickedCard={clickedCard}
                        user={user}
                        row={userRow}
                        setShowModal={setShowModal}
                        local={[]}
                    />
                </PopUp>
                <div className={styles.headerContainer}>
                    <h1 className={styles.header}>Usuarios</h1>
                    <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }} >
                        <CTA

                            text="Añadir Usuario"
                            onClick={() => {
                                setClickedCard('usersByOwner');
                                setShowModal(true);
                            }}
                            size="small"
                        />
                    </Box>
                </div>
                <div>
                    <table className={styles.tableUsers}>
                        <thead>
                            <tr>
                                {heading.map((head, i) =>

                                    <th style={{ display: i === 0 || i === 4 || i === 5 ? 'none' : null }} key={i}>{head}

                                    </th>)}
                            </tr>
                        </thead>
                        <tbody>

                                    

                            {usersOrder && usersOrder.map((row, index) => {

           // console.log(Object.values(row))                                 
                                return (
                                    <tr key={index} style={{ backgroundColor: index === 0 ? '#E9E7E2' : null }}>

                                        {Object.values(row).map((val, i) => {                                         

                                            return <td
                                                style={{ display: i === 0 || i === 3 || i === 5 || i === 7 ? 'none' : null }}
                                                className={`${i === 1 ? styles.leftColor : i === 6 ? styles.rightColor : styles.test} ${styles.tdUsers} ${styles.containerTd} `}
                                                key={i}>
                                                { row.role != 'OWNER' && i === 6 && index !== 0 ?

                                                
                                                   
                                                    <Tooltip

                                                    placement='left'
                                                    visible={index === idPop && popUp}
                                                    animation='zoom'
                                                    onVisibleChange={handleOpenPopUp}
                                                    trigger='click'
                                                    overlay={popOverComponent(row)}
                                                    arrowContent={<div className="rc-tooltip-arrow-inner" ></div>}
                                                    id={i}
                                                >
                                                                                                               
                                                        <img
                                                        src={Options}
                                                        alt='restaurants'
                                                        width='25px'
                                                        height='12px'
                                                        className={styles.iconOptions}
                                                        onClick={() => {
                                                            setUserRow(row);
                                                            openRow(index);
                                                        }} /> 
                                                                                                        
                                                </Tooltip> 
                                                
                                                
                                                  
                                                    :
                                                    <Box  >

                                                        {i === 1 ?

                                                            <>
                                                                <Typography> {val} </Typography>
                                                                <Typography> {row.phone} </Typography>
                                                            </>
                                                            :
                                                            val
                                                        }

                                                    </Box>
                                                }
                                            </td>
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </HomeLayouts>
    )
}
