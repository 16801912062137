import { getCategories, getCategoriesAll, getSubCategories } from "../../api/categories"
import { setCategories, setCategoriesAll, setError, setSubCategories, setSubCategoriesBycat, startCategories } from "./categoriesSlice"
import { setErrorToken } from '../authSlice/authSlice'





export const gatCatego = (id, limit, page, token) => {


    return async (dispatch) => {

        dispatch(startCategories())

        try {

            const categories = await getCategories(id, limit, page, token)



            dispatch(setCategories(categories))


        } catch (error) {

            if (error.response) {
                if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {
                     dispatch(setErrorToken(true))
                 } else {
                     dispatch(setError(error.response?.data))
                 }
             } else {
                 dispatch(setError('Error interno. Inténtelo más tarde'))
                 }
        }


    }
}


export const getCategoAll = (id, token) => {


    return async (dispatch) => {

        dispatch(startCategories())

        try {

            const categories = await getCategoriesAll(id, token)

            const categoriesId = categories.map((categorie)=>{

                return {
                    id:categorie.id,
                    name:categorie.name
                }

            })

            dispatch(setCategoriesAll(categoriesId))

        } catch (error) {
            if (error.response) {
                if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {
                     dispatch(setErrorToken(true))
                 } else {
                     dispatch(setError(error.response?.data))
                 }
             } else {
                 dispatch(setError('Error interno. Inténtelo más tarde'))
                 }
        }
    }
}


export const gatCategoPag = (id, limit, page, token) => {


    return async (dispatch) => {



        try {

            const categories = await getCategories(id, limit, page, token)

            dispatch(setCategories(categories))


        } catch (error) {


            if (error.response) {
               if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {
                    dispatch(setErrorToken(true))
                } else {
                    dispatch(setError(error.response?.data))
                }
            } else {
                dispatch(setError('Error interno. Inténtelo más tarde'))
                }


        }


    }
}

export const gatSubCateBycat = (token) => {


    return async (dispatch) => {


        try {

            const categories = await getSubCategories(token)

            dispatch(setSubCategoriesBycat(categories))


        } catch (error) {

            dispatch(setError(error.response?.data))
            // setLoginError(error.response?.data);
        }


    }
}

export const gatSubCate = (token) => {


    return async (dispatch) => {


        try {

            const categories = await getSubCategories(token)

            const result = {};

            // Recorre el array de datos
            for (const item of categories) {
                const { categoryName, subcategoryName, subcategoryId } = item;
            
                // Si la categoría ya existe en el resultado, agrega la subcategoría
                if (result.hasOwnProperty(categoryName)) {
                    result[categoryName].subcategories.push({ subcategoryName, subcategoryId });
                } else {
                    // Si la categoría no existe, crea una nueva entrada
                    result[categoryName] = {
                        categoryName,
                        subcategories: [{ subcategoryName, subcategoryId }]
                    };
                }
            }
            
            // Convierte el objeto resultante en un array
            const finalResult = Object.values(result);
       
             dispatch(setSubCategories(finalResult))


        } catch (error) {

            if (error.response) {
                if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {
                     dispatch(setErrorToken(true))
                 } else {
                     dispatch(setError(error.response?.data))
                 }
             } else {
                 dispatch(setError('Error interno. Inténtelo más tarde'))
            }
        }


    }
}

