





import { createSlice } from "@reduxjs/toolkit";




const initialState = {

    categories:null,
    categoriesAll:null,
    isLoading:false,
    subCategories:[],
    subcategoriesByCateg:[],
    errorData:null,
  
} 



 export const  categoriesSlice = createSlice({

    name:'categories',
    initialState:initialState,
    reducers:{
        startCategories:(state)=>{
            state.isLoading = true
        },
        setCategories: (state, action )=>{
                            
            state.categories=action.payload,
            state.isLoading=false,
            state.errorData=null
            
        },
        setCategoriesAll: (state, action )=>{
                            
            state.categoriesAll=action.payload,
            state.isLoading=false,
            state.errorData=null
            
        },
        setSubCategoriesBycat: (state, action )=>{
                            
            state.subcategoriesByCateg=action.payload
            state.isLoading=false,
            state.errorData=null
            
        },
        setSubCategories: (state, action )=>{
                            
            state.subCategories=action.payload
            state.isLoading=false,
            state.errorData=null
            
        },

        setError: (state, action )=>{
           
       
            state.categories=null,
            state.subCategories=[]
            state.isLoading=false,
            state.errorData=action.payload
            
        },
        clearCategories: (state )=>{

            state.categories=null,
            state.subCategories=[]
            state.categoriesAll=null,
            state.isLoading=false,
            state.errorData=null
            
        },
     
    }


})



export const {startCategories, setSubCategoriesBycat, setCategories,  setSubCategories, setError, clearCategories, setCategoriesAll } = categoriesSlice.actions




