import axios from 'axios';
import config from '../config';

const axiosApiInstance = axios.create({
  baseURL: config.apiUrl,
  withCredentials: true,
  'content-type': 'application/json',
});

export default axiosApiInstance;
