
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
//import { addUser, getUser } from '../../../../../../api/user'
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';
import SearchBar from '../../../../SearchBar/SearchBar'
import styles from './userRole.module.scss';
import CTA from '../../../../CTA';
import add from '../../../../../assets/addIcon.png'
import useStore from 'store';
import { addUser } from '../../../../../api/user';
import { useSelector } from 'react-redux';
import AutoComplete from '../../../../AutoComplete/AutoComplete';
import { Box, Typography } from '@mui/material';


const data = [

    'Ciabatta',
    'Osteria Emilio',
    'Tapas',

]

const clientes = [

    'Ciabatta',
    'Osteria Emilio',
    'Tapas',
    'pedro',
    'juan',
    'Ciabatta',
    'Osteria Emilio',
    'Tapas',
    'pedro',
    'juan'

]

const UserEdit = ({ changeStep, userInfo }) => {

    const { user } = useSelector((state) => state.auth)
    const [loading, setLoading] = useState(false);
    const [buscar, setBuscar] = useState('')
    const [filtro, setFiltro] = useState('')
    const [role, setRole] = useState();

   

    useEffect(() => {

        setRole(userInfo.role)

    }, [])



    const establecer = (dat) => {
       
        setBuscar(dat)
        setFiltro('')

    }
    const addCliente = (dat) => {

        data.push(dat)

        setBuscar('')
        setFiltro('')

    }

    const handleONchange = (val) => {
        setBuscar(val)
        busc(buscar)


    }

    const busc = (val) => {
     
        const fil = clientes.filter((cliente) => {

            return cliente.toLowerCase().includes(val)

        })

        setFiltro(fil)

    }

    const handleAddUser = async () => {


    };

    return (
        <div className={styles.contentLocal}>
            <div className={styles.header}>

                <h1 className={styles.title}>Editar Usuario</h1>
            </div>
            <div className={styles.section} style={{ marginBottom: '30px' }}>
                <h5>Usuario</h5>
                <div>{userInfo.name}</div>
                <h5>Rol</h5>
                <label htmlFor="rol_admin">
                    <input
                        type='radio'
                        id="rol_admin"
                        name="fav_language"
                        value="ADMIN"
                        onChange={(e) => {
                            setRole(e.target.value)

                        }}
                        style={{ marginRight: '10px' }}
                        checked={role === 'ADMIN' ? true : false}
                    />
                    Admin
                </label>
                <label htmlFor="rol_staff">
                    <input
                        type='radio'
                        id="rol_staff"
                        name="fav_language"
                        value="ASESOR"
                        onChange={(e) => {
                            setRole(e.target.value);

                        }}
                        style={{ marginRight: '10px' }}
                        checked={role === 'ASESOR' ? true : false}
                    />

                    Asesor

                </label>

                <h5>Clientes</h5>
                {/* <AutoComplete
                    placeholder={'Buscar clientes'}
                    suggestions={data}
                /> */}
                <Box  >


                <Box display={'flex'}>
                <SearchBar
                        query={buscar}
                        setQuery={(val) => {
                            handleONchange(val);
                        }}
                        placeholder="Buscar"
                    />


                    <Box sx={{ cursor: 'pointer' }} ml={0.5} onClick={() => addCliente(buscar)} >
                        <img src={add} alt="" />
                    </Box>
                </Box>
              
                <ul style={{ height: '200px', overflow: 'auto', display: buscar === '' ? 'none' : 'block', position:'absolute' } }  >
                <Box height={100} >
                {
                       filtro && filtro.map((cliente) => {
                           return(
                                     <Box boxShadow={1} padding={1} sx={{ "&:hover":{background:'#49A35B', color:'white' }, cursor:'pointer', bgcolor:'#e7e7e7'  }} width={300} >
                                      <li onClick={() => establecer(cliente)} > { cliente } </li>
                                     </Box>
                           )
                         
                       })
                   }
    </Box>
                </ul>
            
              


                    {/* <Box sx={{ display: buscar === '' ? 'none' : 'block' }} position={'absolute'} top={510} padding={2}  >
                   
                   {
                       filtro && filtro.map((cliente) => {
                           return(
                               <Box sx={{ "&:hover":{background:'#49A35B', color:'white' }, cursor:'pointer', bgcolor:'#d7d7d7'  }} width={280} >
                                     <Typography padding={1} onClick={() => establecer(cliente)} width={'100%'} > {cliente} </Typography>
                                     </Box>
                           )
                           
                           
                           
                           
                         
                       })
                   }

             

           </Box> */}
                </Box>
             


                {
                    data.map((dat) => {

                        return (
                            <Box padding={0.5} mt={0.5} > {dat} </Box>
                        )

                    })
                }


            </div>
            <CTA isDisabled={loading} text="Confirmar cambios" onClick={handleAddUser} />
        </div>
    );
};

UserEdit.propTypes = {
    //changeStep: PropTypes.func.isRequired,
    // setState: PropTypes.func.isRequired,
    // userInfo: PropTypes.object,
    // locations: PropTypes.array,
};

export default UserEdit;
