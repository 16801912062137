import React, { useEffect } from 'react'
import { HomeLayouts } from '../../components/layouts/HomeLayout'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import CTA from '../../components/CTA'
import { useNavigate } from 'react-router-dom'
import Loading from '../../components/Loading/Loading';
import { useDispatch, useSelector } from 'react-redux'
import { getTemplatesForSupplier } from '../../redux/templateSlice/thunks'


export const Messages = () => {
    const { user, token } = useSelector((state) => state.auth)
    const { templates, isLoading } = useSelector((state) => state.template)
    const dispatch = useDispatch()
    const navigate = useNavigate();


    useEffect(() => {

        dispatch(getTemplatesForSupplier(user?.supplierId, user?.id,  token))

    }, [])



    return (
        <HomeLayouts>

            {
                isLoading ? <Loading /> :

                    <Container>
                        <Box padding={3}>
                            <Grid
                                display='flex'
                                flexDirection='row'
                                alignItems='center'
                                justifyContent='space-between'
                                container >
                                <Grid item >
                                    <Typography
                                        fontSize={28}
                                        fontWeight='bold'
                                    > Mensajes </Typography>
                                </Grid>
                                <Grid item  >
                                    <CTA
                                        text='Nuevo Mensaje'
                                        onClick={() => navigate('/mensajes/mensaje')}

                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box
                            sx={{ marginTop: 5, padding: { md: 3, xs: 0 } }}

                        >
                            <Box

                                border={1}
                                borderColor={'#E9E7E2'}

                            >
                                <Grid
                                    padding={2}
                                    container
                                    sx={{ gap: { xs: 0, md: 0 } }}

                                    bgcolor={'#F9F6F4'}
                                    display={'flex'}
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                >
                                    <Grid
                                        md={5}
                                        xs={4}
                                        item  >
                                        <Typography
                                            fontWeight='bold'
                                            fontSize={18}
                                        >
                                            Mensajes Programados
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        md={2}
                                        xs={3}
                                        item  >
                                        <Typography
                                            fontWeight='bold'
                                            fontSize={18}
                                        >
                                            Estado
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        md={3}
                                        xs={3}
                                        item >
                                        <Typography
                                            fontWeight='bold'
                                            fontSize={18}
                                        >
                                            Destino
                                        </Typography>
                                    </Grid>



                                    <Grid
                                        md={2}
                                        xs={1}
                                        item >

                                    </Grid>

                                </Grid>




                                {/* desde aqui va el map */}

                                {
                                    templates && templates.map((template, i) => {

                                        return (
                                            <Grid

                                                key={i}
                                                padding={2}
                                                container
                                                sx={{ gap: { xs: 0, md: 0 } }}
                                                display={'flex'}
                                                flexDirection={'row'}
                                                alignItems={'center'}
                                            >
                                                <Grid
                                                    xs={4}
                                                    md={5} item  >
                                                    <Typography

                                                        fontSize={16}
                                                    >
                                                        {template.name}
                                                    </Typography>
                                                    {
                                                        template.recurrent ?
                                                            <span style={{ backgroundColor: '#D6E4EE', paddingLeft: 4, padding: 2, paddingRight: 4, fontSize: 14 }} >
                                                                Recurrente - Semanal
                                                            </span> :
                                                            null

                                                    }


                                                </Grid>
                                                <Grid
                                                    md={2}
                                                    xs={2}
                                                    item >

                                                    <Box
                                                        justifyContent={'center'}
                                                        alignItems={'center'}
                                                        borderRadius={2}
                                                        width={68}
                                                        sx={{

                                                            paddingLeft:1,
                                                            paddingTop:0.5,
                                                            paddingBottom:0.5

                                                        }}
                                                        bgcolor={ template.statusTemplate ?'#deecdc' : '#E9E7E2'}
                                                    >
                                                        <Typography

                                                            fontSize={14}
                                                        >
                                                            {template.statusTemplate ? 'Activo ' : 'Inactivo'}
                                                        </Typography>
                                                    </Box>


                                                </Grid>

                                                <Grid
                                                    md={3}
                                                    xs={4}
                                                    item >
                                                    <Typography

                                                        fontSize={16}
                                                    >
                                                        {`${template.templateClients?.length} contactos`}
                                                    </Typography>
                                                </Grid>

                                                <Grid
                                                    xs={1}
                                                    md={2}
                                                    item
                                                    display='flex'
                                                    flexDirection='row'
                                                    alignItems='center'
                                                    justifyContent='center'
                                                >
                                                    <Button
                                                        onClick={() => navigate('/mensajes/eliminar', { state: { template } })}

                                                        variant='contained'
                                                        sx={{
                                                            marginTop: { xs: 2, md: 0 },
                                                            borderRadius: 5, backgroundColor: 'transparent', border: 1, borderColor: '#e9e7e2', color: 'black',
                                                            '&:hover': {
                                                                backgroundColor: 'white',
                                                            },

                                                        }} >Ver</Button>
                                                </Grid>
                                            </Grid>
                                        )

                                    })
                                }
                            </Box>
                        </Box>
                    </Container>
            }
        </HomeLayouts>
    )
}
