/* eslint-disable prettier/prettier */

import axios from "axios";




export const getOrders = async (params, userId, id, token) => {
  const { page = 1, limit = 10, status, query = '' } = params;
  const { data } = await axios.get(
    `${process.env.API_URL}/orders/users/${userId}/suppliers/${id}?limit=${limit}&page=${page}&query=${query}${status ? `&status=${status}` : ''}`,
    {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'

      }
    }

  );

  return data;
};


export const getOrderById = async (id_user, idRestaurant, id, token) => {


  const response = await axios.get(`${process.env.API_URL}/orders/users/${id_user}/restaurants/${idRestaurant}/code/${id}`,

    {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'

      }
    });
  return response.data;

};

export const markOrderAsConfirmed = async (products, idRestaurant, id, token, user_id='') => {


  const response = await axios.post(`${process.env.API_URL}/orders/restaurants/${idRestaurant}/order/${id}/confirmation/${user_id}`,
    {
      scheduleDate: products.scheduleDate,
      noteSupplier: products.noteSupplier,
      products: products.products
    },
    {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'

      }
    });
  return response.data;

};


export const cancelOrder = async (idRestaurant, id, token, user_id='' ) => {

 

  const response = await axios.post(`${process.env.API_URL}/orders/restaurants/${idRestaurant}/order/${id}/cancelation/${user_id}`, {},

    {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'

      }
    });
  return response.data;
};

export const createOrderSupp = async (order, id_supplier, user_id,  token ) => {



  const response = await axios.post(`${process.env.API_URL}/orders/suppliers/${id_supplier}/user/${user_id}`, order,



    {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'

      }
    });
  return response.data;
};