import axios from "axios";






export const getAllproducts = async (token, supplier_id, page, limit, query, cat_id ) => {
 
    const response = await axios.get(
      `${process.env.API_URL}/suppliers/${supplier_id}/categories/${cat_id}/products?limit=${limit}&page=${page}&query=${query}`,
      {
        withCredentials:true,
       headers: {
           'Authorization': `Bearer ${token}`,
           'Accept': '*/*',
           'content-type': 'application/json'
           
       }
     } 
    );
   
  
    return response.data;
  };

  
  export const getUdm = async (token) => {
   
    const response = await axios.get(
      `${process.env.API_URL}/products/units-measure`,
      {
        withCredentials:true,
       headers: {
           'Authorization': `Bearer ${token}`,
           'Accept': '*/*',
           'content-type': 'application/json'
           
       }
     } 
    );
    
  
    return response.data;
  };

  export const updateProduct = async ( product_id, supplier_id, data, token  ) => {

    //console.log(data)

    const response = await axios.put(
      `${process.env.API_URL}/suppliers/${supplier_id}/products/${product_id}`, data,
      {
        withCredentials:true,
       headers: {
           'Authorization': `Bearer ${token}`,
           'Accept': '*/*',
           'Content-Type': 'application/json'          
       }
     }  
    );
     
    return response.data;
  };
  export const saveProducts = async (supplier_id, data, token) => {

    const response = await axios.post(
      `${process.env.API_URL}/suppliers/${supplier_id}/products-batch`, data,
      {
        withCredentials:true,
       headers: {
           'Authorization': `Bearer ${token}`,
           'Accept': '*/*',
           'content-type': 'application/json'
           
       }
     }   
    );
     
    return response.data;
  };
  // export const getProductsCateg = async (id, page, limit, query, id_cat) => {
 
  //   const response = await axios.get(
  //     `/products/suppliers/${id}/categories/${id_cat}?page=${page}&limit=${limit}&query=${query}`,
  //   );
   
  
  //   return response.data;
  // };
  export const getPriceList = async (token, id_supplier,id_product) => {
   
    const response = await axios.get(
      `${process.env.API_URL}/suppliers/${id_supplier}/products/${id_product}`,
      {
        withCredentials:true,
       headers: {
           'Authorization': `Bearer ${token}`,
           'Accept': '*/*',
           'content-type': 'application/json'
           
       }
     } 
    );
    
  
    return response.data;
  };

  export const addPriceSupplier = async (product_id, data, token) => {

    const response = await axios.post(
      `${process.env.API_URL}/products/${product_id}/prices`, data,
      {
        withCredentials:true,
       headers: {
           'Authorization': `Bearer ${token}`,
           'Accept': '*/*',
           'content-type': 'application/json'
           
       }
     }   
    );
     
    return response.data;
  };