import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';

import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';

import { getDisplayDate, getInputDate } from 'utils/dates';

import CTA from 'components/CTA';
import ProductReview from 'components/ProductReview';

import styles from 'styles/common/summary.module.scss';
import { HomeLayouts } from '../../../../components/layouts/HomeLayout';
import ProductReview2 from '../../../../components/ProductReview2/ProductReview';

const Content = ({ note, setNote, id, order, receive, setOrder, restaurant }) => {
  const navigate = useNavigate();
  const [date, setDate] = useState(getInputDate(order.scheduleDate));
  
  return (

<HomeLayouts>
<div className={styles.gridColors}>
      <div className={styles.header}>
        <Back
          className={styles.back}
          onClick={() => navigate(`/ordenes/detalle/${id}/${restaurant}`)}
        />
        <h1 className={styles.title}>Detalle</h1>
      </div>
      <div className={styles.content}>
        <div className={styles.leftSide}>
          <ProductReview2
           
            allowChanges={true}
            state={order}
            setState={setOrder}
            shouldRemoveEmpty={false}
          />
          <div>
            <p className={styles.subtitle}>Notas del Cliente</p>
            <textarea className={styles.note} value={order.note} readOnly />
            {/* <CTA
              text="Confirmar"
              onClick={() => {
                receive(date);
              }}
              view="mobile"
              isDisabled={!order.products.length}
            /> */}
          </div>
          <div>
            <p className={styles.subtitle}>Añadir Nota</p>
            <textarea
              className={styles.note}
              rows="4"
              maxLength="255" 
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
            <CTA
              text="Confirmar"
              onClick={() => {
                receive(date);
              }}
              view="mobile"
              isDisabled={!order.products.length}
            />
          </div>
        </div>
        <div className={styles.rightSide}>
          <div className={styles.detailsContainer}>
            <div className={styles.field}>
              <p className={styles.subtitle}>Orden: </p>
              <p className={styles.value}>{order.code}</p>
            </div>
            <div className={styles.field}>
              <p className={styles.subtitle}>Comercio: </p>
              <p className={styles.value}>{order.restaurant.name}</p>
            </div>
          </div>
          <div className={styles.detailsContainer}>
            <div className={styles.field}>
              <p className={styles.subtitle}>Fecha de orden: </p>
              <p className={styles.value}>{getDisplayDate(order.requestDate)}</p>
            </div>
            <div className={styles.field}>
              <p className={styles.subtitle}>Fecha de entrega: </p>
              <input
                className={styles.dateValue}
                type="date"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              />
            </div>
          </div>
          <CTA
            text="Confirmar"
            onClick={() => {
              receive(date);
            }}
            view="desktop"
            isDisabled={!order.products.length}
          />
        </div>
      </div>
    </div>
</HomeLayouts>

   
  );
};

Content.propTypes = {
  id: PropTypes.string.isRequired,
  order: PropTypes.object.isRequired,
  receive: PropTypes.func.isRequired,
  setOrder: PropTypes.func.isRequired,
  
};

export default Content;
