
import { Box, Typography } from '@mui/material'
import React from 'react'


export const Card = ( { name, customer, onclick } ) => {




  return (
    <Box
        sx={{ cursor:'pointer' }}
        component={'div'}
        onClick={onclick}
        borderRadius={5}
       
      
        padding={2}
        paddingY={3.5}
        bgcolor={'#D6E4EE'}
    >

         <Typography
            fontSize={18}
            color={'#6F6C6C'}
            fontWeight={'bold'}
         > { name } </Typography>   

    </Box>
  )
}
