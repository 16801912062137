import React from 'react'
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';
import { HomeLayouts } from '../../components/layouts/HomeLayout'
import styles from 'styles/common/summary.module.scss';
import { useNavigate } from 'react-router-dom';

export const DetailDeliv = () => {

    const navigate = useNavigate();



    return (

        <HomeLayouts>

            <div className={styles.content} >
                <div className={styles.header}>
                    <Back className={styles.back} onClick={() => navigate('/perfil')} />
                    <h1 className={styles.title}>Información de Enrega</h1>
                </div>

            </div>

        </HomeLayouts>

    )
}
