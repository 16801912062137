
import { createSlice } from "@reduxjs/toolkit";




const initialState = {

    templates:[],
    validate:false,
    data:{
        msg:'',
        templateId:'',
        name: "",
        clients: [
            ],
        values: [
            ],
        recurrent: false,
        fromsend: "",
        hoursend: "",
        day: "",
        fromsend: "",
        tosend: "",
        hoursend: "",
        text:null
    },
    error:null,
    isLoading:false
     
} 



 export const  templateSlice = createSlice({

    name:'templates',
    initialState:initialState,
    reducers:{
        startTemplates:(state)=>{
            state.isLoading = true
        },
        setTemplatesSupp: (state, action )=>{
                            
            state.templates  = action.payload,
            state.error = null,
            state.isLoading= false 
            
        },
        setValidate: (state, action )=>{
                            
            state.validate  = action.payload,
            state.error = null,
            state.isLoading= false 
            
        },
        setData: (state, action )=>{
                            
            state.data  = action.payload,
            state.error = null,
            state.isLoading= false 
            
        },     
        setError: (state, action )=>{
           
            state.templates  = [],
            state.error = action.payload,
            state.isLoading= false 
        
            
        },
        clearTemplates: (state )=>{
  
            state.templates=[],
            state.validate=false,
            state.data={
                templateId:'',
                name: "",
                clients: [
                    ],
                values: [
                    ],
                recurrent: false,
                fromsend: "",
                hoursend: "",
                day: "",
                fromsend: "",
                tosend: "",
                hoursend: "",
                text:null
            },
            state.error=null,
            state.isLoading=false
            
        },
     
    }

})


export const { 
    startTemplates,
    setTemplatesSupp,
    setError,
    clearTemplates,
    setData,
    setValidate
} = templateSlice.actions




