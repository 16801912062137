import axios from '../lib/axios';


export const getCategories = async (id, limit, page, token) => {
   
    const response = await axios.get(
      `${process.env.API_URL}/suppliers/${id}/category?limit=${limit}&page=${page}`,
      {
        withCredentials:true,
       headers: {
           'Authorization': `Bearer ${token}`,
           'Accept': '*/*',
           'content-type': 'application/json'
           
       }
     } 
    );
    return response.data;
  };

  export const getCategoriesAll = async (id, token) => {
   
    const response = await axios.get(
      `${process.env.API_URL}/suppliers/${id}/all`,
      {
        withCredentials:true,
       headers: {
           'Authorization': `Bearer ${token}`,
           'Accept': '*/*',
           'content-type': 'application/json'
           
       }
     } 
    );
    return response.data;
  };

  export const getSubCategories = async (token) => {
  
    const response = await axios.get(`${process.env.API_URL}/categories/all`,
    {
      withCredentials:true,
     headers: {
         'Authorization': `Bearer ${token}`,
         'Accept': '*/*',
         'content-type': 'application/json'
         
     }
   } 
    );
    return response.data;
  };














































  export const addCategorie = async (id, data) => {
   
    const response = await axios.post(
      `/categories/supplier/${id}`, data
    );
    return response.data;
  };
  export const addSubCategorie = async ( id_supplier, id_cat, data) => {
   
    const response = await axios.post(
      `/categories/${id_cat}/subcategories/suppliers/${id_supplier} `, data
    );
    return response.data;
  };

  