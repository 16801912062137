import axios from "axios";


export const getUser = async (token, phone) => {



  const response = await axios.post(`${process.env.API_URL}/me`,
    {
      phone
    },
    {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'

      }
    });
  return response.data;
}



export const getSubscription = async (id, token) => {



  const response = await axios.get(`${process.env.API_URL}/subscriptions/${id}`,
    {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'

      }
    });



  return response;

};



export const getAllUsers = async (id_supplier, token) => {
  const response = await axios.get(`${process.env.API_URL}/suppliers/${id_supplier}/users`,

    {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'

      }
    }
  );
  return response.data;
};

export const getAllUsersf = async (id_supplier) => {
  const response = await axios.get(`/suppliers/${id_supplier}/users`);
  return response.data;
};

export const changePassword = async (data, id, token) => {
  const response = await axios.put(`${process.env.API_URL}/me/${id}/password`, data,
    {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'

      }
    });
  return response.data;
};


export const changeContact = (data, id, token) => {
  return axios.put(`${process.env.API_URL}/me/${id}/contact-method`, data,

    {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'

      }
    }


  );
};

export const deleteUser = async (id_supplier, id_user, token) => {
  const response = await axios.delete(`${process.env.API_URL}/suppliers/${id_supplier}/users/${id_user} `,
    {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'

      }
    }
  );
  return response.data;
};


export const addUser = async (id_supplier, data, token) => {
  const response = await axios.post(`${process.env.API_URL}/suppliers/${id_supplier}/user`, data,

    {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'

      }
    }

  );
  return response;
};

