import React from 'react';
import loading from 'assets/loading.svg';
import styles from './loading.module.scss';

const Loading = () => (
  <div className={styles.wrapper}>
    <img src={loading} alt="Loading" className={styles.loading} />
  </div>
);

export default Loading;
