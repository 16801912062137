

export const usersByOwner = [
    {
        id: '129',
        user: "Tapas Martin",
        phone: "+15409984347",
        email: "martintraperol@gmail.com",
        ownerId: null,
        role: "Owner",
        code: "OWNER",
        restaurants: [
            {
                id: '144',
                name: "Au Cheval CIA LTDA"
            },
            {
                id: '142',
                name: "Cafetería París"
            },
            {
                id: '47',
                name: "Ciabatta"
            },
            {
                id: '141',
                name: "Nobu Quito"
            },
            {
                id: '98',
                name: "Osteria Emilio"
            },
            {
                id: '92',
                name: "Tapas Guayaquil"
            }
        ]
    },
    {
        id: '146',
        user: "Alex merino",
        phone: "+593995308100",
        email: "alex@gmail.com",
        role: "Administrator",
        restaurants: [
            {
                id: '92',
                name: "Tapas Guayaquil"
            }
        ]
    },
    {
        id: '230',
        user: "Prueba Admin",
        phone: "+19409984347",
        email: null,
        role: "Administrator",
        restaurants: [
            {
                id: '47',
                name: "Ciabatta"
            },
            {
                id: '98',
                name: "Osteria Emilio"
            },
            {
                id: '92',
                name: "Tapas Guayaquil"
            }
        ]
    },
    {
        id: '290',
        user: "Raul pruebas Red",
        phone: "+16892673769",
        email: null,
        role: "Administrator",
        restaurants: [
            {
                id: '47',
                name: "Ciabatta"
            },
            {
                id: '98',
                name: "Osteria Emilio"
            },
            {
                id: '92',
                name: "Tapas Guayaquil"
            }
        ]
    },
    {
        id: '291',
        user: "Raul P",
        phone: "+573208774816",
        email: null,
        role: "Administrator",
        restaurants: [
            {
                id: '47',
                name: "Ciabatta"
            },
            {
                id: '98',
                name: "Osteria Emilio"
            },
            {
                id: '92',
                name: "Tapas Guayaquil"
            }
        ]
    },
    {
        id: '292',
        user: "Prueba",
        phone: "+573117983528",
        email: null,
        role: "Administrator",
        restaurants: [
            {
                id: '47',
                name: "Ciabatta"
            },
            {
                id: '141',
                name: "Nobu Quito"
            },
            {
                id: '92',
                name: "Tapas Guayaquil"
            }
        ]
    },
    {
        id: '295',
        user: "Galton Estrella",
        phone: "+593998032130",
        email: null,
        role: "Administrator",
        restaurants: [
            {
                id: '92',
                name: "Tapas Guayaquil"
            }
        ]
    },
    {
        id: '179',
        user: "Raul Camacho",
        phone: "+573023789302",
        email: null,
        role: "Staff",
        restaurants: [
            {
                id: 47,
                name: "Ciabatta"
            }
        ]
    },
    {
        id: '279',
        user: "Prueba Staff",
        phone: "+593968656005",
        email: null,
        role: "Staff",
        restaurants: [
            {
                id: '47',
                name: "Ciabatta"
            }
        ]
    },
    {
        id: '297',
        user: "Tapas Staff",
        phone: "+17409984347",
        email: null,
        role: "Staff",
        restaurants: [
            {
                id: '47',
                name: "Ciabatta"
            }
        ]
    }
]
const creationDate = new Date();

export  const userListObject = usersByOwner.map(val => {
    return {
        id: val.id,
        name: val.user,
        role: val.role,
        phone: val.phone,
        local: val.restaurant,
        creation: `Se unió: ${creationDate.toLocaleString('en-US', { month: 'short', })} ${creationDate.getDay().toString()}, ${creationDate.getFullYear()}`,
        actions: ""
    }
});