import React, { useEffect, useState } from 'react'

import styles from '../../message.module.scss'
import searchIcon from 'assets/search.svg';
import { Box, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getCustom } from '../../../../redux/customersSlice/thunks';
import { setData, setValidate } from '../../../../redux/templateSlice/templateSlice';

export const ThirdStep = () => {

    const { customers, isLoading, errorData } = useSelector((state) => state.customers)
    const { data } = useSelector((state) => state.template)
    const { user, token } = useSelector((state) => state.auth)
    const [cli, setCli] = useState([])
    const [checked, setChecked] = useState(false)
    const dispatch = useDispatch()
    const [params, setParams] = useState({
        query: '',
    });


   

    useEffect(() => {
        dispatch(setData({
            ...data,
            clients: cli


        }))
    }, [cli])


    useEffect(() => {

        selectAll()
    }, [checked])

    const searchItems = (e) => {
        setParams({ query: e.target.value });
    };
    useEffect(() => {

        dispatch(getCustom(user?.supplierId, user?.id, params.query, token))

    }, [params.query])


    useEffect(() => {

        dispatch(getCustom(user?.supplierId, user?.id, params.query, token))

    }, [])
    useEffect(() => {
        validate()
       
    }, [data])


    
    const validate=()=>{

        if ( data.clients.length > 0 ) {

            dispatch(setValidate(true))

        }else{
            dispatch(setValidate(false))
        }


    }

    const handleOnchange = (e, id) => {

       

        if (e.target.checked && cli.includes(id)) {

            null

        } else {


            setCli(

                [...cli, id]

            )
        }


        if (e.target.checked === false) {

            const cliN = cli.filter((cl) => {

                return cl != id

            })

            setCli(

                cliN

            )
          

        }


    }





    const selectAll = () => {

        if (checked) {

            const all = customers.map((customer) => {
                return customer.restaurantId

            })

         
            dispatch(setData({
                ...data,
                clients: all
            }
            ))


        } else {

            dispatch(setData({
                ...data,
                clients: []
            }
            ))

        }

    }


 



    return (


        <div >
            <Box
                sx={{ border: '1px solid #e4e4e4' }}
                borderRadius={1}
                display='flex'
                flexDirection={'row'}
                alignItems='center'
            >
                <img className={styles.icon} src={searchIcon} alt="search icon" />
                <input
                    onChange={searchItems}
                    className={styles.input}
                    type="search"
                    placeholder='Buscar'


                />

            </Box>
            <Box
                mt={6}
                display='flex'
                flexDirection={'row'}
                justifyContent='end'
            >
                <Box
                    paddingRight={1}

                    display='flex'
                    flexDirection={'row'}
                    alignItems='center'
                >
                    <Typography
                        marginRight={2}
                    >
                        Seleccionar todos
                    </Typography>
                    <input onChange={() => setChecked(!checked)} type='checkbox' />

                </Box>

            </Box>
            <div className={styles.scrollbar} >

                {
                    customers && customers.map((customer, i) => {

                        return (
                            <Grid
                                
                                key={i}

                                container
                                flexDirection={'row'}
                                justifyContent='space-between'
                                alignItems='center'
                                
                               
                                sx={{ borderBottomColor:'#E9E7E2', paddingLeft:1, paddingRight:1, paddingTop:1.5, paddingBottom:1.5 }}
                                borderBottom={1}
                            >
                                <Box

                                    flexDirection={'column'}

                                >

                                    <Typography
                                        fontWeight={'500'}
                                    >
                                        {customer.restaurantname
                                        }
                                    </Typography>
                                    <Typography
                                        color='#808080'
                                        fontSize={14}
                                    >
                                        {customer.ownername
                                        }
                                    </Typography>



                                </Box>
                                <Box

                                >
                                    {
                                        checked ?
                                            <input checked={checked} type='checkbox' />
                                            :
                                            <input checked={ customer.restaurantId === cli.find((element) => element === customer.restaurantId ? true : false ) } name='clients' onChange={(e) => handleOnchange(e, customer.restaurantId)} type='checkbox' />
                                    }

                                </Box>
                            </Grid>
                            
                        )


                    })

                }





            </div>



        </div>
    )
}
