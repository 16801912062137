import React from 'react';
import styles from './registrationwrap.module.scss';
import logo from 'assets/sidebarLogo.svg';
import Registration from './Registration';
import useAuth from 'hooks/useAuth';

const RegistrationWrap = () => {
  const {
    registerStep,
    nextStep,
    goBack,
    registerData,
    handleRegisterChange,
    handleRegisterPhoneChange,
    handleRegisterCode,
    registerUser,
    registerCode,
    verifyUserRegister,
    completeRegister,
    registerError,
    verifyRegisterError,
    disableRegister1,
    disableRegister2,
    disableRegister3,
    validateRegister1,
    validateRegister2,
    validateRegister3,
    toLogin,
    toRecover,
    handleRegisterCodeChange,
    setRegisterStep,
    step2RegisterError,
  } = useAuth();

  return (
    <div className={styles.imageContainer}>
      <div className={styles.container}>
        <img src={logo} className={styles.logo} alt="cado logo" />
        <Registration
          registerStep={registerStep}
          nextStep={nextStep}
          goBack={goBack}
          registerData={registerData}
          handleRegisterChange={handleRegisterChange}
          handleRegisterPhoneChange={handleRegisterPhoneChange}
          handleRegisterCode={handleRegisterCode}
          registerUser={registerUser}
          registerCode={registerCode}
          verifyUserRegister={verifyUserRegister}
          completeRegister={completeRegister}
          registerError={registerError}
          verifyRegisterError={verifyRegisterError}
          disableRegister1={disableRegister1}
          disableRegister2={disableRegister2}
          disableRegister3={disableRegister3}
          validateRegister1={validateRegister1}
          validateRegister2={validateRegister2}
          validateRegister3={validateRegister3}
          toLogin={toLogin}
          toRecover={toRecover}
          handleRegisterCodeChange={handleRegisterCodeChange}
          step2RegisterError={step2RegisterError}
        />
      </div>
    </div>
  );
};

export default RegistrationWrap;
