

import React from 'react'
import Sidebar from '../Sidebar'
import HamburgerMenu from '../HamburgerMenu';
import { Toaster } from 'react-hot-toast'


export const HomeLayouts = ({ children }) => {


    
    return (

        <>
            <HamburgerMenu

            />
            <Sidebar />
            <div id="app-wrapper">


                {children}

            </div>

            <Toaster />
        </>



    )
}
