



import { createSlice } from "@reduxjs/toolkit";




const initialState = {

    open:false,
   
  
} 





 export const  openSlice = createSlice({

    name:'open',
    initialState:initialState,
    reducers:{
    
        setOpen: (state, action )=>{
                            
            state.open=action.payload
          
            
        },
        setClose: (state )=>{

            state.open=false
           
            
        },
     
    }


})

export const {setOpen,  setClose, } = openSlice.actions




