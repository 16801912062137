

import { Box, Collapse, List } from '@mui/material';
import React from 'react'

import { useState } from 'react';
import styles from './sidebar.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClose, setOpen } from '../../redux/openSlice/opneSlice';

export const LinkMenu = ({ to, icon, name, links, restaurant }) => {
    const { user, subscription } = useSelector((state) => state.auth)
    const { pathname } = useLocation();


    const isCurrentPath = (route) => {
        if (route === '/') {
            return route === pathname;
        } else {
            return pathname.startsWith(route);
        }
    };


    const [isOpen, setIsOpen] = useState(false);

    const handleIsOpen = () => {

        setIsOpen((prev) => !prev);
      
       
    };



    return (

        <List>
            <Link
                onClick={handleIsOpen}
                to={  to }
                className={`${styles.link} ${
                    isCurrentPath(to) ? styles.currentLink : ''
                  }`}
                  style={{
                    display:
                    (name === 'Órdenes' || name === 'Perfil' || name === 'Cerrar sesión' || name === 'Catálogo' || name === 'Clientes' || name === 'Mensajes') && !user  ? 'none' : user?.roles && name === 'Iniciar sesión' ? 'none'
                          : null,
                  }}
                //   style={{
                //     display:
                //     (name === 'Órdenes' || name === 'Perfil' || name === 'Cerrar sesión') && !user
                //         ? 'none' : user?.roles && name === 'Iniciar sesión' ? 'none'
                //         : null,
                //   }}
            >
                <img  width={name === 'Iniciar sesión' ? 25 : null}  src={icon} className={styles.icons} />
                {name}
            </Link>
            {
                links.map((v, i) => {

                    if (user?.secondaryRole && user?.secondaryRole === 'ASESOR' && v.name === 'Categorias' ) {
                            
                         return null

                    }else{

                     return (
                        <Collapse
                        key={i}
                        sx={{

                            height: 0,
                            margin: 0,
                            padding: 0,

                        }}
                        in={isOpen}>
                        <Box
                            display='flex'
                        >

                            <Box ml={2} display={'flex'} alignItems={'center'}  >
                                <Box
                                    ml={4}
                                    mr={1}
                                    borderRadius={50}
                                    bgcolor={'white'}
                                    height={6}
                                    width={6}
                                    mt={-1.2}>
                                </Box>
                                <Box
                                    width={190}
                                >
                                    <Link
                                        to={v.to}
                                        key={`${i}_${v.to}`}
                                        className={`${styles.link} ${isCurrentPath(v.to) ? styles.currentLink : ''
                                            }`}
                                    >
                                        {v.name}
                                    </Link>
                                </Box>

                            </Box>
                        </Box>
                    </Collapse>

                     )  
                     
                  
                    }

                } 
                
               
                
                )
            }
        </List>

    )
}


