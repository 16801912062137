import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import styles from './hamburgerMenu.module.scss';
import { useSelector } from 'react-redux';

export const MenuLink = ({ icon, to, name, }) => {
    const { user, subscription } = useSelector((state) => state.auth)
  
    const { pathname } = useLocation();
    const isCurrentPath = (route) => {
        if (route === '/') {
            return route === pathname;
        } else {
            return pathname.startsWith(route);
        }
    };
    return (
        <Link
            //onClick={handleClick}
            to={to}

            className={`${styles.link} ${isCurrentPath(to) ? styles.currentLink : ''}`}
            style={{
                display:
                (name === 'Órdenes' || name === 'Perfil' || name === 'Cerrar sesión' || name === 'Catálogo' || name === 'Clientes' || name === 'Mensajes') && !user  ? 'none' : user?.roles && name === 'Iniciar sesión' ? 'none'
                      : null,
              }}
            // style={{
            //     display:
            //     (name === 'Órdenes' || name === 'Perfil' || name === 'Cerrar sesión') && user?.role === 'guestUser'
            //         ? 'none' : user?.roles && name === 'Iniciar sesión' ? 'none'
            //         : null,
            //   }}
        >
           
            <img src={icon} className={styles.icons} />
                      {name}
        </Link>
    )
}
