import { getAllproducts, getProductsCateg } from "../../api/products"
import { getArrayOfErrors } from 'utils/errors';
import { setError, setProductsAll, startProducts } from "./productsSlice"
import { setErrorToken } from "../authSlice/authSlice";







export const getproducts = (token, id, page, limit, query, id_cat ) => {

    
        if (id_cat === undefined ) {
            
            return async (dispatch) => {
    
           
            try {
    
                const products = await getAllproducts(token, id, page, limit, query)
          
                      
                dispatch( setProductsAll(products) )
    
    
            } catch (error) {
                if (error.response) {
                    const errors = getArrayOfErrors(error.response.data);
            
                    if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {
            
                      dispatch(setErrorToken(true))
            
                    } else {
                      for (let i = 0; i < errors.length; i++) {
                        dispatch(setError(errors[i]))
                      }
                    }
            
            
            
                  } else {
                    dispatch(setError('Error interno. Inténtelo más tarde'))
                    
                  }
             
              
                // setLoginError(error.response?.data);
            }
    
    
        }
            
        }else{
            return async (dispatch) => {
                           
            try {
    
                const products = await getAllproducts(token, id, page, limit, query, id_cat)
               
          
                dispatch( setProductsAll(products) )
    
    
            } catch (error) {
             
                if (error.response) {
                    const errors = getArrayOfErrors(error.response.data);
            
                    if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {
            
                      dispatch(setErrorToken(true))
            
                    } else {
                      for (let i = 0; i < errors.length; i++) {
                        dispatch(setError(errors[i]))
                      }
                    }
                  } else {
                    dispatch(setError('Error interno. Inténtelo más tarde'))
                    
                  }
            }
       
        }

        }

  
}
