





import { createSlice } from "@reduxjs/toolkit";




const initialState = {

    customers:null,
    isLoading:false,
    errorData:null,
  
} 



 export const  customerSlice = createSlice({

    name:'customers',
    initialState:initialState,
    reducers:{
        startCustomers:(state)=>{
            state.isLoading = true
        },
        setCustomers: (state, action )=>{
                            
            state.customers=action.payload,
            state.isLoading=false,
            state.errorData=null
            
        },

        setError: (state, action )=>{
           
       
            state.customers=null,
            state.isLoading=false,
            state.errorData=action.payload
            
        },
        clearCustomers: (state )=>{

            state.customers=null,
           state.isLoading=false,
            state.errorData=null    
            
        },
     
    }


})

export const {startCustomers,  setCustomers,  setError, clearCustomers } = customerSlice.actions




