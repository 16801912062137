
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { IoChevronForwardSharp as Back } from '@react-icons/all-files/io5/IoChevronForwardSharp';
export const CustomerNovil = ({ customers, navigate }) => {




    return (

        <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }} mt={5}  >




            <Grid gap={1} container display='flex' flexDirection='row' padding={1}  >

                <Grid item xs={3}  >
                    <Typography fontSize={16} fontWeight={'bold'} >
                        Registrado
                    </Typography>
                </Grid>
                <Grid item xs={4}   >
                    <Typography fontSize={16} fontWeight={'bold'} >
                        Cliente
                    </Typography>
                </Grid>
                <Grid item xs={4}   >
                    <Typography fontSize={16} fontWeight={'bold'}  textAlign='start' >
                        Vendedor
                    </Typography>
                </Grid>



            </Grid>


            {
                customers && customers.map((customer, i) => {

                    return (

                        <Grid key={i} onClick={() => navigate('cliente', { state: { customer } })} sx={{ borderColor: '#dedede' }}  borderBottom={1} gap={1} container display='flex' flexDirection='row' padding={1} paddingTop={2} paddingBottom={2}  >

                            <Grid item xs={3}  >
                                <Box mt={1} >
                                    <input style={{ height: '15px', width: '15px' }} type="checkbox" checked={customer.complete} readOnly />
                                </Box>
                            </Grid>
                            <Grid item xs={4}  >
                                <Typography textAlign='start' fontSize={13} >
                                    {customer.restaurantname}
                                </Typography>
                                <Typography textAlign='start' color={'#808080'} fontSize={13} >
                                    {customer.identifier}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} >
                                <Typography fontSize={13} textAlign='start' >
                                    {customer.username
                                    }
                                </Typography>
                            </Grid>



                        </Grid>


                    

                    )

                })

            }




        </Box>


    )
}
