import React from 'react';
import styles from './swipedots.module.scss';

const SwipeDots = ({ registerStep }) => {
  return (
    <div className={styles.dotsContainer}>
      <div className={registerStep === 1 ? styles.dotActive : styles.dotNotActive}></div>
      <div className={registerStep === 2 ? styles.dotActive : styles.dotNotActive}></div>
      {/* <div className={registerStep === 3 ? styles.dotActive : styles.dotNotActive}></div> */}
    </div>
  );
};

export default SwipeDots;
