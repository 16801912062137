import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { toast } from 'react-hot-toast';
import { getOrderById, markOrderAsConfirmed } from 'api/orders';
import { formatDateForOrder } from 'utils/dates';

import Content from './Content';

import { getArrayOfErrors } from 'utils/errors';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorToken } from '../../../../redux/authSlice/authSlice';
import { HomeLayouts } from '../../../../components/layouts/HomeLayout';
const RecieveOrder = () => {
  const { user, token } = useSelector((state) => state.auth)
  const { id, restaurant } = useParams();
  const [note, setNote] = useState('');
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const dispatch = useDispatch()





  const receive = async (date) => {
    const formattedDate = formatDateForOrder(date);
    const requestOrder = {
      products: [],
      note:'',
      noteSupplier:note,
      scheduleDate: formattedDate,
    };

    for (let i = 0; i < order.products.length; i++) {
      let key = 'requestedQuantity';
      requestOrder.products.push({
        id: order.products[i].id,       
        confirmedQuantity: order.products[i][key],
      });
    }
   try {
      const responseOrder = await markOrderAsConfirmed( requestOrder, restaurant, order.id, token, user?.id);
      navigate(`/ordenes/confirmacion/${responseOrder.code}/${restaurant}`);
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);

        if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {

          dispatch(setErrorToken(true))

        } else {
          for (let i = 0; i < errors.length; i++) {
            toast.error(errors[i]);
          }
        }
      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    }
  };
//id_user, idRestaurant, id, token
  useEffect(async () => {
    try {
      const order = await getOrderById( user?.id, restaurant, id, token);
      setOrder(order);
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);

        if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {

          dispatch(setErrorToken(true))

        } else {
          for (let i = 0; i < errors.length; i++) {
            toast.error(errors[i]);
          }
        }



      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    }
  }, []);



  if (!order) return <HomeLayouts></HomeLayouts> ;
  if (!order.products.length) navigate(-1);

  return <Content note={note} setNote={setNote} id={id} order={order} setOrder={setOrder} receive={receive} restaurant={restaurant}/>;
};

export default RecieveOrder;
