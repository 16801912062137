
import React, { useState, useEffect } from 'react'
import { HomeLayouts } from '../../components/layouts/HomeLayout'
import CTA from 'components/CTA';
import SearchBar from 'components/SearchBar';
import styles from './catalog.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import { getCustom } from '../../redux/customersSlice/thunks';
import { Box, Container, Typography } from '@mui/material';
import PopUp from '../../components/PopUp';
import AddSupplier from '../../components/PopUp/components/AddSupplier/AddSupplier';
import { useNavigate } from 'react-router-dom';
import { CustomerNovil } from './components/CustomerNovil';
import emptySupplier from 'assets/emptySupplier.svg';



const columns = [
    {
        name: 'Registrado',
        size: 'centered',
    },
    {
        name: 'Cliente',
        size: 'large',
    },
    {
        name: 'Vendedor',
        size: 'medium',
    },
    {
        name: '',
        size: 'centered',
    },

];


export const Customer = () => {

    const { user, token } = useSelector((state) => state.auth)
    const [showModal, setShowModal] = useState(false);
    const { customers, isLoading, errorData } = useSelector((state) => state.customers)
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [params, setParams] = useState({
        query: '',
    });




    ////id, user_id,query,  token

    useEffect(() => {

        dispatch(getCustom(user?.supplierId, user?.id, params.query, token))

    }, [params.query])


    useEffect(() => {

        dispatch(getCustom(user?.supplierId, user?.id, params.query, token))

    }, [])




    if (isLoading) {
        return <HomeLayouts> <Loading /> </HomeLayouts>

    }



    return (

        <HomeLayouts>

            <div className={styles.content} >

                <PopUp showModal={showModal} setShowModal={setShowModal}>
                    <AddSupplier setRerender={false} setShowModal={setShowModal} />
                </PopUp>

                <div className={styles.header} >
                    <Box
                        sx={{ alignItems: 'center', display: { sm: 'flex', md: 'flex', xs: 'block' } }}
                        flexDirection='row'
                        alignContent={'center'}
                        justifyContent='space-between'
                        display='flex' >
                        <h1 className={styles.title}>Clientes</h1>
                        <Box flexDirection={'row'} sx={{ display: { sm: 'flex', md: 'flex', xs: 'block' } }} >
                            <SearchBar
                                query={params.query}
                                setQuery={(val) => {
                                    setParams({ ...params, query: val });
                                }}
                                placeholder="Buscar"
                            />
                            <Box ml={2} sx={{ display: { xs: 'none', md: 'flex', sm: 'flex' } }}  >
                                <CTA
                                    text="Añadir Cliente"
                                    onClick={() => {
                                        setShowModal(true)
                                    }}

                                />
                            </Box>
                        </Box>
                    </Box>
                </div>

            </div>

            {
                customers && customers.length === 0 ?

                    <Box
                        sx={{ display: 'flex', flexDirection: 'column', padding: 2, height: '80%', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <Typography
                            textAlign={'start'}
                            fontWeight={'bold'}
                            marginBottom={2}
                            fontSize={18}
                            maxWidth={250}

                        >
                            Tu lista esta vacia.
                        </Typography>
                        <Typography
                            fontSize={18}
                            maxWidth={250}
                            marginBottom={6}
                        >
                            Aún no tienes clientes. Agrega uno nuevo a tu lista.
                        </Typography>
                        <img src={emptySupplier} alt="No suppliers" />
                    </Box> :
                    <>
                        <Box sx={{ marginTop: 10, display: { xs: 'none', sm: 'none', md: 'block' } }}  >
                            <div className={styles.desktopOrdersContainer} >

                                <div className={`${styles.row} ${styles.headerRow}`}>
                                    {columns.map((column, i) => {
                                        return (
                                            <div
                                                key={`${i}_${column.name}`}
                                                className={`${styles.column} ${styles[column.size]}`}
                                            >
                                                {column.name}
                                            </div>
                                        );
                                    })}
                                </div>

                                <div>
                                    {customers && customers.map((customer, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className={`${styles.row}`}
                                            >
                                                <div className={`${styles.column} ${styles.centered}`}>
                                                    <input className={styles.checkbox} type="checkbox" checked={customer.complete} readOnly />
                                                </div>
                                                <div className={`${styles.descriptionP} ${styles.large}`}>
                                                    <div>  {customer.restaurantname}</div>
                                                    <div className={styles.codeP} > {customer.identifier} </div>


                                                </div>
                                                <div className={`${styles.column} ${styles.medium}`}>
                                                    {customer.contactname}
                                                </div>

                                                <div className={`${styles.column} ${styles.centered}`}>
                                                    <div onClick={() => navigate('cliente', { state: { customer } })} className={styles.edit} >Ver</div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>

                            </div>
                            <div style={{ marginTop: '25px' }} >

                            </div>

                        </Box>
                        <CustomerNovil
                            customers={customers}
                            navigate={navigate}

                        />
                    </>
            }
        </HomeLayouts>


    )
}
